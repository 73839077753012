import BaseValidate from '../../../../validator/BaseValidate';
class AddMultiVariableValidate extends BaseValidate {
    constructor(props) {
        super();
        var { t } = props;
        this.trans = t;
    }
    setRule() {
        

    }
    setAlias() {
        this.v.setAlias({
            
        });

    }
}
export default AddMultiVariableValidate;