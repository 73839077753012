import BaseComponent from '../../../BaseComponent';
import Constants from '../../../../utils/Constants';
import Libs from '../../../../utils/Libs';
import GoogleFormJsx from './GoogleForm.jsx';
import { withTranslation } from 'react-i18next';
import GoogleFormService from '../../../../services/GoogleFormService';
import './ExportStock.scss';

class GoogleForm extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.jsxTemplate = GoogleFormJsx;
        this.state = {
            allLanguages: this.allLanguages,
            curItem: {},
            formSearch: false,
            dataList: [],
            searchParam: {
                limit: Constants.COMMON.LIMIT,
                offset: 0,
                index: 1,
                sort_column: null,
                order_by: null
            },
            total_row: 0,
            add: false,
            showDeletePopup: false
        };
        //paging info
        this.paging = {
            total: 1,
            current: 1,
        };

    
        this.inputChangedHandler = this.inputChangedHandler.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.inputChangedEnter = this.inputChangedEnter.bind(this);
        this.inputChangedBlue = this.inputChangedBlue.bind(this);
    }

    handleInputChange(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (name) {
            let params = self.state.searchParam;
            params[name] = (event.target.validity.valid) ? value : params[name];
            self.setState({
                searchParam: params
            });
        }
    }

    /**
    * Func filter table
    * @author long.pham 28/07/2019
    * @param  {Object} e
    */

    onSort(event, sortKey) {
        this.state.searchParam.sort_column = sortKey;
        this.state.searchParam.order_by = (this.state.searchParam.order_by == '' || this.state.searchParam.order_by == 'asc') ? 'desc' : 'asc';
        this.getList();
    }

    /**
     * Func search
     * @author long.pham 28/07/2019
     * @param  {Object} e
     */

    handleSearch() {
        this.getList();
    }

    componentDidMount() {
        var self = this;
        self.getList();
    }

    addSearch() {
        let formSearch = (this.state.formSearch == false) ? true : false;
        this.setState({
            formSearch: formSearch
        });
    }

    /**
     * @description reload data
     * @author long.pham 09/05/2021
     * @param {int} index
     */
    onClickReload() {
        let self = this;
        self.getList();
    }

    inputChangedEnter(event) {
        let self = this;
        if (event.key === 'Enter') {
            var current = this.paging.current;
            if (!Libs.isBlank(current)) {
                var { t } = this.props;
                if (!Libs.isNumber(current)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            if (Libs.isBlank(current) && !Libs.isNumber(current)) return;
            if (parseInt(current) > this.paging.total) {
                current = self.paging.total;
            }

            if (current <= 0) {
                current = 1;
            }

            self.paging.current = current;
            self.paging.current = current;
            this.onSelectPage(current);
            self.forceUpdate();
        }
    }

    inputChangedBlue(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        if (name === 'current') {
            var current = this.paging.current;
            if (!Libs.isBlank(current)) {
                var { t } = this.props;
                if (!Libs.isNumber(current)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            if (Libs.isBlank(current) && !Libs.isNumber(current)) return;
            if (parseInt(current) > this.paging.total) {
                current = self.paging.total;
            }

            if (current <= 0) {
                current = 1;
            }

            self.paging.current = current;
            self.paging.current = current;
            this.onSelectPage(current);
            self.forceUpdate();
        }
    }

    /**
     * @description Select page in paging
     * @author long.pham 27/07/2019
     * @param {int} index
     */
    onSelectPage(index) {
        let self = this;
        self.state.searchParam.index = index;
        if (index > 0) {
            self.state.searchParam.offset = (index - 1) * self.state.searchParam.limit;
        } else {
            self.state.searchParam.offset = 0;
        }
        self.getList();
    }


    inputChangedHandler(event) {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (name === 'current') {
            if (!Libs.isBlank(value)) {
                var { t } = this.props;
                if (!Libs.isNumber(value)) {
                    self.toast(t('common.page_is_number'), "error");
                    return;
                }
            }

            self.paging.current = value;
            self.forceUpdate();
        }

        if (name === 'limit') {
            var { searchParam } = this.state;
            searchParam.limit = value;
            this.setState({
                searchParam: searchParam
            }, () => {
                self.getList();
            })
        }
    }

    /**
     * get moderation list
     * @author Long.Pham 2019-06-03
     */
    getList() {
        let self = this;
        let params = self.state.searchParam;
        GoogleFormService.instance.getList(params, (data, total_row) => {
            console.log(data);
            if (Libs.isArrayData(data)) {
                self.setState({
                    dataList: data
                });

                var total = parseInt(total_row / self.state.searchParam.limit);
                if (total_row % self.state.searchParam.limit > 0) {
                    total = total + 1;
                }
                self.paging.total = total;
                self.paging.current = self.state.searchParam.index;
                self.state.total_row = total_row;
                this.forceUpdate();
            } else {
                self.setState({
                    dataList: []
                });
                self.paging.total = 1;
                self.paging.current = 1;
                self.state.total_row = 0;
            }
        })
    }

    /**
     * @description Status change event
     * @author long.pham 27/07/2019
     */
    onStatusChange = (index) => {
        console.log(index);
        if (!Libs.isArrayData(this.state.dataList)) return;
        var self = this;
        var item = this.state.dataList[index];
        var isActiveUpdate = item.status;
        if (isActiveUpdate * 1 == 1) {
            isActiveUpdate = 0;
        }
        else {
            isActiveUpdate = 1;
        }
        var param = {
            id: item.id,
            status: isActiveUpdate,
            id_company: this.staff.id_company,
            updated_by: this.staff.fullname
        };
        GoogleFormService.instance.updateStatus(param, function (status, msg) {
            if (status) {
                item.status = isActiveUpdate;
                self.setState({
                    dataList: self.state.dataList
                });
            }
        });
    }

    /**
     * @description Item click event
     * @author long.pham 27/07/2019
     * @param index Order element in the list
     */
    onItemClick = (index) => {
        var self = this;
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];

        var curItem = {};
        curItem.screen_mode = Constants.SCREEN_MODE.EDIT;

        GoogleFormService.instance.getDetail(item, data => {
            if (data) {
                self.setState({
                    curItem: Object.assign(curItem, data),
                    add: true
                });
            }
        }, false);

    }


    

    /**
     * @description Item click event
     * @author long.pham 27/07/2019
     * @param index Order element in the list
     */
     onItemClickView = (index) => {
        var self = this;
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];

        var curItem = {};
        curItem.screen_mode = Constants.SCREEN_MODE.VIEW;

        GoogleFormService.instance.getDetail(item, data => {
            if (data) {
                self.setState({
                    curItem: Object.assign(curItem, data),
                    add: true
                });
            }
        }, false);

    }

    /**
     * @description Item click event delete
     * @author long.pham 27/07/2019
     * @param index Order element in the list
     */
    onItemClickDelete = (index) => {
        if (!Libs.isArrayData(this.state.dataList)) return;
        var item = this.state.dataList[index];
        this.setState({
            curItem: item,
            showDeletePopup: true,
        });
    }


    /**
     * @description Show add popup
     * @author long.pham 28/08/2022
    */
    onAddPopup() {
        let curItem = {}, data = [];
        curItem.screen_mode = Constants.SCREEN_MODE.ADD;
        curItem.dataForm = [];
        this.setState({
            curItem: curItem,
            add: true
        });
    }

    /**
     * @description Close GoogleForm popup
     * @author long.pham 26/07/2019
     */
    onCloseAddPopup = (status) => {
        if (status) {
            this.getList();
        }
        this.setState({
            add: false
        });
    }

    /**
     * @description Close GoogleForm popup delete
     * @author long.pham 26/07/2019
     */
    onCloseDeletePopup = (status) => {
        if (status) {
            this.getList();
        }

        this.setState({
            showDeletePopup: false
        });
    }


    render() {
        return this.jsxTemplate.call(this)
    }
}



const HighOrderComponentTranslated = withTranslation('common')(GoogleForm)
export default HighOrderComponentTranslated;