import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { RButton } from '../../../../components/Controls';


export default function () {
    const { t } = this.props;
    var { curItem } = this.state;
    return (
        <React.Fragment>
            <Modal visible={true} className="modal-delete" dialogClassName="">
                <div className="modal-header">
                    <h5 className="modal-title">
                    Xóa Form: {curItem.name_form}
                    </h5>
                    <button type="button" onClick={this.onCloseDeletePopup.bind(this)} className="bootbox-close-button close"><span>×</span></button>
                </div>
                {/* modal-body */}
                <div className="modal-body">
                    <div className="box-info">
                        <div className="box-info-content">
                            <div className="row">
                                <div className='col-md-12'>
                                    <div className='alert'>{t('common.message_delete')}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* end modal-body */}
                <div className="modal-footer">
                    <RButton
                        onClick={this.onCloseDeletePopup.bind(this)}
                        className="btn btn-default btn-cancel"
                        text={" " + t('common.label_cancel')}
                        data-dismiss="modal" aria-hidden="true"
                        title={t('common.label_cancel')} />

                    <RButton
                        onClick={this.onDeleteAction.bind(this)}
                        className="btn btn-danger btn-add"
                        text={" " + t('common.label_delete')}
                        iClass={"fa fa-trash-o"}
                        title={t('common.label_delete')} />
                </div>
            </Modal>
        </React.Fragment >
    )
}