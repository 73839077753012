
import BaseComponent from '../../../BaseComponent';
import Libs from '../../../../utils/Libs';
import AddPopupJsx from './AddPopup.jsx';
import AddPopupValidate from './AddPopupValidate';
import GoogleFormService from '../../../../services/GoogleFormService';
import Constants from '../../../../utils/Constants';
import readXlsxFile from 'read-excel-file';


class AddPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItem,
            dataImports: [],
            loadding: 0,
            totalRow: 0,
            showMultiVariable: false,
            options: [
                {
                    id: 0,
                    text: "Một trả lời",
                    label: "Một trả lời",
                    value: 0
                },

                {
                    id: 1,
                    text: "Nhiều trả lời",
                    label: "Nhiều trả lời",
                    value: 1
                },
            ]
        }
        this.jsxTemplate = AddPopupJsx;
    }

    addMultiVariable = (e) => {
        this.setState({
            showMultiVariable: true
        });
    }

    onCloseAddMultiVariable = (e) => {
        this.setState({
            showMultiVariable: false
        });
    }

    callbackListFields = (fieldArr, keysArr) => {
        // render field
        var { curItem } = this.state;
        var dataForm = [];
        if (fieldArr.length > 0) {
            for (var i = 0; i < fieldArr.length; i++) {
                var item = {
                    id_google_form: "",
                    keyword: "",
                    name: fieldArr[i],
                    is_multi: 0
                };
                dataForm.push(item);
            }
        }

        if (keysArr.length > 0) {
            dataForm.map((item, index) => {
                dataForm[index].keyword = keysArr[index];
            });
        }

        curItem.dataForm = dataForm;
        this.setState({ showMultiVariable: false, curItem: curItem })

    }

    handleDragEnd = (e) => {
        var { curItem } = this.state;
        var dataForm = curItem.dataForm;
        if (!e.destination) return;
        let tempData = Array.from(dataForm);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);

        curItem.dataForm = tempData;
        this.setState({
            curItem: curItem
        })
    };


    handleDropdownChange(item, index) {
        console.log(item, index);
        var { curItem } = this.state;

        var dataForm = curItem.dataForm;
        if (!Libs.isArrayData(dataForm)) return;
        var findItem = dataForm[index];
        if (Libs.isObjectEmpty(findItem)) return;
        if (Libs.isObjectEmpty(item)) return;
        dataForm[index].is_multi = item.id;

        this.setState({
            dataForm: dataForm
        })
    }

    addRowItem = () => {
        var { curItem } = this.state;
        var dataForm = curItem.dataForm;

        var item = {
            id_google_form: "",
            keyword: "",
            name: "",
            is_multi: 0
        };
        dataForm.push(item);
        this.setState({ dataForm: dataForm })
    }



    onClickDeleteItem = (index) => {
        var { curItem } = this.state;
        var dataForm = curItem.dataForm;

        if (!Libs.isArrayData(dataForm)) return;
        dataForm.splice(index, 1);
        this.setState({
            dataForm: dataForm
        });
    }


    /**
     * @description translate change
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */

    handleInputRowChange = (event, index) => {
        var target = event.target;
        var name = target.name;
        var value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        var { curItem } = this.state;
        var dataForm = curItem.dataForm;

        if (!Libs.isArrayData(dataForm)) return;
        var item = dataForm[index];
        if (Libs.isObjectEmpty(item)) return;

        if (name) {
            item[name] = value;
            dataForm[index] = item;
            curItem.dataForm = dataForm
            this.setState({ curItem: curItem });
        }
    }


    /**
     * @description select change
     * @author long.pham 28/07/2019
     * @param {*} event 
     */
    handleFileInputChange = (event) => {
        var target = event.target;
        var file = target.files[0];
        if (!file || file === undefined || file === null) return;
        var dataImports = [];
        // console.log("file: ", target.files[0]);
        readXlsxFile(file).then((rows) => {
            // `rows` is an array of rows
            // each row being an array of cells.
            dataImports.push(rows);
        })


        this.setState({
            dataImports: dataImports
        })
        // var fileName = file.name, fileSize = file.size;
        // var checkExtFile = Libs.checkExtensionFile(fileName, 1);
        // var msgError = null;
        // if (!checkExtFile) {
        //     msgError = t('message.msg_err_ext_image_file');
        // }
        // else if (fileSize <= 0) {
        //     msgError = t('message.msg_err_file_size');
        // }
        // else if (fileSize > Constants.COMMON.MAX_IMAGE_SIZE) {
        //     msgError = t('message.msg_err_max_size_image_upload');
        // }

        // var self = this;
        // var { curItem } = this.state;
        // if (!Libs.isBlank(msgError)) {
        //     self.toast(msgError, 'error');
        //     return;
        // }


    }

















    /** 
     * @description Save info GoogleForm
     * @author long.pham 28/07/2019
    */
    async onSaveAction() {
        let params = this.state.curItem, self = this;
        var screenMode = (!Libs.isBlank(this.props.curItem.screen_mode)) ? this.props.curItem.screen_mode : Constants.SCREEN_MODE.ADD;
        let v = new AddPopupValidate(this.props);
        let errors = await v.FLValidationAll(params);
        if (errors) {
            this.setValidateMessage(errors);
            return;
        }
        //remove message validation
        this.removeAllValidateMessage();
        params.screen_mode = screenMode;

        GoogleFormService.instance.save(params, function (status, data, msg) {
            if (status) {
                self.toast(msg, "info");
                self.props.onCloseAddPopup(true, data);
            }
            else if (data) {
                this.setValidateMessage(data);
            }
            else if (!Libs.isBlank(msg)) {
                self.toast(msg, "error");
            }
        }, true);
    }
    /**
     * @description validate a field input
     * @author long.pham 28/07/2022
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddPopupValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }



    /** 
     * @description Save info GoogleForm
     * @author long.pham 28/07/2019
    */
    async onRunAction() {

        var { curItem, dataImports, loadding, totalRow } = this.state;
        var self = this;
        var dataForm = curItem.dataForm;

        if (!Libs.isArrayData(dataForm)) return;
        if (dataImports.length <= 0) return;
        dataImports = dataImports[0];

        // var field = {};
        // dataForm.map((item, index) => {
        //     field[item.keyword] = item.keyword;
        // });

        totalRow = dataImports.length;

        // console.log("field: ", field);

        // const fields = {
        //     name: "entry.825375631",
        //     address: "entry.1499234221",
        //     phone: "entry.1349619803",
        //     gender: "entry.2100521479",
        //     age: "entry.961370562",
        //     des: "entry.1611364466"

        // };

        const sleep = (milliseconds) => {
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        }

        for (var i = 0; i < dataImports.length; i++) {
            // if (i == 1 || i == 2 || i == 3 || i == 4) {
            var item = {
                formURL: curItem.url_form,
                dataFields: dataForm,
                data: dataImports[i]
            };
            loadding = i;
            GoogleFormService.instance.uploadGoogleForm(item, function (status, data, msg) {
                if (status) {
                    self.setState({
                        loadding: loadding,
                        totalRow: totalRow
                    })
                    // self.toast(msg, "info");
                    // self.props.onCloseAddPopup(true, data);
                } else {
                    self.setState({
                        loadding: loadding,
                        totalRow: totalRow
                    })
                }
            }, true);

            await sleep(dataImports[i][0] * 1000)
            // }
        }
    }

    /**
     * @description close popup
     * @author long.pham 28/07/2019
     */
    async onCloseAddPopup() {
        if (!this.props.onCloseAddPopup || typeof this.props.onCloseAddPopup !== 'function') return;
        const sleep = (milliseconds) => {
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        }
        await sleep(1000000000000 * 1000);

        this.props.onCloseAddPopup(false);
    }

}
export default AddPopup;