import React from 'react';
import MetaTags from 'react-meta-tags';

export default function DashBoard() {
    return (
        <div className="dashboard">
            <MetaTags>
                <title>Dashboard</title>
            </MetaTags>
        </div>
    );
};