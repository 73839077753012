import React from 'react';
import { RText, RButton } from '../../../../components/Controls';
import Libs from '../../../../utils/Libs';
import { Paging } from '../../../../components/Paging';
import RowItem from './RowItem';
import AddPopup from './AddPopup';
import DeletePopup from './DeletePopup';

export default function () {
  const { t } = this.props;
  var { formSearch, dataList, searchParam, curItem } = this.state;


  var rowItems = null;
  if (Libs.isArrayData(dataList)) {
    rowItems = dataList.map((item, index) => {
      return <RowItem
        key={'row_item_' + index}
        index={index}
        dataItem={item}
        onStatusChange={this.onStatusChange}
        onItemClick={this.onItemClick}
        onItemClickDelete={this.onItemClickDelete}
        onItemClickView={this.onItemClickView}
        t={t}
        actions={this.actions}
        auth={this.props.auth}
      />
    });
  }

  var GoogleFormPopupLayout = this.state.add
    ? <AddPopup
      onCloseAddPopup={this.onCloseAddPopup.bind(this)}
      auth={this.props.auth}
      curItem={curItem}
      t={t}
      allLanguages={this.state.allLanguages}
    /> : '';


  var GoogleFormDeletePopupLayout = this.state.showDeletePopup
    ? <DeletePopup
      onCloseDeletePopup={this.onCloseDeletePopup.bind(this)}
      auth={this.props.auth}
      curItem={curItem}
      t={t}
    /> : '';




  return (
    <React.Fragment>
      {GoogleFormPopupLayout}
      {GoogleFormDeletePopupLayout}
      <div className="GoogleForm" >
        <div className="main">
          <section className="content-header">
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-md-6 col-sx-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Danh sách form</li>
                </ol>
              </div>
              <div className="col-lg-6 col-sm-6 col-md-6 col-sx-12">
                <div className='act'>
                  <ul>
                    <li>
                      <RButton className="btn btn-danger btn-add"
                        onClick={this.onAddPopup.bind(this)}
                        title={t('common.add')}
                        text={t('common.add')}
                        iClass={"icon-plus"}
                      />
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </section>

          {formSearch ?
            <section className="box-search">
              <div className="title">
                <h2>{t('GoogleForm.search_title')}</h2>
                <RButton className="btn btn-close-search"
                  onClick={this.addSearch.bind(this)}
                  title={t('GoogleForm.search')}
                  iClass={"icon-close"}
                />
              </div>
              <div className="search">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                    <div className="mb-3">
                      <RText label={t('GoogleForm.name')}
                        inputClass="form-control"
                        inputName="name"
                        inputId="name"
                        value={searchParam.name}
                        onChange={(e) => { this.handleInputChange(e); }}
                        maxLength={200} />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                    <div className="mb-3">
                      <RText label={t('GoogleForm.manage_email')}
                        inputClass="form-control"
                        inputName="manage_email"
                        inputId="manage_email"
                        value={searchParam.manage_email}
                        onChange={(e) => { this.handleInputChange(e); }}
                        maxLength={200} />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                    <div className="mb-3">
                      <RText label={t('GoogleForm.manage_phone')}
                        inputClass="form-control"
                        inputName="manage_phone"
                        inputId="manage_phone"
                        value={searchParam.manage_phone}
                        onChange={(e) => { this.handleInputChange(e); }}
                        maxLength={200} />
                    </div>
                  </div>


                  <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2">
                    <RButton className="btn btn-danger btn-search"
                      onClick={this.handleSearch.bind(this)}
                      title={t('GoogleForm.btn_search')}
                      iClass={"icon-search"}
                    />
                  </div>
                </div>
              </div>
            </section>
            : ""}

          <div className="body">
            <div className="list">
              {/* Header */}
              <div className="header-item">
                <div className="header-col width30">
                  Tên Form
                </div>

                <div className="header-col width60">
                  Submit URL Google Form
                </div>

                <div className="header-col width10">
                  {t('common.action')}
                </div>
              </div>
              {/* end header */}

              {rowItems ? rowItems : <div className='data-empty'>Data empty</div>}

            </div>
          </div>
          <div className="footer">
            <Paging
              total={parseInt(this.paging.total)}
              current={(this.paging.current)}
              inputChangedHandler={this.inputChangedHandler}
              inputChangedEnter={this.inputChangedEnter}
              inputChangedBlue={this.inputChangedBlue}
              onClickReload={(e) => this.onClickReload.bind(this)}
              onSelectPage={(index) => this.onSelectPage.bind(this, index)}>
            </Paging>
          </div>
        </div>
      </div>
    </React.Fragment>

  )
}