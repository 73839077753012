import React from 'react';
import { NavLink } from 'react-router-dom';
import Libs from '../../../utils/Libs';
import Constants from '../../../utils/Constants';
import Auth from '../../../utils/Auth';

export default function MenuProject() {
    let info = localStorage.getItem(Constants.COMMON.ACCESS_PARAM);
    let permissions = JSON.parse(Libs.base64Decrypt(info));
    return (
        <div className="sidebar-top">
            <ul>


                {!Libs.isObjectEmpty(permissions) && !Libs.isBlank(permissions['/dashboard']) && Auth.getPermisson(permissions['/dashboard'].auth, Constants.AUTH_MODE.VIEW) ?
                    <li><NavLink to="/dashboard">Dashboard</NavLink></li>
                    : ""}

                {!Libs.isObjectEmpty(permissions) && !Libs.isBlank(permissions['/google-form']) && Auth.getPermisson(permissions['/google-form'].auth, Constants.AUTH_MODE.VIEW) ?
                    <li><NavLink target="_blank" to={"/google-form"}>Google Form</NavLink></li>
                    : ""}
                {!Libs.isObjectEmpty(permissions) && !Libs.isBlank(permissions['/data-5']) && Auth.getPermisson(permissions['/data-5'].auth, Constants.AUTH_MODE.VIEW) ?
                    <li><NavLink target="_blank" to={"/data-5"}>Dữ liệu Likert 5</NavLink></li>
                    : ""}

                {!Libs.isObjectEmpty(permissions) && !Libs.isBlank(permissions['/regression-5']) && Auth.getPermisson(permissions['/regression-5'].auth, Constants.AUTH_MODE.VIEW) ?
                    <li><NavLink target="_blank" to={"/regression-5"}>Hồi quy Likert 5</NavLink></li>
                    : ""}


                {!Libs.isObjectEmpty(permissions) && !Libs.isBlank(permissions['/data-7']) && Auth.getPermisson(permissions['/data-7'].auth, Constants.AUTH_MODE.VIEW) ?
                    <li><NavLink target="_blank" to={"/data-7"}>Dữ liệu Likert 7</NavLink></li>
                    : ""}

                {!Libs.isObjectEmpty(permissions) && !Libs.isBlank(permissions['/regression-7']) && Auth.getPermisson(permissions['/regression-7'].auth, Constants.AUTH_MODE.VIEW) ?
                    <li><NavLink target="_blank" to={"/regression-7"}>Hồi quy Likert 7</NavLink></li>
                    : ""}


                {!Libs.isObjectEmpty(permissions) && !Libs.isBlank(permissions['/role']) && Auth.getPermisson(permissions['/role'].auth, Constants.AUTH_MODE.VIEW) ?
                    <li><NavLink to={"/role"}>Roles</NavLink></li>
                    : ""}

                {!Libs.isObjectEmpty(permissions) && !Libs.isBlank(permissions['/permission']) && Auth.getPermisson(permissions['/permission'].auth, Constants.AUTH_MODE.VIEW) ?
                    <li><NavLink to={"/permission"}>Permissions</NavLink></li>
                    : ""}

                {!Libs.isObjectEmpty(permissions) && !Libs.isBlank(permissions['/user']) && Auth.getPermisson(permissions['/user'].auth, Constants.AUTH_MODE.VIEW) ?
                    <li><NavLink to={"/user"}>Users</NavLink></li>
                    : ""}









            </ul>
        </div>
    );
};