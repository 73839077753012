import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { RText, RButton } from '../../../../components/Controls';
import Constants from '../../../../utils/Constants';
import FormReactSelect from '../../../../components/FormReactSelect';
import Libs from '../../../../utils/Libs';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddMultiVariable from './AddMultiVariable';


export default function () {
    var { curItem, loadding, totalRow, options } = this.state;
    const { t } = this.props;




    var dataForm = curItem.dataForm;

    var AddMultiVariableLayout = this.state.showMultiVariable
        ? <AddMultiVariable
            onCloseAddMultiVariable={this.onCloseAddMultiVariable.bind(this)}
            callbackListFields={this.callbackListFields.bind(this)}
            auth={this.props.auth}
            curItem={curItem}
            t={t}
        /> : '';


    return (
        <div className='multi'>

            {this.state.showMultiVariable ? AddMultiVariableLayout :
                <Modal visible={true} className="modal-add google-form" dialogClassName="modal-lg modal-dialog-scrollable" >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {(curItem.screen_mode == Constants.SCREEN_MODE.ADD) ? t('common.add') : (curItem.screen_mode == Constants.SCREEN_MODE.EDIT ? t('common.edit') : "Import data")}
                        </h5>
                        <button type="button" onClick={this.onCloseAddPopup.bind(this)} className="bootbox-close-button close" data-dismiss="modal" aria-hidden="true"><span>×</span></button>
                    </div>
                    <div className="modal-body">
                        {curItem.screen_mode == Constants.SCREEN_MODE.VIEW ?
                            <div className="box-info">
                                <h2>File Upload</h2>
                                <div className="box-translate">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="mb-3">
                                                <input type="file" id="input_file" name='input_file' onChange={(e) => { this.handleFileInputChange(e) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ""}
                        {curItem.screen_mode == Constants.SCREEN_MODE.VIEW ?
                            <div className='process-loadding'>
                                <div className='process'>
                                    <span>{loadding}/ {Libs.formatNum(totalRow, '#,###')}</span>
                                    <div className='process-percent' style={{ width: (loadding / totalRow) * 100 + "%" }}>

                                    </div>
                                </div>
                            </div>
                            : ""}




                        {curItem.screen_mode != Constants.SCREEN_MODE.VIEW ?
                            <div className="box-info">
                                <h2>{t('common.basic_info')}</h2>
                                <div className="box-translate">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="mb-3">
                                                <RText label="Tên Form"
                                                    inputClass="form-control"
                                                    required="required"
                                                    inputId="name_form" inputName="name_form"
                                                    value={curItem.name_form}
                                                    disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                    onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="mb-3">
                                                <RText label="Form URL"
                                                    inputClass="form-control"
                                                    required="required"
                                                    inputId="url_form" inputName="url_form"
                                                    value={curItem.url_form}
                                                    disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                    onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ""}

                        {curItem.screen_mode != Constants.SCREEN_MODE.VIEW ?
                            <div className="box-info">
                                <h2>Fields</h2>
                                <div className="box-info-content">
                                    <DragDropContext onDragEnd={(e) => { this.handleDragEnd(e) }}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ width: "30%" }} scope="col">
                                                        Tên biến
                                                        <span onClick={this.addMultiVariable.bind(this)}  style={{ marginLeft: "5px", backgroundColor: "#d87140" }} className='addrow' >
                                                            <i className='icon-plus'></i>
                                                        </span>
                                                        </td>
                                                    <td style={{ width: "30%" }} scope="col">Key
                                                        <span onClick={this.addMultiVariable.bind(this)}  style={{ marginLeft: "5px", backgroundColor: "#d87140" }} className='addrow'>
                                                            <i className='icon-plus'></i>
                                                        </span>
                                                    </td>
                                                    <td style={{ width: "30%" }} scope="col">Loại câu hỏi</td>
                                                    <td style={{ width: "10%" }} scope="col" className='text-end'>
                                                        <span className='addrow' onClick={this.addRowItem.bind(this)}>
                                                            <i className='icon-plus'></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <Droppable droppableId="droppable-1">
                                                {(provider) => (
                                                    <tbody
                                                        className="text-capitalize"
                                                        ref={provider.innerRef}
                                                        {...provider.droppableProps}
                                                    >
                                                        {dataForm?.map((item, index) => (
                                                            <Draggable
                                                                key={index}
                                                                draggableId={"key_" + index}
                                                                index={index}
                                                            >
                                                                {(provider) => (
                                                                    <tr {...provider.draggableProps} ref={provider.innerRef}>
                                                                        <td {...provider.dragHandleProps} style={{ fontSize: "22px" }}> = </td>
                                                                        <td style={{ verticalAlign: "middle" }}> {index + 1} </td>

                                                                        <td style={{ width: "20%" }}>
                                                                            <RText
                                                                                inputClass="form-control"
                                                                                inputId="name"
                                                                                inputName="name"
                                                                                value={item.name}
                                                                                disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                                                onChange={(e) => { this.handleInputRowChange(e, index) }}
                                                                            />
                                                                        </td>
                                                                        <td style={{ width: "20%" }}>
                                                                            <RText
                                                                                inputClass="form-control"
                                                                                inputId="keyword"
                                                                                inputName="keyword"
                                                                                value={item.keyword}
                                                                                disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                                                onChange={(e) => { this.handleInputRowChange(e, index) }}
                                                                            />
                                                                        </td>

                                                                        <td style={{ width: "30%" }} scope="col">
                                                                            <FormReactSelect
                                                                                className="is_multi"
                                                                                name="is_multi"
                                                                                allowSearch={false}
                                                                                multiple={false}
                                                                                isClearable={false}
                                                                                value={options.filter(function (v) { return v.id == item.is_multi })}
                                                                                optionList={options}
                                                                                disabled={curItem.screen_mode == Constants.SCREEN_MODE.VIEW ? true : false}
                                                                                onChange={(ev) => { this.handleDropdownChange(ev, index); }}
                                                                            />

                                                                        </td>

                                                                        <td style={{ width: "10%" }} className="text-end">
                                                                            <span className='delete' onClick={this.onClickDeleteItem.bind(this, index)} >
                                                                                <i className='icon-trash-o'></i>
                                                                            </span>
                                                                        </td>

                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provider.placeholder}
                                                    </tbody>
                                                )}
                                            </Droppable>

                                            {/* <tbody>
                                    {dataFormItems}
                                </tbody> */}
                                        </table>
                                    </DragDropContext>



                                </div>
                            </div>
                            : ""}







                    </div>
                    <div className="modal-footer">
                        <RButton
                            onClick={this.onCloseAddPopup.bind(this)}
                            className="btn btn-cancel"
                            text={" " + t('common.cancel')}
                            title={t('common.cancel')} />

                        {curItem.screen_mode == Constants.SCREEN_MODE.VIEW ?
                            <RButton
                                onClick={this.onRunAction.bind(this)}
                                className="btn btn-danger btn-add"
                                text="Run"
                                title="Run" />
                            : ""}


                        {curItem.screen_mode != Constants.SCREEN_MODE.VIEW ?
                            <RButton
                                onClick={this.onSaveAction.bind(this)}
                                className="btn btn-danger btn-add"
                                text={(curItem.screen_mode == Constants.SCREEN_MODE.ADD) ? " " + t('common.add') : " " + t('common.update')}
                                iClass={curItem.screen_mode == Constants.SCREEN_MODE.ADD ? "icon-floppy-o" : ''}
                                title={(curItem.screen_mode == Constants.SCREEN_MODE.ADD) ? " " + t('common.add') : " " + t('common.update')} />
                            : ""}


                    </div>
                </Modal>
            }



        </div>


    )
}