import BaseComponent from '../../../BaseComponent';
import DataRegressionSevenJsx from './DataRegressionSeven.jsx';
import { withTranslation } from 'react-i18next';
import './DataRegressionSeven.scss';
import Libs from '../../../../utils/Libs';
import LoadDataDefault7 from '../../../../utils/LoadDataDefault7';
import { cloneDeep } from 'lodash-es';

class DataRegressionSeven extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.jsxTemplate = DataRegressionSevenJsx;
        this.state = {
            curItemReset: {
                func_one: "",
                func_two: "",
                func_three: "",
                totalRow: 1020,
                input: 5,
                output: 3,
                row_percent: 0,
                dataList: [],
                enable_output: 0,
                indexChange: []
            },
            curItem: {
                clickOne: 0,
                clickDataOne: 0,
                totalRow: 1020,
                input: 3,
                output: 4,
                row_apply: "1:1020",
                row_start: 0,
                row_end: 1020,
                row_percent: 0,

                func_one: "",
                func_two: "",
                func_three: "",

                func_oned: "",
                func_twod: "",
                func_threed: "",

                index_change: null,
                index_active: null,
                default_add: 0.15,
                enable_output: 0

            },
            dataInput: [],
            indexChange: [],
            dataOutput: [],
            showForm: false,
            dataList: [],
            dataCounts: {
                a: null,
                b: null,
                c: null,
                d: null,
                e: null,
                f: null,
                g: null,
                h: null,
                i: null,
                j: null,
                k: null,
                l: null,
                m: null,
                n: null,
                o: null,
                p: null,
                q: null,
                r: null,
                s: null,
                t: null,
                u: null,
                v: null,
                w: null,
                x: null,
                y: null,
                z: null,

                aa: null,
                ab: null,
                ac: null,
                ad: null,
                ae: null,
                af: null,
                ag: null,
                ah: null,
                ai: null,
                aj: null,
                ak: null,
                al: null,
                am: null,
                an: null,
                ao: null,
                ap: null,
                aq: null,
                ar: null,
                as: null,
                at: null,
                au: null,
                av: null,
                aw: null,
                ax: null,
                ay: null,
                az: null,

                ba: null,
                bb: null,
                bc: null,
                bd: null,
                be: null,
                bf: null,
                bg: null,
                bh: null,
                bi: null,
                bj: null,
                bk: null,
                bl: null,
                bm: null,
                bn: null,
                bo: null,
                bp: null,
                bq: null,
                br: null,
                bs: null,
                bt: null,
                bu: null,
                bv: null,
                bw: null,
                bx: null,
                by: null,
                bz: null,

                ca: null,
                cb: null,
                cc: null,
            }
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.loadDefaultData();
        var { curItem,curItemReset } = this.state;
        curItem.row_end = curItem.totalRow;
        var input = "";
        if (curItem.input > 0) {
            for (var i = 0; i < curItem.input; i++) {
                if (i == 0) { input = input + "A:"; }
                if (i == 1) { input = input + "B:"; }
                if (i == 2) { input = input + "C:"; }
                if (i == 3) { input = input + "D:"; }
                if (i == 4) { input = input + "E:"; }
                if (i == 5) { input = input + "F:"; }
                if (i == 6) { input = input + "G:"; }
                if (i == 7) { input = input + "H:"; }
                if (i == 8) { input = input + "I:"; }
                if (i == 9) { input = input + "J:"; }

                if (i == 10) { input = input + "K:"; }
                if (i == 11) { input = input + "L:"; }
                if (i == 12) { input = input + "M:"; }
                if (i == 13) { input = input + "N:"; }
                if (i == 14) { input = input + "O:"; }
                if (i == 15) { input = input + "P:"; }
                if (i == 16) { input = input + "Q:"; }
                if (i == 17) { input = input + "R:"; }
                if (i == 18) { input = input + "S:"; }
                if (i == 19) { input = input + "T:"; }

            }
            curItem.func_one = input.slice(0, -1);
            curItem.func_two = input.slice(0, -1);
            curItem.func_three = input.slice(0, -1);

            curItemReset.func_one = input.slice(0, -1);
            curItemReset.func_two = input.slice(0, -1);
            curItemReset.func_three = input.slice(0, -1);
            

            curItem.func_oned = curItem.func_one;
            curItem.func_twod = curItem.func_two;
            curItem.func_threed = curItem.func_three;
        }
    }

    handleInputSubChange = (index, event) => {
        let target = event.target;
        let value = target.value;
        var { dataInput } = this.state;
        dataInput[index] = value;
        this.setState({
            dataInput: dataInput
        })
    };

    handleInputSubChangeEnter = (index, event) => {
        var { curItem, dataList, dataInput } = this.state, self = this;
        if (event.key === 'Enter') {
            if (index == 0) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].a = Libs.round(Number(dataList[i].a) + Number(dataInput[index]), 2).toFixed(2);
                }
            } else if (index == 1) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].b = Libs.round(Number(dataList[i].b) + Number(dataInput[index]), 2).toFixed(2);
                }
            } else if (index == 2) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].c = Libs.round(Number(dataList[i].c) + Number(dataInput[index]), 2).toFixed(2);
                }
            } else if (index == 3) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].d = Libs.round(Number(dataList[i].d) + Number(dataInput[index]), 2).toFixed(2);
                }
            } else if (index == 4) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].e = Libs.round(Number(dataList[i].e) + Number(dataInput[index]), 2).toFixed(2);
                }
            } else if (index == 5) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].f = Libs.round(Number(dataList[i].f) + Number(dataInput[index]), 2).toFixed(2);
                }
            } else if (index == 6) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].g = Libs.round(Number(dataList[i].g) + Number(dataInput[index]), 2).toFixed(2);
                }
            } else if (index == 7) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].h = Libs.round(Number(dataList[i].h) + Number(dataInput[index]), 2).toFixed(2);
                }
            } else if (index == 8) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].i = Libs.round(Number(dataList[i].i) + Number(dataInput[index]), 2).toFixed(2);
                }
            } else if (index == 9) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].j = Libs.round(Number(dataList[i].j) + Number(dataInput[index]), 2).toFixed(2);
                }
            }

            else if (index == 10) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].k = Libs.round(Number(dataList[i].k) + Number(dataInput[index]), 2).toFixed(2);
                }
            }else if (index == 11) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].l = Libs.round(Number(dataList[i].l) + Number(dataInput[index]), 2).toFixed(2);
                }
            }else if (index == 12) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].m = Libs.round(Number(dataList[i].m) + Number(dataInput[index]), 2).toFixed(2);
                }
            }else if (index == 13) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].n = Libs.round(Number(dataList[i].n) + Number(dataInput[index]), 2).toFixed(2);
                }
            }else if (index == 14) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].o = Libs.round(Number(dataList[i].o) + Number(dataInput[index]), 2).toFixed(2);
                }
            }else if (index == 15) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].p = Libs.round(Number(dataList[i].p) + Number(dataInput[index]), 2).toFixed(2);
                }
            }else if (index == 16) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].q = Libs.round(Number(dataList[i].q) + Number(dataInput[index]), 2).toFixed(2);
                }
            }else if (index == 17) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].r = Libs.round(Number(dataList[i].r) + Number(dataInput[index]), 2).toFixed(2);
                }
            }else if (index == 18) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].s = Libs.round(Number(dataList[i].s) + Number(dataInput[index]), 2).toFixed(2);
                }
            }else if (index == 19) {
                for (var i = 0; i < dataList.length; i++) {
                    dataList[i].t = Libs.round(Number(dataList[i].t) + Number(dataInput[index]), 2).toFixed(2);
                }
            }


            this.setState({
                dataList: dataList
            }, () => {
                self.applyFunction(1);
            });
        }
    }


    applyDefaultValue(event) {
        var { curItem, dataList } = this.state;
        if (event.key === 'Enter') {
            if (Libs.isArrayData(dataList)) {
                for (var i = 0; i < dataList.length; i++) {
                    if (curItem.input == 1) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                    } else if (curItem.input == 2) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                    } else if (curItem.input == 3) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                    } else if (curItem.input == 4) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                    } else if (curItem.input == 5) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                    } else if (curItem.input == 6) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                    } else if (curItem.input == 7) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                    } else if (curItem.input == 8) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                    } else if (curItem.input == 9) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].i = Libs.round(Number(dataList[i].i) + Number(curItem.default_add), 2).toFixed(2);
                    } else if (curItem.input == 10) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].i = Libs.round(Number(dataList[i].i) + Number(curItem.default_add), 2).toFixed(2);
                    }
                    else if (curItem.input == 11) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].i = Libs.round(Number(dataList[i].i) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].j = Libs.round(Number(dataList[i].j) + Number(curItem.default_add), 2).toFixed(2);
                    }else if (curItem.input == 12) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].i = Libs.round(Number(dataList[i].i) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].j = Libs.round(Number(dataList[i].j) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].k = Libs.round(Number(dataList[i].k) + Number(curItem.default_add), 2).toFixed(2);
                    }
                    else if (curItem.input == 12) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].i = Libs.round(Number(dataList[i].i) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].j = Libs.round(Number(dataList[i].j) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].k = Libs.round(Number(dataList[i].k) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].l = Libs.round(Number(dataList[i].l) + Number(curItem.default_add), 2).toFixed(2);
                    }
                    else if (curItem.input == 13) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].i = Libs.round(Number(dataList[i].i) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].j = Libs.round(Number(dataList[i].j) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].k = Libs.round(Number(dataList[i].k) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].l = Libs.round(Number(dataList[i].l) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].m = Libs.round(Number(dataList[i].m) + Number(curItem.default_add), 2).toFixed(2);
                    }
                    else if (curItem.input == 14) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].i = Libs.round(Number(dataList[i].i) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].j = Libs.round(Number(dataList[i].j) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].k = Libs.round(Number(dataList[i].k) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].l = Libs.round(Number(dataList[i].l) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].m = Libs.round(Number(dataList[i].m) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].n = Libs.round(Number(dataList[i].n) + Number(curItem.default_add), 2).toFixed(2);
                    }
                    else if (curItem.input == 15) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].i = Libs.round(Number(dataList[i].i) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].j = Libs.round(Number(dataList[i].j) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].k = Libs.round(Number(dataList[i].k) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].l = Libs.round(Number(dataList[i].l) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].m = Libs.round(Number(dataList[i].m) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].n = Libs.round(Number(dataList[i].n) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].o = Libs.round(Number(dataList[i].o) + Number(curItem.default_add), 2).toFixed(2);
                    }
                    else if (curItem.input == 16) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].i = Libs.round(Number(dataList[i].i) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].j = Libs.round(Number(dataList[i].j) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].k = Libs.round(Number(dataList[i].k) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].l = Libs.round(Number(dataList[i].l) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].m = Libs.round(Number(dataList[i].m) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].n = Libs.round(Number(dataList[i].n) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].o = Libs.round(Number(dataList[i].o) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].p = Libs.round(Number(dataList[i].p) + Number(curItem.default_add), 2).toFixed(2);
                    }
                    else if (curItem.input == 17) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].i = Libs.round(Number(dataList[i].i) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].j = Libs.round(Number(dataList[i].j) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].k = Libs.round(Number(dataList[i].k) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].l = Libs.round(Number(dataList[i].l) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].m = Libs.round(Number(dataList[i].m) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].n = Libs.round(Number(dataList[i].n) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].o = Libs.round(Number(dataList[i].o) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].p = Libs.round(Number(dataList[i].p) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].q = Libs.round(Number(dataList[i].q) + Number(curItem.default_add), 2).toFixed(2);
                    }
                    else if (curItem.input == 18) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].i = Libs.round(Number(dataList[i].i) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].j = Libs.round(Number(dataList[i].j) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].k = Libs.round(Number(dataList[i].k) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].l = Libs.round(Number(dataList[i].l) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].m = Libs.round(Number(dataList[i].m) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].n = Libs.round(Number(dataList[i].n) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].o = Libs.round(Number(dataList[i].o) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].p = Libs.round(Number(dataList[i].p) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].r = Libs.round(Number(dataList[i].r) + Number(curItem.default_add), 2).toFixed(2);
                    }
                    else if (curItem.input == 19) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].i = Libs.round(Number(dataList[i].i) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].j = Libs.round(Number(dataList[i].j) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].k = Libs.round(Number(dataList[i].k) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].l = Libs.round(Number(dataList[i].l) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].m = Libs.round(Number(dataList[i].m) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].n = Libs.round(Number(dataList[i].n) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].o = Libs.round(Number(dataList[i].o) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].p = Libs.round(Number(dataList[i].p) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].r = Libs.round(Number(dataList[i].r) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].s = Libs.round(Number(dataList[i].s) + Number(curItem.default_add), 2).toFixed(2);
                    }
                    else if (curItem.input == 20) {
                        dataList[i].a = Libs.round(Number(dataList[i].a) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].b = Libs.round(Number(dataList[i].b) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].c = Libs.round(Number(dataList[i].c) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].d = Libs.round(Number(dataList[i].d) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].e = Libs.round(Number(dataList[i].e) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].f = Libs.round(Number(dataList[i].f) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].g = Libs.round(Number(dataList[i].g) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].h = Libs.round(Number(dataList[i].h) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].i = Libs.round(Number(dataList[i].i) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].j = Libs.round(Number(dataList[i].j) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].k = Libs.round(Number(dataList[i].k) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].l = Libs.round(Number(dataList[i].l) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].m = Libs.round(Number(dataList[i].m) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].n = Libs.round(Number(dataList[i].n) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].o = Libs.round(Number(dataList[i].o) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].p = Libs.round(Number(dataList[i].p) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].r = Libs.round(Number(dataList[i].r) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].s = Libs.round(Number(dataList[i].s) + Number(curItem.default_add), 2).toFixed(2);
                        dataList[i].t = Libs.round(Number(dataList[i].t) + Number(curItem.default_add), 2).toFixed(2);
                    }
                }
            }
            this.setState({
                dataList: dataList
            }, () => {
                this.applyFunction(1);
            });
        }
    }

    onClickShowForm = (index) => {
        var { curItem } = this.state;
        curItem.index_change = index;
        this.setState({
            showForm: this.state.showForm ? false : true,
            curItem: curItem
        })
    }

    onClickCloseShowForm = (item) => {
        var { dataList, indexChange } = this.state, self = this;

        var itemFind = Libs.find(indexChange, 'id', item.index_change);
        if(Libs.isObjectEmpty(itemFind)){
            indexChange.push({id: item.index_change });
        }
        if (!Libs.isObjectEmpty(item)) {
            if (Libs.isArrayData(dataList)) {
                for (var i = 0; i < dataList.length; i++) {
                    if (!Libs.isBlank(item.value_add)) {
                        if (item.index_active == 0) {
                            dataList[i].a = Libs.round(((5 - dataList[i].a) + Number(item.value_add)), 2).toFixed(2);
                        } else if (item.index_active == 1) {
                            dataList[i].b = Libs.round(((5 - dataList[i].b) + Number(item.value_add)), 2).toFixed(2);
                        } else if (item.index_active == 2) {
                            dataList[i].c = Libs.round(((5 - dataList[i].c) + Number(item.value_add)), 2).toFixed(2);
                        } else if (item.index_active == 3) {
                            dataList[i].d = Libs.round(((5 - dataList[i].d) + Number(item.value_add)), 2).toFixed(2);
                        } else if (item.index_active == 4) {
                            dataList[i].e = Libs.round(((5 - dataList[i].e) + Number(item.value_add)), 2).toFixed(2);
                        } else if (item.index_active == 5) {
                            dataList[i].f = Libs.round(((5 - dataList[i].f) + Number(item.value_add)), 2).toFixed(2);
                        } else if (item.index_active == 6) {
                            dataList[i].g = Libs.round(((5 - dataList[i].g) + Number(item.value_add)), 2).toFixed(2);
                        } else if (item.index_active == 7) {
                            dataList[i].h = Libs.round(((5 - dataList[i].h) + Number(item.value_add)), 2).toFixed(2);
                        } else if (item.index_active == 8) {
                            dataList[i].i = Libs.round(((5 - dataList[i].i) + Number(item.value_add)), 2).toFixed(2);
                        } else if (item.index_active == 9) {
                            dataList[i].j = Libs.round(((5 - dataList[i].j) + Number(item.value_add)), 2).toFixed(2);
                        }

                        else if (item.index_active == 10) {
                            dataList[i].k = Libs.round(((5 - dataList[i].k) + Number(item.value_add)), 2).toFixed(2);
                        }else if (item.index_active == 11) {
                            dataList[i].l = Libs.round(((5 - dataList[i].l) + Number(item.value_add)), 2).toFixed(2);
                        }else if (item.index_active == 12) {
                            dataList[i].m = Libs.round(((5 - dataList[i].m) + Number(item.value_add)), 2).toFixed(2);
                        }else if (item.index_active == 13) {
                            dataList[i].n = Libs.round(((5 - dataList[i].n) + Number(item.value_add)), 2).toFixed(2);
                        }else if (item.index_active == 14) {
                            dataList[i].o = Libs.round(((5 - dataList[i].o) + Number(item.value_add)), 2).toFixed(2);
                        }else if (item.index_active == 15) {
                            dataList[i].p = Libs.round(((5 - dataList[i].p) + Number(item.value_add)), 2).toFixed(2);
                        }else if (item.index_active == 16) {
                            dataList[i].q = Libs.round(((5 - dataList[i].q) + Number(item.value_add)), 2).toFixed(2);
                        }else if (item.index_active == 17) {
                            dataList[i].r = Libs.round(((5 - dataList[i].r) + Number(item.value_add)), 2).toFixed(2);
                        }else if (item.index_active == 18) {
                            dataList[i].s = Libs.round(((5 - dataList[i].s) + Number(item.value_add)), 2).toFixed(2);
                        }else if (item.index_active == 19) {
                            dataList[i].t = Libs.round(((5 - dataList[i].t) + Number(item.value_add)), 2).toFixed(2);
                        }
                    }
                }
            }
            this.setState({
                curItem: item,
                dataList: dataList,
                showForm: false,
                indexChange: indexChange
            }, () => {
                self.applyFunction(1);
            });
        } else {
            this.setState({
                showForm: false
            })
        }

    }

    changeData() {
        this.applyFunction(1);
    }

    resetFunctionDefault() {
        var { curItem, dataInput, curItemReset, dataList } = this.state, self = this;
        curItem.func_one = curItemReset.func_one;
        curItem.func_two = curItemReset.func_two;
        curItem.func_three = curItemReset.func_three;

        curItem.input = curItemReset.input;
        curItem.output = curItemReset.output;
        curItem.totalRow = curItemReset.totalRow;
        curItem.row_percent = curItemReset.row_percent;

        dataList = curItemReset.dataList;



        curItem.row_end = curItem.totalRow;
        curItem.row_start = 0;
        curItem.row_percent = 0;
        if (dataInput.length > 0) {
            for (var i = 0; i < dataInput.length; i++) {
                dataInput[i] = null;
            }
        }

        this.setState({ curItem: curItem, dataInput: dataInput , dataList: dataList }, () => {
            self.applyFunction(1);
        })
    }

    applyFunction(callIdex) {
        var { curItem, dataList, curItemReset } = this.state, self = this;

        if(curItem.clickOne == 0 && callIdex != 1){ 
            curItem.clickOne = 1;
            curItemReset.func_one = curItem.func_one;
            curItemReset.func_two = curItem.func_two;
            curItemReset.func_three = curItem.func_three;
            curItemReset.row_percent = curItem.row_percent;
            curItemReset.input = curItem.input;
            curItemReset.output = curItem.output;
            curItemReset.totalRow = curItem.totalRow;
            curItemReset.dataList = dataList;

        }

        var fieldInput = [], dataFieldOutPut = [], dataFieldOutPutOne = [], dataFieldOutPutTwo = [], dataFieldOutPutThree = [];

        if (!Libs.isBlank(curItem.input)) {
            for (var i = 0; i < curItem.input; i++) {
                switch (i) {
                    case 0: fieldInput.push({ id: 'a' }); break;
                    case 1: fieldInput.push({ id: 'b' }); break;
                    case 2: fieldInput.push({ id: 'c' }); break;
                    case 3: fieldInput.push({ id: 'd' }); break;
                    case 4: fieldInput.push({ id: 'e' }); break;
                    case 5: fieldInput.push({ id: 'f' }); break;
                    case 6: fieldInput.push({ id: 'g' }); break;
                    case 7: fieldInput.push({ id: 'h' }); break;
                    case 8: fieldInput.push({ id: 'i' }); break;
                    case 9: fieldInput.push({ id: 'j' }); break;
                    case 10: fieldInput.push({ id: 'k' }); break;
                    case 11: fieldInput.push({ id: 'l' }); break;
                    case 12: fieldInput.push({ id: 'm' }); break;
                    case 13: fieldInput.push({ id: 'n' }); break;
                    case 14: fieldInput.push({ id: 'o' }); break;
                    case 15: fieldInput.push({ id: 'p' }); break;
                    case 16: fieldInput.push({ id: 'q' }); break;
                    case 17: fieldInput.push({ id: 'r' }); break;
                    case 18: fieldInput.push({ id: 's' }); break;
                    case 19: fieldInput.push({ id: 't' }); break;
                }
            }
        }

        var countTmp = parseInt(curItem.input) + parseInt(curItem.output) - 1;
        for (var v = (parseInt(curItem.input) - 1); v < countTmp; v++) {
            switch (v) {
                case 0: dataFieldOutPut.push({ id: 'b' }); break;
                case 1: dataFieldOutPut.push({ id: 'c' }); break;
                case 2: dataFieldOutPut.push({ id: 'd' }); break;
                case 3: dataFieldOutPut.push({ id: 'e' }); break;
                case 4: dataFieldOutPut.push({ id: 'f' }); break;
                case 5: dataFieldOutPut.push({ id: 'g' }); break;
                case 6: dataFieldOutPut.push({ id: 'h' }); break;
                case 7: dataFieldOutPut.push({ id: 'i' }); break;
                case 8: dataFieldOutPut.push({ id: 'j' }); break;
                case 9: dataFieldOutPut.push({ id: 'k' }); break;
                case 10: dataFieldOutPut.push({ id: 'l' }); break;
                case 11: dataFieldOutPut.push({ id: 'm' }); break;
                case 12: dataFieldOutPut.push({ id: 'n' }); break;
                case 13: dataFieldOutPut.push({ id: 'o' }); break;
                case 14: dataFieldOutPut.push({ id: 'p' }); break;
                case 15: dataFieldOutPut.push({ id: 'q' }); break;
                case 16: dataFieldOutPut.push({ id: 'r' }); break;
                case 17: dataFieldOutPut.push({ id: 's' }); break;
                case 18: dataFieldOutPut.push({ id: 't' }); break;
                case 19: dataFieldOutPut.push({ id: 'u' }); break;
                case 20: dataFieldOutPut.push({ id: 'v' }); break;
                case 21: dataFieldOutPut.push({ id: 'w' }); break;
                case 22: dataFieldOutPut.push({ id: 'x' }); break;
                case 23: dataFieldOutPut.push({ id: 'y' }); break;
                case 24: dataFieldOutPut.push({ id: 'z' }); break;
                case 25: dataFieldOutPut.push({ id: 'aa' }); break;
                case 26: dataFieldOutPut.push({ id: 'ab' }); break;
                case 27: dataFieldOutPut.push({ id: 'ac' }); break;
                case 28: dataFieldOutPut.push({ id: 'ad' }); break;
                case 29: dataFieldOutPut.push({ id: 'ae' }); break;
                case 30: dataFieldOutPut.push({ id: 'af' }); break;
                case 31: dataFieldOutPut.push({ id: 'ag' }); break;
                case 32: dataFieldOutPut.push({ id: 'ah' }); break;
                case 33: dataFieldOutPut.push({ id: 'ai' }); break;
                case 34: dataFieldOutPut.push({ id: 'aj' }); break;
                case 35: dataFieldOutPut.push({ id: 'ak' }); break;
                case 36: dataFieldOutPut.push({ id: 'al' }); break;
                case 37: dataFieldOutPut.push({ id: 'am' }); break;
                case 38: dataFieldOutPut.push({ id: 'an' }); break;
                case 39: dataFieldOutPut.push({ id: 'ao' }); break;
                case 40: dataFieldOutPut.push({ id: 'ap' }); break;

            }
        }

        var countStartOne = parseInt(curItem.input) + (parseInt(curItem.output) * 2) - 1;
        for (var t = ((parseInt(curItem.input) + parseInt(curItem.output)) - 1); t < countStartOne; t++) {
            switch (t) {
                case 0: dataFieldOutPutOne.push({ id: 'b' }); break;
                case 1: dataFieldOutPutOne.push({ id: 'c' }); break;
                case 2: dataFieldOutPutOne.push({ id: 'd' }); break;
                case 3: dataFieldOutPutOne.push({ id: 'e' }); break;
                case 4: dataFieldOutPutOne.push({ id: 'f' }); break;
                case 5: dataFieldOutPutOne.push({ id: 'g' }); break;
                case 6: dataFieldOutPutOne.push({ id: 'h' }); break;
                case 7: dataFieldOutPutOne.push({ id: 'i' }); break;
                case 8: dataFieldOutPutOne.push({ id: 'j' }); break;
                case 9: dataFieldOutPutOne.push({ id: 'k' }); break;
                case 10: dataFieldOutPutOne.push({ id: 'l' }); break;
                case 11: dataFieldOutPutOne.push({ id: 'm' }); break;
                case 12: dataFieldOutPutOne.push({ id: 'n' }); break;
                case 13: dataFieldOutPutOne.push({ id: 'o' }); break;
                case 14: dataFieldOutPutOne.push({ id: 'p' }); break;
                case 15: dataFieldOutPutOne.push({ id: 'q' }); break;
                case 16: dataFieldOutPutOne.push({ id: 'r' }); break;
                case 17: dataFieldOutPutOne.push({ id: 's' }); break;
                case 18: dataFieldOutPutOne.push({ id: 't' }); break;
                case 19: dataFieldOutPutOne.push({ id: 'u' }); break;
                case 20: dataFieldOutPutOne.push({ id: 'v' }); break;
                case 21: dataFieldOutPutOne.push({ id: 'w' }); break;
                case 22: dataFieldOutPutOne.push({ id: 'x' }); break;
                case 23: dataFieldOutPutOne.push({ id: 'y' }); break;
                case 24: dataFieldOutPutOne.push({ id: 'z' }); break;
                case 25: dataFieldOutPutOne.push({ id: 'aa' }); break;
                case 26: dataFieldOutPutOne.push({ id: 'ab' }); break;
                case 27: dataFieldOutPutOne.push({ id: 'ac' }); break;
                case 28: dataFieldOutPutOne.push({ id: 'ad' }); break;
                case 29: dataFieldOutPutOne.push({ id: 'ae' }); break;
                case 30: dataFieldOutPutOne.push({ id: 'af' }); break;
                case 31: dataFieldOutPutOne.push({ id: 'ag' }); break;
                case 32: dataFieldOutPutOne.push({ id: 'ah' }); break;
                case 33: dataFieldOutPutOne.push({ id: 'ai' }); break;
                case 34: dataFieldOutPutOne.push({ id: 'aj' }); break;
                case 35: dataFieldOutPutOne.push({ id: 'ak' }); break;
                case 36: dataFieldOutPutOne.push({ id: 'al' }); break;
                case 37: dataFieldOutPutOne.push({ id: 'am' }); break;
                case 38: dataFieldOutPutOne.push({ id: 'an' }); break;
                case 39: dataFieldOutPutOne.push({ id: 'ao' }); break;
                case 40: dataFieldOutPutOne.push({ id: 'ap' }); break;
                case 41: dataFieldOutPutOne.push({ id: 'aq' }); break;
                case 42: dataFieldOutPutOne.push({ id: 'ar' }); break;
                case 43: dataFieldOutPutOne.push({ id: 'as' }); break;
                case 44: dataFieldOutPutOne.push({ id: 'at' }); break;
                case 45: dataFieldOutPutOne.push({ id: 'au' }); break;
                case 46: dataFieldOutPutOne.push({ id: 'av' }); break;
                case 47: dataFieldOutPutOne.push({ id: 'aw' }); break;
                case 48: dataFieldOutPutOne.push({ id: 'ax' }); break;
                case 49: dataFieldOutPutOne.push({ id: 'ay' }); break;
                case 50: dataFieldOutPutOne.push({ id: 'az' }); break;
                case 51: dataFieldOutPutOne.push({ id: 'ba' }); break;
                case 52: dataFieldOutPutOne.push({ id: 'bb' }); break;
                case 53: dataFieldOutPutOne.push({ id: 'bc' }); break;
                case 54: dataFieldOutPutOne.push({ id: 'bd' }); break;
                case 55: dataFieldOutPutOne.push({ id: 'be' }); break;
                case 56: dataFieldOutPutOne.push({ id: 'bf' }); break;
                case 57: dataFieldOutPutOne.push({ id: 'bg' }); break;
                case 58: dataFieldOutPutOne.push({ id: 'bh' }); break;
                case 59: dataFieldOutPutOne.push({ id: 'bi' }); break;
                case 60: dataFieldOutPutOne.push({ id: 'bj' }); break;
                case 61: dataFieldOutPutOne.push({ id: 'bk' }); break;
                case 62: dataFieldOutPutOne.push({ id: 'bl' }); break;
                case 63: dataFieldOutPutOne.push({ id: 'bm' }); break;
                case 64: dataFieldOutPutOne.push({ id: 'bn' }); break;
                case 65: dataFieldOutPutOne.push({ id: 'bo' }); break;
                case 66: dataFieldOutPutOne.push({ id: 'bp' }); break;
                case 67: dataFieldOutPutOne.push({ id: 'bq' }); break;
                case 68: dataFieldOutPutOne.push({ id: 'br' }); break;
                case 69: dataFieldOutPutOne.push({ id: 'bs' }); break;
                case 70: dataFieldOutPutOne.push({ id: 'bt' }); break;
                case 71: dataFieldOutPutOne.push({ id: 'bu' }); break;
                case 72: dataFieldOutPutOne.push({ id: 'bv' }); break;
                case 73: dataFieldOutPutOne.push({ id: 'bw' }); break;
                case 74: dataFieldOutPutOne.push({ id: 'bx' }); break;
                case 75: dataFieldOutPutOne.push({ id: 'by' }); break;
                case 76: dataFieldOutPutOne.push({ id: 'bz' }); break;
                case 77: dataFieldOutPutOne.push({ id: 'ca' }); break;
                case 78: dataFieldOutPutOne.push({ id: 'cb' }); break;
                case 79: dataFieldOutPutOne.push({ id: 'cc' }); break;
                case 80: dataFieldOutPutOne.push({ id: 'cd' }); break;


            }
        }

        var countStartTwo = parseInt(curItem.input) + (parseInt(curItem.output) * 3) - 1;

        for (var x = ((parseInt(curItem.input) + (parseInt(curItem.output) * 2)) - 1); x < countStartTwo; x++) {
            switch (x) {
                case 0: dataFieldOutPutTwo.push({ id: 'b' }); break;
                case 1: dataFieldOutPutTwo.push({ id: 'c' }); break;
                case 2: dataFieldOutPutTwo.push({ id: 'd' }); break;
                case 3: dataFieldOutPutTwo.push({ id: 'e' }); break;
                case 4: dataFieldOutPutTwo.push({ id: 'f' }); break;
                case 5: dataFieldOutPutTwo.push({ id: 'g' }); break;
                case 6: dataFieldOutPutTwo.push({ id: 'h' }); break;
                case 7: dataFieldOutPutTwo.push({ id: 'i' }); break;
                case 8: dataFieldOutPutTwo.push({ id: 'j' }); break;
                case 9: dataFieldOutPutTwo.push({ id: 'k' }); break;
                case 10: dataFieldOutPutTwo.push({ id: 'l' }); break;
                case 11: dataFieldOutPutTwo.push({ id: 'm' }); break;
                case 12: dataFieldOutPutTwo.push({ id: 'n' }); break;
                case 13: dataFieldOutPutTwo.push({ id: 'o' }); break;
                case 14: dataFieldOutPutTwo.push({ id: 'p' }); break;
                case 15: dataFieldOutPutTwo.push({ id: 'q' }); break;
                case 16: dataFieldOutPutTwo.push({ id: 'r' }); break;
                case 17: dataFieldOutPutTwo.push({ id: 's' }); break;
                case 18: dataFieldOutPutTwo.push({ id: 't' }); break;
                case 19: dataFieldOutPutTwo.push({ id: 'u' }); break;
                case 20: dataFieldOutPutTwo.push({ id: 'v' }); break;
                case 21: dataFieldOutPutTwo.push({ id: 'w' }); break;
                case 22: dataFieldOutPutTwo.push({ id: 'x' }); break;
                case 23: dataFieldOutPutTwo.push({ id: 'y' }); break;
                case 24: dataFieldOutPutTwo.push({ id: 'z' }); break;
                case 25: dataFieldOutPutTwo.push({ id: 'aa' }); break;
                case 26: dataFieldOutPutTwo.push({ id: 'ab' }); break;
                case 27: dataFieldOutPutTwo.push({ id: 'ac' }); break;
                case 28: dataFieldOutPutTwo.push({ id: 'ad' }); break;
                case 29: dataFieldOutPutTwo.push({ id: 'ae' }); break;
                case 30: dataFieldOutPutTwo.push({ id: 'af' }); break;
                case 31: dataFieldOutPutTwo.push({ id: 'ag' }); break;
                case 32: dataFieldOutPutTwo.push({ id: 'ah' }); break;
                case 33: dataFieldOutPutTwo.push({ id: 'ai' }); break;
                case 34: dataFieldOutPutTwo.push({ id: 'aj' }); break;
                case 35: dataFieldOutPutTwo.push({ id: 'ak' }); break;
                case 36: dataFieldOutPutTwo.push({ id: 'al' }); break;
                case 37: dataFieldOutPutTwo.push({ id: 'am' }); break;
                case 38: dataFieldOutPutTwo.push({ id: 'an' }); break;
                case 39: dataFieldOutPutTwo.push({ id: 'ao' }); break;
                case 40: dataFieldOutPutTwo.push({ id: 'ap' }); break;
                case 41: dataFieldOutPutTwo.push({ id: 'aq' }); break;
                case 42: dataFieldOutPutTwo.push({ id: 'ar' }); break;
                case 43: dataFieldOutPutTwo.push({ id: 'as' }); break;
                case 44: dataFieldOutPutTwo.push({ id: 'at' }); break;
                case 45: dataFieldOutPutTwo.push({ id: 'au' }); break;
                case 46: dataFieldOutPutTwo.push({ id: 'av' }); break;
                case 47: dataFieldOutPutTwo.push({ id: 'aw' }); break;
                case 48: dataFieldOutPutTwo.push({ id: 'ax' }); break;
                case 49: dataFieldOutPutTwo.push({ id: 'ay' }); break;
                case 50: dataFieldOutPutTwo.push({ id: 'az' }); break;
                case 51: dataFieldOutPutTwo.push({ id: 'ba' }); break;
                case 52: dataFieldOutPutTwo.push({ id: 'bb' }); break;
                case 53: dataFieldOutPutTwo.push({ id: 'bc' }); break;
                case 54: dataFieldOutPutTwo.push({ id: 'bd' }); break;
                case 55: dataFieldOutPutTwo.push({ id: 'be' }); break;
                case 56: dataFieldOutPutTwo.push({ id: 'bf' }); break;
                case 57: dataFieldOutPutTwo.push({ id: 'bg' }); break;
                case 58: dataFieldOutPutTwo.push({ id: 'bh' }); break;
                case 59: dataFieldOutPutTwo.push({ id: 'bi' }); break;
                case 60: dataFieldOutPutTwo.push({ id: 'bj' }); break;
                case 61: dataFieldOutPutTwo.push({ id: 'bk' }); break;
                case 62: dataFieldOutPutTwo.push({ id: 'bl' }); break;
                case 63: dataFieldOutPutTwo.push({ id: 'bm' }); break;
                case 64: dataFieldOutPutTwo.push({ id: 'bn' }); break;
                case 65: dataFieldOutPutTwo.push({ id: 'bo' }); break;
                case 66: dataFieldOutPutTwo.push({ id: 'bp' }); break;
                case 67: dataFieldOutPutTwo.push({ id: 'bq' }); break;
                case 68: dataFieldOutPutTwo.push({ id: 'br' }); break;
                case 69: dataFieldOutPutTwo.push({ id: 'bs' }); break;
                case 70: dataFieldOutPutTwo.push({ id: 'bt' }); break;
                case 71: dataFieldOutPutTwo.push({ id: 'bu' }); break;
                case 72: dataFieldOutPutTwo.push({ id: 'bv' }); break;
                case 73: dataFieldOutPutTwo.push({ id: 'bw' }); break;
                case 74: dataFieldOutPutTwo.push({ id: 'bx' }); break;
                case 75: dataFieldOutPutTwo.push({ id: 'by' }); break;
                case 76: dataFieldOutPutTwo.push({ id: 'bz' }); break;
                case 77: dataFieldOutPutTwo.push({ id: 'ca' }); break;
                case 78: dataFieldOutPutTwo.push({ id: 'cb' }); break;
                case 79: dataFieldOutPutTwo.push({ id: 'cc' }); break;
                case 80: dataFieldOutPutTwo.push({ id: 'cd' }); break;
            }
        }

        var countStartThree = parseInt(curItem.input) + (parseInt(curItem.output) * 4) - 1;

        for (var x = ((parseInt(curItem.input) + (parseInt(curItem.output) * 3)) - 1); x < countStartThree; x++) {
            switch (x) {
                case 0: dataFieldOutPutThree.push({ id: 'b' }); break;
                case 1: dataFieldOutPutThree.push({ id: 'c' }); break;
                case 2: dataFieldOutPutThree.push({ id: 'd' }); break;
                case 3: dataFieldOutPutThree.push({ id: 'e' }); break;
                case 4: dataFieldOutPutThree.push({ id: 'f' }); break;
                case 5: dataFieldOutPutThree.push({ id: 'g' }); break;
                case 6: dataFieldOutPutThree.push({ id: 'h' }); break;
                case 7: dataFieldOutPutThree.push({ id: 'i' }); break;
                case 8: dataFieldOutPutThree.push({ id: 'j' }); break;
                case 9: dataFieldOutPutThree.push({ id: 'k' }); break;
                case 10: dataFieldOutPutThree.push({ id: 'l' }); break;
                case 11: dataFieldOutPutThree.push({ id: 'm' }); break;
                case 12: dataFieldOutPutThree.push({ id: 'n' }); break;
                case 13: dataFieldOutPutThree.push({ id: 'o' }); break;
                case 14: dataFieldOutPutThree.push({ id: 'p' }); break;
                case 15: dataFieldOutPutThree.push({ id: 'q' }); break;
                case 16: dataFieldOutPutThree.push({ id: 'r' }); break;
                case 17: dataFieldOutPutThree.push({ id: 's' }); break;
                case 18: dataFieldOutPutThree.push({ id: 't' }); break;
                case 19: dataFieldOutPutThree.push({ id: 'u' }); break;
                case 20: dataFieldOutPutThree.push({ id: 'v' }); break;
                case 21: dataFieldOutPutThree.push({ id: 'w' }); break;
                case 22: dataFieldOutPutThree.push({ id: 'x' }); break;
                case 23: dataFieldOutPutThree.push({ id: 'y' }); break;
                case 24: dataFieldOutPutThree.push({ id: 'z' }); break;
                case 25: dataFieldOutPutThree.push({ id: 'aa' }); break;
                case 26: dataFieldOutPutThree.push({ id: 'ab' }); break;
                case 27: dataFieldOutPutThree.push({ id: 'ac' }); break;
                case 28: dataFieldOutPutThree.push({ id: 'ad' }); break;
                case 29: dataFieldOutPutThree.push({ id: 'ae' }); break;
                case 30: dataFieldOutPutThree.push({ id: 'af' }); break;
                case 31: dataFieldOutPutThree.push({ id: 'ag' }); break;
                case 32: dataFieldOutPutThree.push({ id: 'ah' }); break;
                case 33: dataFieldOutPutThree.push({ id: 'ai' }); break;
                case 34: dataFieldOutPutThree.push({ id: 'aj' }); break;
                case 35: dataFieldOutPutThree.push({ id: 'ak' }); break;
                case 36: dataFieldOutPutThree.push({ id: 'al' }); break;
                case 37: dataFieldOutPutThree.push({ id: 'am' }); break;
                case 38: dataFieldOutPutThree.push({ id: 'an' }); break;
                case 39: dataFieldOutPutThree.push({ id: 'ao' }); break;
                case 40: dataFieldOutPutThree.push({ id: 'ap' }); break;
                case 41: dataFieldOutPutThree.push({ id: 'aq' }); break;
                case 42: dataFieldOutPutThree.push({ id: 'ar' }); break;
                case 43: dataFieldOutPutThree.push({ id: 'as' }); break;
                case 44: dataFieldOutPutThree.push({ id: 'at' }); break;
                case 45: dataFieldOutPutThree.push({ id: 'au' }); break;
                case 46: dataFieldOutPutThree.push({ id: 'av' }); break;
                case 47: dataFieldOutPutThree.push({ id: 'aw' }); break;
                case 48: dataFieldOutPutThree.push({ id: 'ax' }); break;
                case 49: dataFieldOutPutThree.push({ id: 'ay' }); break;
                case 50: dataFieldOutPutThree.push({ id: 'az' }); break;
                case 51: dataFieldOutPutThree.push({ id: 'ba' }); break;
                case 52: dataFieldOutPutThree.push({ id: 'bb' }); break;
                case 53: dataFieldOutPutThree.push({ id: 'bc' }); break;
                case 54: dataFieldOutPutThree.push({ id: 'bd' }); break;
                case 55: dataFieldOutPutThree.push({ id: 'be' }); break;
                case 56: dataFieldOutPutThree.push({ id: 'bf' }); break;
                case 57: dataFieldOutPutThree.push({ id: 'bg' }); break;
                case 58: dataFieldOutPutThree.push({ id: 'bh' }); break;
                case 59: dataFieldOutPutThree.push({ id: 'bi' }); break;
                case 60: dataFieldOutPutThree.push({ id: 'bj' }); break;
                case 61: dataFieldOutPutThree.push({ id: 'bk' }); break;
                case 62: dataFieldOutPutThree.push({ id: 'bl' }); break;
                case 63: dataFieldOutPutThree.push({ id: 'bm' }); break;
                case 64: dataFieldOutPutThree.push({ id: 'bn' }); break;
                case 65: dataFieldOutPutThree.push({ id: 'bo' }); break;
                case 66: dataFieldOutPutThree.push({ id: 'bp' }); break;
                case 67: dataFieldOutPutThree.push({ id: 'bq' }); break;
                case 68: dataFieldOutPutThree.push({ id: 'br' }); break;
                case 69: dataFieldOutPutThree.push({ id: 'bs' }); break;
                case 70: dataFieldOutPutThree.push({ id: 'bt' }); break;
                case 71: dataFieldOutPutThree.push({ id: 'bu' }); break;
                case 72: dataFieldOutPutThree.push({ id: 'bv' }); break;
                case 73: dataFieldOutPutThree.push({ id: 'bw' }); break;
                case 74: dataFieldOutPutThree.push({ id: 'bx' }); break;
                case 75: dataFieldOutPutThree.push({ id: 'by' }); break;
                case 76: dataFieldOutPutThree.push({ id: 'bz' }); break;
                case 77: dataFieldOutPutThree.push({ id: 'ca' }); break;
                case 78: dataFieldOutPutThree.push({ id: 'cb' }); break;
                case 79: dataFieldOutPutThree.push({ id: 'cc' }); break;
                case 80: dataFieldOutPutThree.push({ id: 'cd' }); break;
            }
        }


        if (Libs.isArrayData(dataList) && !Libs.isBlank(curItem.func_one) && !Libs.isBlank(curItem.func_two) && !Libs.isBlank(curItem.func_three)) {
            var strFncOne = curItem.func_one;
            var strFncTwo = curItem.func_two;
            var strFncThree = curItem.func_three;
            var arrFunctionOne = strFncOne.split(",");
            var arrFunctionTwo = strFncTwo.split(",");
            var arrFunctionThree = strFncThree.split(",");

            if (Libs.isArrayData(arrFunctionOne) && Libs.isArrayData(arrFunctionTwo) && Libs.isArrayData(arrFunctionThree)) {
                // Set cong thuoc 1
                var strOne = arrFunctionOne[0];
                var strTwo = arrFunctionTwo[0];
                var strThree = arrFunctionThree[0];

                for (var j = 0; j < dataList.length; j++) {
                    var strMoreOne = "";
                    var strMoreTwo = "";
                    var strMoreThree = "";

                    var strMoreOneOut1 = "";
                    var strMoreOneOut2 = "";

                    var strMoreTwoOut1 = "";
                    var strMoreTwoOut2 = "";

                    var strMoreThreeOut1 = "";
                    var strMoreThreeOut2 = "";

                    if (arrFunctionOne.length > 1) {
                        for (var v = 0; v < arrFunctionOne.length; v++) {
                            if (v > 0) {
                                if (arrFunctionOne[v] == 'A') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].a);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].a) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].a) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'B') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].b);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].b) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].b) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'C') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].c);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].c) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].d) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'D') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].d);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].d) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].d) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'E') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].e);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].e) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].e) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'F') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].f);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].f) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].f) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'G') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].g);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].g) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].g) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'H') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].h);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].h) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].h) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'I') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].i);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].i) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].i) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'J') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].j);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].j) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].j) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'K') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].k);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].k) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].k) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'L') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].l);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].l) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].l) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'M') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].m);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].m) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].m) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'M') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].n);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].n) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].n) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'O') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].o);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].o) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].o) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'P') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].p);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].p) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].p) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'Q') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].q);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].q) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].q) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'R') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].r);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].r) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].r) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'S') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].s);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].s) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].s) - 1.7);
                                }
                                else if (arrFunctionOne[v] == 'T') {
                                    strMoreOne = strMoreOne + Math.round(dataList[j].t);
                                    strMoreOneOut1 = strMoreOneOut1 + Math.round(Number(dataList[j].t) + 1.7);
                                    strMoreOneOut2 = strMoreOneOut2 + Math.round(Number(dataList[j].t) - 1.7);
                                }
                            }
                        }
                    }

                    if (arrFunctionTwo.length > 1) {
                        for (var v = 0; v < arrFunctionTwo.length; v++) {
                            if (v > 0) {
                                if (arrFunctionTwo[v] == 'A') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].a);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].a) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].a) - 1.7);

                                }
                                else if (arrFunctionTwo[v] == 'B') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].b);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].b) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].b) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'C') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].c);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].c) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].c) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'D') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].d);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].d) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].d) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'E') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].e);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].e) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].e) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'F') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].f);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].f) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].f) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'G') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].g);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].g) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].g) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'H') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].h);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].h) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].h) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'I') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].i);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].i) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].i) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'J') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].j);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].j) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].j) - 1.7);
                                }

                                else if (arrFunctionTwo[v] == 'K') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].k);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].k) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].k) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'L') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].l);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].l) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].l) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'M') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].m);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].m) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].m) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'N') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].n);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].n) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].n) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'O') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].o);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].o) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].o) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'P') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].p);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].p) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].p) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'Q') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].q);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].q) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].q) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'R') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].r);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].r) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].r) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'S') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].s);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].s) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].s) - 1.7);
                                }
                                else if (arrFunctionTwo[v] == 'T') {
                                    strMoreTwo = strMoreTwo + Math.round(dataList[j].t);
                                    strMoreTwoOut1 = strMoreTwoOut1 + Math.round(Number(dataList[j].t) + 1.7);
                                    strMoreTwoOut2 = strMoreTwoOut2 + Math.round(Number(dataList[j].t) - 1.7);
                                }
                            }
                        }
                    }


                    if (arrFunctionThree.length > 1) {
                        for (var v = 0; v < arrFunctionThree.length; v++) {
                            if (v > 0) {
                                if (arrFunctionThree[v] == 'A') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].a);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].a) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].a) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'B') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].b);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].b) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].b) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'C') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].c);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].c) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].c) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'D') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].d);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].d) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].d) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'E') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].e);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].e) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].e) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'F') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].f);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].f) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].f) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'G') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].g);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].g) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].g) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'H') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].h);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].h) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].h) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'I') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].i);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].i) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].i) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'J') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].j);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].j) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].j) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'K') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].k);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].k) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].k) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'L') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].l);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].l) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].l) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'M') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].m);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].m) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].m) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'N') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].n);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].n) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].n) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'O') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].o);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].o) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].o) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'P') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].p);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].p) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].p) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'Q') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].q);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].q) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].q) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'R') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].r);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].r) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].r) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'S') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].s);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].s) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].s) - 1.7);
                                }
                                else if (arrFunctionThree[v] == 'T') {
                                    strMoreThree = strMoreThree + Math.round(dataList[j].t);
                                    strMoreThreeOut1 = strMoreThreeOut1 + Math.round(Number(dataList[j].t) + 1.7);
                                    strMoreThreeOut2 = strMoreThreeOut2 + Math.round(Number(dataList[j].t) - 1.7);
                                }
                            }
                        }
                    }

                    if (j >= parseInt(curItem.row_start) && j < parseInt(curItem.row_end)) {
                        // Tinh tong cong thuc 1
                        var totalCalOne = null, totalCalOneOut1 = null, totalCalOneOut2 = null;
                        if (!Libs.isBlank(strOne)) {
                            var strAvgOne = strOne.split(":");
                            if (strAvgOne.length > 0) {
                                for (var i = 0; i < strAvgOne.length; i++) {
                                    if (strAvgOne[i] == 'A') {
                                        totalCalOne = totalCalOne + Number(dataList[j].a);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].a) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].a) - 1.7);

                                    } else if (strAvgOne[i] == 'B') {
                                        totalCalOne = totalCalOne + Number(dataList[j].b);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].b) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].b) - 1.7);
                                    } else if (strAvgOne[i] == 'C') {
                                        totalCalOne = totalCalOne + Number(dataList[j].c);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].c) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].c) - 1.7);
                                    } else if (strAvgOne[i] == 'D') {
                                        totalCalOne = totalCalOne + Number(dataList[j].d);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].d) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].d) - 1.7);
                                    } else if (strAvgOne[i] == 'E') {
                                        totalCalOne = totalCalOne + Number(dataList[j].e);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].e) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].e) - 1.7);
                                    } else if (strAvgOne[i] == 'F') {
                                        totalCalOne = totalCalOne + Number(dataList[j].f);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].f) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].f) - 1.7);
                                    } else if (strAvgOne[i] == 'G') {
                                        totalCalOne = totalCalOne + Number(dataList[j].g);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].g) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].g) - 1.7);
                                    } else if (strAvgOne[i] == 'H') {
                                        totalCalOne = totalCalOne + Number(dataList[j].h);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].h) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].h) - 1.7);
                                    } else if (strAvgOne[i] == 'I') {
                                        totalCalOne = totalCalOne + Number(dataList[j].i);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].i) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].i) - 1.7);
                                    } else if (strAvgOne[i] == 'J') {
                                        totalCalOne = totalCalOne + Number(dataList[j].j);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].j) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].j) - 1.7);
                                    }
                                    else if (strAvgOne[i] == 'K') {
                                        totalCalOne = totalCalOne + Number(dataList[j].k);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].k) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].k) - 1.7);
                                    }
                                    else if (strAvgOne[i] == 'L') {
                                        totalCalOne = totalCalOne + Number(dataList[j].l);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].l) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].l) - 1.7);
                                    }
                                    else if (strAvgOne[i] == 'M') {
                                        totalCalOne = totalCalOne + Number(dataList[j].m);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].m) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].m) - 1.7);
                                    }
                                    else if (strAvgOne[i] == 'N') {
                                        totalCalOne = totalCalOne + Number(dataList[j].n);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].n) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].n) - 1.7);
                                    }
                                    else if (strAvgOne[i] == 'O') {
                                        totalCalOne = totalCalOne + Number(dataList[j].o);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].o) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].o) - 1.7);
                                    }
                                    else if (strAvgOne[i] == 'P') {
                                        totalCalOne = totalCalOne + Number(dataList[j].p);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].p) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].p) - 1.7);
                                    }
                                    else if (strAvgOne[i] == 'Q') {
                                        totalCalOne = totalCalOne + Number(dataList[j].q);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].q) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].q) - 1.7);
                                    }
                                    else if (strAvgOne[i] == 'R') {
                                        totalCalOne = totalCalOne + Number(dataList[j].r);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].r) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].r) - 1.7);
                                    }
                                    else if (strAvgOne[i] == 'S') {
                                        totalCalOne = totalCalOne + Number(dataList[j].s);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].s) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].s) - 1.7);
                                    }
                                    else if (strAvgOne[i] == 'T') {
                                        totalCalOne = totalCalOne + Number(dataList[j].t);
                                        totalCalOneOut1 = totalCalOneOut1 + (Number(dataList[j].t) + 1.7);
                                        totalCalOneOut2 = totalCalOneOut2 + (Number(dataList[j].t) - 1.7);
                                    }
                                }
                            }
                        }


                        // Tinh tong cong thuc 2
                        var totalCalTwo = 0, totalCalTwoOut1 = 0, totalCalTwoOut2 = 0;
                        if (!Libs.isBlank(strTwo)) {
                            var strAvgTwo = strTwo.split(":");
                            if (strAvgTwo.length > 0) {
                                for (var i = 0; i < strAvgTwo.length; i++) {
                                    if (strAvgTwo[i] == 'A') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].a);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].a) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].a) - 1.7);
                                    } else if (strAvgTwo[i] == 'B') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].b);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].b) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].b) - 1.7);
                                    } else if (strAvgTwo[i] == 'C') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].c);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].c) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].c) - 1.7);
                                    } else if (strAvgTwo[i] == 'D') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].d);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].d) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].d) - 1.7);
                                    } else if (strAvgTwo[i] == 'E') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].e);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].e) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].e) - 1.7);
                                    } else if (strAvgTwo[i] == 'F') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].f);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].f) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].f) - 1.7);
                                    } else if (strAvgTwo[i] == 'G') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].g);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].g) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].g) - 1.7);
                                    } else if (strAvgTwo[i] == 'H') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].h);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].h) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].h) - 1.7);
                                    } else if (strAvgTwo[i] == 'I') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].i);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].i) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].i) - 1.7);
                                    } else if (strAvgTwo[i] == 'J') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].j);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].j) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].j) - 1.7);
                                    }
                                    else if (strAvgTwo[i] == 'K') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].k);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].k) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].k) - 1.7);
                                    }
                                    else if (strAvgTwo[i] == 'L') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].l);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].l) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].l) - 1.7);
                                    }
                                    else if (strAvgTwo[i] == 'M') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].m);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].m) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].m) - 1.7);
                                    }
                                    else if (strAvgTwo[i] == 'N') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].n);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].n) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].n) - 1.7);
                                    }
                                    else if (strAvgTwo[i] == 'O') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].o);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].o) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].o) - 1.7);
                                    }
                                    else if (strAvgTwo[i] == 'P') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].p);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].p) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].p) - 1.7);
                                    }
                                    else if (strAvgTwo[i] == 'Q') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].q);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].q) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].q) - 1.7);
                                    }
                                    else if (strAvgTwo[i] == 'R') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].r);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].r) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].r) - 1.7);
                                    }
                                    else if (strAvgTwo[i] == 'S') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].s);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].s) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].s) - 1.7);
                                    }
                                    else if (strAvgTwo[i] == 'T') {
                                        totalCalTwo = totalCalTwo + Number(dataList[j].t);
                                        totalCalTwoOut1 = totalCalTwoOut1 + (Number(dataList[j].t) + 1.7);
                                        totalCalTwoOut2 = totalCalTwoOut2 + (Number(dataList[j].t) - 1.7);
                                    }
                                }
                            }
                        }

                        // Tinh tong cong thuc 3
                        var totalCalThree = null;
                        var medianThree = [], medianThreeOut1 = [], medianThreeOut2 = [];
                        if (!Libs.isBlank(strThree)) {
                            var strAvgThree = strTwo.split(":");
                            if (strAvgThree.length > 0) {
                                for (var i = 0; i < strAvgThree.length; i++) {
                                    if (strAvgThree[i] == 'A' && Number(dataList[j].a) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].a);
                                        medianThree.push(Number(dataList[j].a));
                                        medianThreeOut1.push((Number(dataList[j].a) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].a) - 1.7));

                                    } else if (strAvgThree[i] == 'B' && Number(dataList[j].b) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].b);
                                        medianThree.push(Number(dataList[j].b));
                                        medianThreeOut1.push((Number(dataList[j].b) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].b) - 1.7));
                                    } else if (strAvgThree[i] == 'C' && Number(dataList[j].c) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].c);
                                        medianThree.push(Number(dataList[j].c));
                                        medianThreeOut1.push((Number(dataList[j].c) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].c) - 1.7));
                                    } else if (strAvgThree[i] == 'D' && Number(dataList[j].d) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].d);
                                        medianThree.push(Number(dataList[j].d));
                                        medianThreeOut1.push((Number(dataList[j].d) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].d) - 1.7));
                                    } else if (strAvgThree[i] == 'E' && Number(dataList[j].e) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].e);
                                        medianThree.push(Number(dataList[j].e));
                                        medianThreeOut1.push((Number(dataList[j].e) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].e) - 1.7));
                                    } else if (strAvgThree[i] == 'F' && Number(dataList[j].f) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].f);
                                        medianThree.push(Number(dataList[j].f));
                                        medianThreeOut1.push((Number(dataList[j].f) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].f) - 1.7));
                                    } else if (strAvgThree[i] == 'G' && Number(dataList[j].g) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].g);
                                        medianThree.push(Number(dataList[j].g));
                                        medianThreeOut1.push((Number(dataList[j].g) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].g) - 1.7));
                                    } else if (strAvgThree[i] == 'H' && Number(dataList[j].h) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].h);
                                        medianThree.push(Number(dataList[j].h));
                                        medianThreeOut1.push((Number(dataList[j].h) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].h) - 1.7));
                                    } else if (strAvgThree[i] == 'I' && Number(dataList[j].i) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].i);
                                        medianThree.push(Number(dataList[j].i));
                                        medianThreeOut1.push((Number(dataList[j].i) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].i) - 1.7));
                                    } else if (strAvgThree[i] == 'J' && Number(dataList[j].j) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].j);
                                        medianThree.push(Number(dataList[j].j));
                                        medianThreeOut1.push((Number(dataList[j].j) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].j) - 1.7));
                                    }
                                    else if (strAvgThree[i] == 'K' && Number(dataList[j].j) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].k);
                                        medianThree.push(Number(dataList[j].k));
                                        medianThreeOut1.push((Number(dataList[j].k) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].k) - 1.7));
                                    }
                                    else if (strAvgThree[i] == 'L' && Number(dataList[j].l) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].l);
                                        medianThree.push(Number(dataList[j].l));
                                        medianThreeOut1.push((Number(dataList[j].l) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].l) - 1.7));
                                    }
                                    else if (strAvgThree[i] == 'M' && Number(dataList[j].m) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].m);
                                        medianThree.push(Number(dataList[j].m));
                                        medianThreeOut1.push((Number(dataList[j].m) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].m) - 1.7));
                                    }
                                    else if (strAvgThree[i] == 'N' && Number(dataList[j].n) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].n);
                                        medianThree.push(Number(dataList[j].n));
                                        medianThreeOut1.push((Number(dataList[j].n) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].n) - 1.7));
                                    }
                                    else if (strAvgThree[i] == 'O' && Number(dataList[j].o) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].o);
                                        medianThree.push(Number(dataList[j].o));
                                        medianThreeOut1.push((Number(dataList[j].o) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].o) - 1.7));
                                    }
                                    else if (strAvgThree[i] == 'P' && Number(dataList[j].p) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].p);
                                        medianThree.push(Number(dataList[j].p));
                                        medianThreeOut1.push((Number(dataList[j].p) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].p) - 1.7));
                                    }
                                    else if (strAvgThree[i] == 'Q' && Number(dataList[j].q) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].q);
                                        medianThree.push(Number(dataList[j].q));
                                        medianThreeOut1.push((Number(dataList[j].q) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].q) - 1.7));
                                    }
                                    else if (strAvgThree[i] == 'R' && Number(dataList[j].s) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].s);
                                        medianThree.push(Number(dataList[j].s));
                                        medianThreeOut1.push((Number(dataList[j].s) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].s) - 1.7));
                                    }
                                    else if (strAvgThree[i] == 'S' && Number(dataList[j].s) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].s);
                                        medianThree.push(Number(dataList[j].s));
                                        medianThreeOut1.push((Number(dataList[j].s) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].s) - 1.7));
                                    }
                                    else if (strAvgThree[i] == 'T' && Number(dataList[j].t) > 0) {
                                        totalCalThree = totalCalThree + Number(dataList[j].t);
                                        medianThree.push(Number(dataList[j].t));
                                        medianThreeOut1.push((Number(dataList[j].t) + 1.7));
                                        medianThreeOut2.push((Number(dataList[j].t) - 1.7));
                                    }
                                }
                            }
                        }

                        // =CHOOSE(RANDBETWEEN(1;3);INT(AVERAGE($A1:$E1));ROUND(AVERAGE($A1:$E1);0);ROUND(MEDIAN($A1:$E1);0)) 
                        if (totalCalOne > 0) {
                            dataFieldOutPut.map((v, index) => {
                                dataList[j][v.id] = Libs.randomNumber(Math.floor(totalCalOne / strAvgOne.length) + "" + strMoreOne + "" + Math.round(totalCalTwo / strAvgTwo.length) + "" + strMoreTwo + "" + Math.round(Libs.medianOfArray(medianThree)) + "" + strMoreThree, 1);
                                if(Number(dataList[j][v.id] ) < 1) { dataList[j][v.id]  = 1;}
					            // if(Number(dataList[j][v.id] ) > 5) { dataList[j][v.id]  = 5;}
                            });
                        }


                        // Tinh toan output 2
                        dataFieldOutPutOne.map((v, index) => {
                            // =IF(D2=2,CHOOSE(RANDBETWEEN(1,3),2,2,3),
                            // IF(D2=3,CHOOSE(RANDBETWEEN(1,3),2,3,3),
                            // IF(D2=4,CHOOSE(RANDBETWEEN(1,3),5,4,4),
                            // IF(D2=5,CHOOSE(RANDBETWEEN(1,3),4,5,5),
                            // IF(D2=6,CHOOSE(RANDBETWEEN(1,3),6,6,7),D2)))))
                            if (dataList[j][dataFieldOutPut[index].id] == 2) {
                                dataList[j][v.id] = Libs.randomNumber('223', 1);
                            } else if (dataList[j][dataFieldOutPut[index].id] == 3) {
                                dataList[j][v.id] = Libs.randomNumber('233', 1);
                            } else if (dataList[j][dataFieldOutPut[index].id] == 4) {
                                dataList[j][v.id] = Libs.randomNumber('544', 1);
                            } else if (dataList[j][dataFieldOutPut[index].id] == 5) {
                                dataList[j][v.id] = Libs.randomNumber('455', 1);
                            } else if (dataList[j][dataFieldOutPut[index].id] == 6) {
                                dataList[j][v.id] = Libs.randomNumber('667', 1);
                            } else{
                                dataList[j][v.id] = dataList[j][dataFieldOutPut[index].id];
                            }
                        });



                        var totalOutPut2 = 0, totalOutPut3 = 0, medianOut2 = 0, medianOut3 = 0, arrMedianOutPut2 = [], arrMedianOutPut3 = [], medianOut2 = 0, medianOut3 = 0;
                        fieldInput.map((v, index) => {
                            totalOutPut2 = totalOutPut2 + (Number(dataList[j][v.id]) + 1.7);
                            totalOutPut3 = totalOutPut3 + (Number(dataList[j][v.id]) - 1.7);

                            arrMedianOutPut2.push(Number(dataList[j][v.id]) + 1.7);
                            arrMedianOutPut3.push(Number(dataList[j][v.id]) - 1.7);
                        })

                        medianOut2 = Math.round(Libs.medianOfArray(arrMedianOutPut2));
                        medianOut3 = Math.round(Libs.medianOfArray(arrMedianOutPut3));

                        if (totalCalOne > 0 && curItem.enable_output == 1) {
                            //output 2
                            dataFieldOutPutTwo.map((v, index) => {
                                dataList[j][v.id] = Libs.randomNumber(Math.floor(totalOutPut2 / parseInt(curItem.input)) + "" + Math.round(totalOutPut2 / curItem.input) + "" + medianOut2, 1);
                                if(Number(dataList[j][v.id] ) < 1) { dataList[j][v.id]  = 1;}
					            // if(Number(dataList[j][v.id] ) > 5) { dataList[j][v.id]  = 5;}
                            });

                            //output 3
                            dataFieldOutPutThree.map((v, index) => {
                                dataList[j][v.id] =  Libs.randomNumber(Math.floor(totalOutPut3 / parseInt(curItem.input)) + "" + Math.round(totalOutPut3 / curItem.input) + "" + medianOut3, 1);
                                if(Number(dataList[j][v.id] ) < 1) { dataList[j][v.id]  = 1;}
					            // if(Number(dataList[j][v.id] ) > 5) { dataList[j][v.id]  = 5;}
                            });
                        }



                    } else {
                        
                        var total = 0, median = 0, arrMedian = [], totalOutPut2 = 0, totalOutPut3 = 0, medianOut2 = 0, medianOut3 = 0, arrMedianOutPut2 = [], arrMedianOutPut3 = [], medianOut2 = 0, medianOut3 = 0;
                        fieldInput.map((v, index) => {

                            total = total + Number(dataList[j][v.id]);
                            arrMedian.push(Number(dataList[j][v.id]));

                            totalOutPut2 = totalOutPut2 + (Number(dataList[j][v.id]) + 1.7);
                            totalOutPut3 = totalOutPut3 + (Number(dataList[j][v.id]) - 1.7);

                            arrMedianOutPut2.push(Number(dataList[j][v.id]) + 1.7);
                            arrMedianOutPut3.push(Number(dataList[j][v.id]) - 1.7);
                        })

                        median = Math.round(Libs.medianOfArray(arrMedian));
                        if (total > 0) {
                            dataFieldOutPut.map((v, index) => {
                                dataList[j][v.id] = Libs.randomNumber(Math.floor(total / parseInt(curItem.input)) + "" + Math.round(total / curItem.input) + "" + median, 1);
                                if(Number(dataList[j][v.id] ) < 1) { dataList[j][v.id]  = 1;}
					            if(Number(dataList[j][v.id] ) > 5) { dataList[j][v.id]  = 5;}
                            });
                        }

                        medianOut2 = Math.round(Libs.medianOfArray(arrMedianOutPut2));
                        medianOut3 = Math.round(Libs.medianOfArray(arrMedianOutPut3));

                         // Tinh toan output 2
                         dataFieldOutPutOne.map((v, index) => {
                            // =IF(D2=2,CHOOSE(RANDBETWEEN(1,3),2,2,3),
                            // IF(D2=3,CHOOSE(RANDBETWEEN(1,3),2,3,3),
                            // IF(D2=4,CHOOSE(RANDBETWEEN(1,3),5,4,4),
                            // IF(D2=5,CHOOSE(RANDBETWEEN(1,3),4,5,5),
                            // IF(D2=6,CHOOSE(RANDBETWEEN(1,3),6,6,7),D2)))))
                            if (dataList[j][dataFieldOutPut[index].id] == 2) {
                                dataList[j][v.id] = Libs.randomNumber('223', 1);
                            } else if (dataList[j][dataFieldOutPut[index].id] == 3) {
                                dataList[j][v.id] = Libs.randomNumber('233', 1);
                            } else if (dataList[j][dataFieldOutPut[index].id] == 4) {
                                dataList[j][v.id] = Libs.randomNumber('544', 1);
                            } else if (dataList[j][dataFieldOutPut[index].id] == 5) {
                                dataList[j][v.id] = Libs.randomNumber('455', 1);
                            } else if (dataList[j][dataFieldOutPut[index].id] == 6) {
                                dataList[j][v.id] = Libs.randomNumber('667', 1);
                            } else{
                                dataList[j][v.id] = dataList[j][dataFieldOutPut[index].id];
                            }
                        });

                        if (total > 0 && curItem.enable_output == 1 ) {
                            //output 2
                            dataFieldOutPutTwo.map((v, index) => {
                                dataList[j][v.id] = Libs.randomNumber(Math.floor(totalOutPut2 / parseInt(curItem.input)) + "" + Math.round(totalOutPut2 / curItem.input) + "" + medianOut2, 1);
                                if(Number(dataList[j][v.id] ) < 1) { dataList[j][v.id]  = 1;}
					            if(Number(dataList[j][v.id] ) > 5) { dataList[j][v.id]  = 5;}
                            });

                            //output 3
                            dataFieldOutPutThree.map((v, index) => {
                                dataList[j][v.id] = Libs.randomNumber(Math.floor(totalOutPut3 / parseInt(curItem.input)) + "" + Math.round(totalOutPut3 / curItem.input) + "" + medianOut3, 1);
                                if(Number(dataList[j][v.id] ) < 1) { dataList[j][v.id]  = 1;}
					            if(Number(dataList[j][v.id] ) > 5) { dataList[j][v.id]  = 5;}
                            });
                        }
                    }
                }


            }
            this.setState({
                dataList: dataList,
                curItemReset: curItemReset
            }, () => {
                self.calAvg();
            })

        }
    }


    onClickChangePercent = (value) => {
        var { curItem } = this.state;
        if (value == curItem.row_percent) {
            curItem.row_percent = 0;
            curItem.row_start = 0;
            curItem.row_end = curItem.totalRow;
        } else {
            curItem.row_percent = value;
            curItem.row_start = 0;
            curItem.row_end = Math.round(parseInt(curItem.totalRow) * (value / 100));
        }

        this.setState({
            curItem: curItem
        })
    }
    /**
     * setValue method to Input
     * @author Long.Pham 20/05/2021
     */
    handleInputChange(event) {
        let target = event.target ,self = this;
        let name = target.name;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }

        if (name) {
            let { curItem, dataList } = this.state;
            if (name == 'func_one' || name == 'func_two' || name == 'func_three') {
                value = value.toUpperCase();
            }
            if (name == 'row_apply') {
                var splitRowApply = value.split(":");
                if (splitRowApply.length == 2) {
                    curItem.row_start = parseInt(splitRowApply[0]);
                    curItem.row_end = parseInt(splitRowApply[1]);
                }
            }

            curItem[name] = (event.target.validity.valid) ? value : curItem[name];

            if (name == 'input') {
                curItem.row_end = curItem.totalRow;
                var input = "";
                if (curItem.input > 0) {
                    for (var i = 0; i < curItem.input; i++) {
                        if (i == 0) { input = input + "A:"; }
                        if (i == 1) { input = input + "B:"; }
                        if (i == 2) { input = input + "C:"; }
                        if (i == 3) { input = input + "D:"; }
                        if (i == 4) { input = input + "E:"; }
                        if (i == 5) { input = input + "F:"; }
                        if (i == 6) { input = input + "G:"; }
                        if (i == 7) { input = input + "H:"; }
                        if (i == 8) { input = input + "I:"; }
                        if (i == 9) { input = input + "J:"; }

                        if (i == 10) { input = input + "K:"; }
                        if (i == 11) { input = input + "L:"; }
                        if (i == 12) { input = input + "M:"; }
                        if (i == 13) { input = input + "N:"; }
                        if (i == 14) { input = input + "O:"; }
                        if (i == 15) { input = input + "P:"; }
                        if (i == 16) { input = input + "Q:"; }
                        if (i == 17) { input = input + "R:"; }
                        if (i == 18) { input = input + "S:"; }
                        if (i == 19) { input = input + "T:"; }
                    }
                    curItem.func_one = input.slice(0, -1);
                    curItem.func_two = input.slice(0, -1);
                    curItem.func_three = input.slice(0, -1);
                }
            }

            if (name == 'totalRow') {
                this.loadDefaultData();
            }

            var dataOutPut = [];
            if(name == 'enable_output' && value == 0 && Libs.isArrayData(dataList)){
                var countStartTwo = parseInt(curItem.input) + (parseInt(curItem.output) * 4) - 1;
                for (var x = ((parseInt(curItem.input) + (parseInt(curItem.output) * 2)) - 1); x < countStartTwo; x++) {
                    switch (x) {
                        case 0: dataOutPut.push({ id: 'b' }); break;
                        case 1: dataOutPut.push({ id: 'c' }); break;
                        case 2: dataOutPut.push({ id: 'd' }); break;
                        case 3: dataOutPut.push({ id: 'e' }); break;
                        case 4: dataOutPut.push({ id: 'f' }); break;
                        case 5: dataOutPut.push({ id: 'g' }); break;
                        case 6: dataOutPut.push({ id: 'h' }); break;
                        case 7: dataOutPut.push({ id: 'i' }); break;
                        case 8: dataOutPut.push({ id: 'j' }); break; 
                        case 9: dataOutPut.push({ id: 'k' }); break;
                        case 10: dataOutPut.push({ id: 'l' }); break;
                        case 11: dataOutPut.push({ id: 'm' }); break;
                        case 12: dataOutPut.push({ id: 'n' }); break;
                        case 13: dataOutPut.push({ id: 'o' }); break;
                        case 14: dataOutPut.push({ id: 'p' }); break;
                        case 15: dataOutPut.push({ id: 'q' }); break;
                        case 16: dataOutPut.push({ id: 'r' }); break;
                        case 17: dataOutPut.push({ id: 's' }); break;
                        case 18: dataOutPut.push({ id: 't' }); break;
                        case 19: dataOutPut.push({ id: 'u' }); break;
                        case 20: dataOutPut.push({ id: 'v' }); break;
                        case 21: dataOutPut.push({ id: 'w' }); break;
                        case 22: dataOutPut.push({ id: 'x' }); break;
                        case 23: dataOutPut.push({ id: 'y' }); break;
                        case 24: dataOutPut.push({ id: 'z' }); break;
                        case 25: dataOutPut.push({ id: 'aa' }); break;
                        case 26: dataOutPut.push({ id: 'ab' }); break;
                        case 27: dataOutPut.push({ id: 'ac' }); break;
                        case 28: dataOutPut.push({ id: 'ad' }); break;
                        case 29: dataOutPut.push({ id: 'ae' }); break;
                        case 30: dataOutPut.push({ id: 'af' }); break;
                        case 31: dataOutPut.push({ id: 'ag' }); break;
                        case 32: dataOutPut.push({ id: 'ah' }); break;
                        case 33: dataOutPut.push({ id: 'ai' }); break;
                        case 34: dataOutPut.push({ id: 'aj' }); break;
                        case 35: dataOutPut.push({ id: 'ak' }); break;
                        case 36: dataOutPut.push({ id: 'al' }); break;
                        case 37: dataOutPut.push({ id: 'am' }); break;
                        case 38: dataOutPut.push({ id: 'an' }); break;
                        case 39: dataOutPut.push({ id: 'ao' }); break;
                        case 40: dataOutPut.push({ id: 'ap' }); break;
                        case 41: dataOutPut.push({ id: 'aq' }); break;
                        case 42: dataOutPut.push({ id: 'ar' }); break;
                        case 43: dataOutPut.push({ id: 'as' }); break;
                        case 44: dataOutPut.push({ id: 'at' }); break;
                        case 45: dataOutPut.push({ id: 'au' }); break;
                        case 46: dataOutPut.push({ id: 'av' }); break;
                        case 47: dataOutPut.push({ id: 'aw' }); break;
                        case 48: dataOutPut.push({ id: 'ax' }); break;
                        case 49: dataOutPut.push({ id: 'ay' }); break;
                        case 50: dataOutPut.push({ id: 'az' }); break;
                        case 51: dataOutPut.push({ id: 'ba' }); break;
                        case 52: dataOutPut.push({ id: 'bb' }); break;
                        case 53: dataOutPut.push({ id: 'bc' }); break;
                        case 54: dataOutPut.push({ id: 'bd' }); break;
                        case 55: dataOutPut.push({ id: 'be' }); break;
                        case 56: dataOutPut.push({ id: 'bf' }); break;
                        case 57: dataOutPut.push({ id: 'bg' }); break;
                        case 58: dataOutPut.push({ id: 'bh' }); break;
                        case 59: dataOutPut.push({ id: 'bi' }); break;
                        case 60: dataOutPut.push({ id: 'bj' }); break;
                        case 61: dataOutPut.push({ id: 'bk' }); break;
                        case 62: dataOutPut.push({ id: 'bl' }); break;
                        case 63: dataOutPut.push({ id: 'bm' }); break;
                        case 64: dataOutPut.push({ id: 'bn' }); break;
                        case 65: dataOutPut.push({ id: 'bo' }); break;
                        case 66: dataOutPut.push({ id: 'bp' }); break;
                        case 67: dataOutPut.push({ id: 'bq' }); break;
                        case 68: dataOutPut.push({ id: 'br' }); break;
                        case 69: dataOutPut.push({ id: 'bs' }); break;
                        case 70: dataOutPut.push({ id: 'bt' }); break;
                        case 71: dataOutPut.push({ id: 'bu' }); break;
                        case 72: dataOutPut.push({ id: 'bv' }); break;
                        case 73: dataOutPut.push({ id: 'bw' }); break;
                        case 74: dataOutPut.push({ id: 'bx' }); break;
                        case 75: dataOutPut.push({ id: 'by' }); break;
                        case 76: dataOutPut.push({ id: 'bz' }); break;
                        case 77: dataOutPut.push({ id: 'ca' }); break;
                        case 78: dataOutPut.push({ id: 'cb' }); break;
                        case 79: dataOutPut.push({ id: 'cc' }); break;
                        case 80: dataOutPut.push({ id: 'cd' }); break;
                    }
                }

                dataList.map((item, index) => {
                    dataOutPut.map((v, idx) => {
                        dataList[index][v.id] = null;
                    });
                })
            }

            if(name == 'enable_output' && value == 1 && Libs.isArrayData(dataList)){
                self.applyFunction(1);
            }

            this.setState({ curItem: curItem, dataList: dataList });
        }
    }


    onKeyDownChange(event) {
        var { curItem } = this.state;
        let target = event.target;
        let name = target.name;
        if (event.key == "Enter") {
            this.loadDefaultData();
        }
    }
    changeHot(data) {
        if (Libs.isArrayData(data)) {
            this.reRenderData();
        }
    }
    loadDefaultData() {
        var dataList = [], self = this;

        var { curItem, dataCounts, dataInput , curItemReset } = this.state;
        var data = LoadDataDefault7.LoadData(curItem, dataCounts, dataInput);
        dataList = data[0];
        dataCounts = data[1];
        dataInput = data[2];
        curItemReset.dataList = dataList;

        this.setState({
            dataList: dataList,
            dataCounts: dataCounts,
            dataInput: dataInput,
            curItemReset: curItemReset
        }, () => {
            self.calAvg();
        })

    }


    calAvg() {
        var { dataList, curItem, dataCounts } = this.state, self = this;
        var counta = null,
            countb = null,
            countc = null,
            countd = null,
            counte = null,
            countf = null,
            countg = null,
            counth = null,
            counti = null,
            countj = null,
            countk = null,
            countl = null,
            countm = null,
            countn = null,
            counto = null,
            countp = null,
            countq = null,
            countr = null,
            counts = null,
            countt = null,

            countu = null,
            countv = null,
            countw = null,
            countx = null,
            county = null,
            countz = null,

            countaa = null,
            countab = null,
            countac = null,
            countad = null,
            countae = null,
            countaf = null,
            countag = null,
            countah = null,
            countai = null,
            countaj = null,
            countak = null,
            countal = null,
            countam = null,
            countan = null,
            countao = null,
            countap = null,

            countaq = null,
            countar = null,
            countas = null,
            countat = null,
            countau = null,
            countav = null,
            countaw = null,
            countax = null,
            countay = null,
            countaz = null,

            countba = null,
            countbb = null,
            countbc = null,
            countbd = null,
            countbe = null,
            countbf = null,
            countbg = null,
            countbh = null,
            countbi = null,
            countbj = null,
            countbk = null,
            countbl = null,
            countbm = null,
            countbn = null,
            countbo = null,
            countbp = null,
            countbq = null,
            countbr = null,
            countbs = null,
            countbt = null,
            countbu = null,
            countbv = null,
            countbw = null,
            countbx = null,
            countby = null,
            countbz = null,

            countca = null,
            countcb = null,
            countcc = null;




        if (Libs.isArrayData(dataList)) {
            for (var i = 0; i < curItem.totalRow; i++) {
                if (!Libs.isBlank(dataList[i].a)) { counta = counta + Number(dataList[i].a); }
                if (!Libs.isBlank(dataList[i].b)) { countb = countb + Number(dataList[i].b); }
                if (!Libs.isBlank(dataList[i].c)) { countc = countc + Number(dataList[i].c); }
                if (!Libs.isBlank(dataList[i].d)) { countd = countd + Number(dataList[i].d); }
                if (!Libs.isBlank(dataList[i].e)) { counte = counte + Number(dataList[i].e); }
                if (!Libs.isBlank(dataList[i].f)) { countf = countf + Number(dataList[i].f); }
                if (!Libs.isBlank(dataList[i].g)) { countg = countg + Number(dataList[i].g); }
                if (!Libs.isBlank(dataList[i].h)) { counth = counth + Number(dataList[i].h); }
                if (!Libs.isBlank(dataList[i].i)) { counti = counti + Number(dataList[i].i); }
                if (!Libs.isBlank(dataList[i].j)) { countj = countj + Number(dataList[i].j); }
                if (!Libs.isBlank(dataList[i].k)) { countk = countk + Number(dataList[i].k); }
                if (!Libs.isBlank(dataList[i].l)) { countl = countl + Number(dataList[i].l); }
                if (!Libs.isBlank(dataList[i].m)) { countm = countm + Number(dataList[i].m); }
                if (!Libs.isBlank(dataList[i].n)) { countn = countn + Number(dataList[i].n); }
                if (!Libs.isBlank(dataList[i].o)) { counto = counto + Number(dataList[i].o); }
                if (!Libs.isBlank(dataList[i].p)) { countp = countp + Number(dataList[i].p); }
                if (!Libs.isBlank(dataList[i].q)) { countq = countq + Number(dataList[i].q); }
                if (!Libs.isBlank(dataList[i].r)) { countr = countr + Number(dataList[i].r); }
                if (!Libs.isBlank(dataList[i].s)) { counts = counts + Number(dataList[i].s); }
                if (!Libs.isBlank(dataList[i].t)) { countt = countt + Number(dataList[i].t); }

                if (!Libs.isBlank(dataList[i].u)) { countu = countu + Number(dataList[i].u); }
                if (!Libs.isBlank(dataList[i].v)) { countv = countv + Number(dataList[i].v); }
                if (!Libs.isBlank(dataList[i].w)) { countw = countw + Number(dataList[i].w); }
                if (!Libs.isBlank(dataList[i].x)) { countx = countx + Number(dataList[i].x); }
                if (!Libs.isBlank(dataList[i].y)) { county = county + Number(dataList[i].y); }
                if (!Libs.isBlank(dataList[i].z)) { countz = countz + Number(dataList[i].z); }
                if (!Libs.isBlank(dataList[i].aa)) { countaa = countaa + Number(dataList[i].aa); }
                if (!Libs.isBlank(dataList[i].ab)) { countab = countab + Number(dataList[i].ab); }
                if (!Libs.isBlank(dataList[i].ac)) { countac = countac + Number(dataList[i].ac); }
                if (!Libs.isBlank(dataList[i].ad)) { countad = countad + Number(dataList[i].ad); }
                if (!Libs.isBlank(dataList[i].ae)) { countae = countae + Number(dataList[i].ae); }
                if (!Libs.isBlank(dataList[i].af)) { countaf = countaf + Number(dataList[i].af); }
                if (!Libs.isBlank(dataList[i].ag)) { countag = countag + Number(dataList[i].ag); }
                if (!Libs.isBlank(dataList[i].ah)) { countah = countah + Number(dataList[i].ah); }
                if (!Libs.isBlank(dataList[i].ai)) { countai = countai + Number(dataList[i].ai); }
                if (!Libs.isBlank(dataList[i].aj)) { countaj = countaj + Number(dataList[i].aj); }
                if (!Libs.isBlank(dataList[i].ak)) { countak = countak + Number(dataList[i].ak); }
                if (!Libs.isBlank(dataList[i].al)) { countal = countal + Number(dataList[i].al); }
                if (!Libs.isBlank(dataList[i].am)) { countam = countam + Number(dataList[i].am); }
                if (!Libs.isBlank(dataList[i].an)) { countan = countan + Number(dataList[i].an); }
                if (!Libs.isBlank(dataList[i].ao)) { countao = countao + Number(dataList[i].ao); }
                if (!Libs.isBlank(dataList[i].ap)) { countap = countap + Number(dataList[i].ap); }
                if (!Libs.isBlank(dataList[i].aq)) { countap = countap + Number(dataList[i].aq); }
                if (!Libs.isBlank(dataList[i].ar)) { countap = countap + Number(dataList[i].ar); }
                if (!Libs.isBlank(dataList[i].as)) { countap = countap + Number(dataList[i].as); }
                if (!Libs.isBlank(dataList[i].at)) { countap = countap + Number(dataList[i].at); }
                if (!Libs.isBlank(dataList[i].au)) { countap = countap + Number(dataList[i].au); }
                if (!Libs.isBlank(dataList[i].av)) { countap = countap + Number(dataList[i].av); }
                if (!Libs.isBlank(dataList[i].aw)) { countap = countap + Number(dataList[i].aw); }
                if (!Libs.isBlank(dataList[i].ax)) { countap = countap + Number(dataList[i].ax); }
                if (!Libs.isBlank(dataList[i].ay)) { countap = countap + Number(dataList[i].ay); }
                if (!Libs.isBlank(dataList[i].az)) { countap = countap + Number(dataList[i].az); }


                if (!Libs.isBlank(dataList[i].ba)) { countba = countba + Number(dataList[i].ba); }
                if (!Libs.isBlank(dataList[i].bb)) { countbb = countbb + Number(dataList[i].bb); }
                if (!Libs.isBlank(dataList[i].bc)) { countbc = countbc + Number(dataList[i].bc); }
                if (!Libs.isBlank(dataList[i].bd)) { countbd = countbd + Number(dataList[i].bd); }
                if (!Libs.isBlank(dataList[i].be)) { countbe = countbe + Number(dataList[i].be); }
                if (!Libs.isBlank(dataList[i].bf)) { countbf = countbf + Number(dataList[i].bf); }
                if (!Libs.isBlank(dataList[i].bg)) { countbg = countbg + Number(dataList[i].bg); }
                if (!Libs.isBlank(dataList[i].bh)) { countbh = countbh + Number(dataList[i].bh); }
                if (!Libs.isBlank(dataList[i].bi)) { countbi = countbi + Number(dataList[i].bi); }
                if (!Libs.isBlank(dataList[i].bj)) { countbj = countbj + Number(dataList[i].bj); }
                if (!Libs.isBlank(dataList[i].bk)) { countbk = countbk + Number(dataList[i].bk); }
                if (!Libs.isBlank(dataList[i].bl)) { countbl = countbl + Number(dataList[i].bl); }
                if (!Libs.isBlank(dataList[i].bm)) { countbm = countbm + Number(dataList[i].bm); }
                if (!Libs.isBlank(dataList[i].bn)) { countbn = countbn + Number(dataList[i].bn); }
                if (!Libs.isBlank(dataList[i].bo)) { countbo = countbo + Number(dataList[i].bo); }
                if (!Libs.isBlank(dataList[i].bp)) { countbp = countbp + Number(dataList[i].bp); }
                if (!Libs.isBlank(dataList[i].bq)) { countbq = countbq + Number(dataList[i].bq); }
                if (!Libs.isBlank(dataList[i].br)) { countbr = countbr + Number(dataList[i].br); }
                if (!Libs.isBlank(dataList[i].bs)) { countbs = countbs + Number(dataList[i].bs); }
                if (!Libs.isBlank(dataList[i].bt)) { countbt = countbt + Number(dataList[i].bt); }
                if (!Libs.isBlank(dataList[i].bu)) { countbu = countbu + Number(dataList[i].bu); }
                if (!Libs.isBlank(dataList[i].bv)) { countbv = countbv + Number(dataList[i].bv); }
                if (!Libs.isBlank(dataList[i].bw)) { countbw = countbw + Number(dataList[i].bw); }
                if (!Libs.isBlank(dataList[i].bx)) { countbx = countbx + Number(dataList[i].bx); }
                if (!Libs.isBlank(dataList[i].by)) { countby = countby + Number(dataList[i].by); }
                if (!Libs.isBlank(dataList[i].bz)) { countbz = countbz + Number(dataList[i].bz); }

                if (!Libs.isBlank(dataList[i].ca)) { countca = countca + Number(dataList[i].ca); }
                if (!Libs.isBlank(dataList[i].cb)) { countcb = countcb + Number(dataList[i].cb); }
                if (!Libs.isBlank(dataList[i].cc)) { countcc = countcc + Number(dataList[i].cc); }
            }

            dataCounts.a = Libs.round((counta / curItem.totalRow), 2).toFixed(2);
            dataCounts.b = Libs.round((countb / curItem.totalRow), 2).toFixed(2);
            dataCounts.c = Libs.round((countc / curItem.totalRow), 2).toFixed(2);
            dataCounts.d = Libs.round((countd / curItem.totalRow), 2).toFixed(2);
            dataCounts.e = Libs.round((counte / curItem.totalRow), 2).toFixed(2);
            dataCounts.f = Libs.round((countf / curItem.totalRow), 2).toFixed(2);
            dataCounts.g = Libs.round((countg / curItem.totalRow), 2).toFixed(2);
            dataCounts.h = Libs.round((counth / curItem.totalRow), 2).toFixed(2);
            dataCounts.i = Libs.round((counti / curItem.totalRow), 2).toFixed(2);
            dataCounts.j = Libs.round((countj / curItem.totalRow), 2).toFixed(2);
            dataCounts.k = Libs.round((countk / curItem.totalRow), 2).toFixed(2);
            dataCounts.l = Libs.round((countl / curItem.totalRow), 2).toFixed(2);
            dataCounts.m = Libs.round((countm / curItem.totalRow), 2).toFixed(2);
            dataCounts.n = Libs.round((countn / curItem.totalRow), 2).toFixed(2);
            dataCounts.o = Libs.round((counto / curItem.totalRow), 2).toFixed(2);
            dataCounts.p = Libs.round((countp / curItem.totalRow), 2).toFixed(2);
            dataCounts.q = Libs.round((countq / curItem.totalRow), 2).toFixed(2);
            dataCounts.r = Libs.round((countr / curItem.totalRow), 2).toFixed(2);
            dataCounts.s = Libs.round((counts / curItem.totalRow), 2).toFixed(2);
            dataCounts.t = Libs.round((countt / curItem.totalRow), 2).toFixed(2);
            dataCounts.u = Libs.round((countu / curItem.totalRow), 2).toFixed(2);
            dataCounts.v = Libs.round((countv / curItem.totalRow), 2).toFixed(2);
            dataCounts.w = Libs.round((countw / curItem.totalRow), 2).toFixed(2);
            dataCounts.x = Libs.round((countx / curItem.totalRow), 2).toFixed(2);
            dataCounts.y = Libs.round((county / curItem.totalRow), 2).toFixed(2);
            dataCounts.z = Libs.round((countz / curItem.totalRow), 2).toFixed(2);

            dataCounts.aa = Libs.round((countaa / curItem.totalRow), 2).toFixed(2);
            dataCounts.ab = Libs.round((countab / curItem.totalRow), 2).toFixed(2);
            dataCounts.ac = Libs.round((countac / curItem.totalRow), 2).toFixed(2);
            dataCounts.ad = Libs.round((countad / curItem.totalRow), 2).toFixed(2);
            dataCounts.ae = Libs.round((countae / curItem.totalRow), 2).toFixed(2);
            dataCounts.af = Libs.round((countaf / curItem.totalRow), 2).toFixed(2);
            dataCounts.ag = Libs.round((countag / curItem.totalRow), 2).toFixed(2);
            dataCounts.ah = Libs.round((countah / curItem.totalRow), 2).toFixed(2);
            dataCounts.ai = Libs.round((countai / curItem.totalRow), 2).toFixed(2);
            dataCounts.aj = Libs.round((countaj / curItem.totalRow), 2).toFixed(2);
            dataCounts.ak = Libs.round((countak / curItem.totalRow), 2).toFixed(2);
            dataCounts.al = Libs.round((countal / curItem.totalRow), 2).toFixed(2);
            dataCounts.am = Libs.round((countam / curItem.totalRow), 2).toFixed(2);
            dataCounts.an = Libs.round((countan / curItem.totalRow), 2).toFixed(2);
            dataCounts.ao = Libs.round((countao / curItem.totalRow), 2).toFixed(2);
            dataCounts.ap = Libs.round((countap / curItem.totalRow), 2).toFixed(2);
            dataCounts.aq = Libs.round((countaq / curItem.totalRow), 2).toFixed(2);
            dataCounts.ar = Libs.round((countar / curItem.totalRow), 2).toFixed(2);
            dataCounts.as = Libs.round((countas / curItem.totalRow), 2).toFixed(2);
            dataCounts.at = Libs.round((countat / curItem.totalRow), 2).toFixed(2);
            dataCounts.au = Libs.round((countau / curItem.totalRow), 2).toFixed(2);
            dataCounts.av = Libs.round((countav / curItem.totalRow), 2).toFixed(2);
            dataCounts.aw = Libs.round((countaw / curItem.totalRow), 2).toFixed(2);
            dataCounts.ax = Libs.round((countax / curItem.totalRow), 2).toFixed(2);
            dataCounts.ay = Libs.round((countay / curItem.totalRow), 2).toFixed(2);
            dataCounts.az = Libs.round((countaz / curItem.totalRow), 2).toFixed(2);

            dataCounts.ba = Libs.round((countba / curItem.totalRow), 2).toFixed(2);
            dataCounts.bb = Libs.round((countbb / curItem.totalRow), 2).toFixed(2);
            dataCounts.bc = Libs.round((countbc / curItem.totalRow), 2).toFixed(2);
            dataCounts.bd = Libs.round((countbd / curItem.totalRow), 2).toFixed(2);
            dataCounts.be = Libs.round((countbe / curItem.totalRow), 2).toFixed(2);
            dataCounts.bf = Libs.round((countbf / curItem.totalRow), 2).toFixed(2);
            dataCounts.bg = Libs.round((countbg / curItem.totalRow), 2).toFixed(2);
            dataCounts.bh = Libs.round((countbh / curItem.totalRow), 2).toFixed(2);
            dataCounts.bi = Libs.round((countbi / curItem.totalRow), 2).toFixed(2);
            dataCounts.bj = Libs.round((countbj / curItem.totalRow), 2).toFixed(2);
            dataCounts.bk = Libs.round((countbk / curItem.totalRow), 2).toFixed(2);
            dataCounts.bl = Libs.round((countbl / curItem.totalRow), 2).toFixed(2);
            dataCounts.bm = Libs.round((countbm / curItem.totalRow), 2).toFixed(2);
            dataCounts.bn = Libs.round((countbn / curItem.totalRow), 2).toFixed(2);
            dataCounts.bo = Libs.round((countbo / curItem.totalRow), 2).toFixed(2);
            dataCounts.bp = Libs.round((countbp / curItem.totalRow), 2).toFixed(2);
            dataCounts.bq = Libs.round((countbq / curItem.totalRow), 2).toFixed(2);
            dataCounts.br = Libs.round((countbr / curItem.totalRow), 2).toFixed(2);
            dataCounts.bs = Libs.round((countbs / curItem.totalRow), 2).toFixed(2);
            dataCounts.bt = Libs.round((countbt / curItem.totalRow), 2).toFixed(2);
            dataCounts.bu = Libs.round((countbu / curItem.totalRow), 2).toFixed(2);
            dataCounts.bv = Libs.round((countbv / curItem.totalRow), 2).toFixed(2);
            dataCounts.bw = Libs.round((countbw / curItem.totalRow), 2).toFixed(2);
            dataCounts.bx = Libs.round((countbx / curItem.totalRow), 2).toFixed(2);
            dataCounts.by = Libs.round((countby / curItem.totalRow), 2).toFixed(2);
            dataCounts.bz = Libs.round((countbz / curItem.totalRow), 2).toFixed(2);

            dataCounts.ca = Libs.round((countca / curItem.totalRow), 2).toFixed(2);
            dataCounts.cb = Libs.round((countcb / curItem.totalRow), 2).toFixed(2);
            dataCounts.cc = Libs.round((countcc / curItem.totalRow), 2).toFixed(2);


        }
        this.setState({
            dataCounts: dataCounts
        });
    }


    reRenderData() {
        var { dataList, curItem, dataCounts, curItemReset } = this.state, self = this;
        
        if(curItem.clickDataOne == 0){
            curItem.clickDataOne = 1;
            curItemReset.dataList = cloneDeep(dataList);
        }


        curItem.index_active = null;
        var counta = null,
            countb = null,
            countc = null,
            countd = null,
            counte = null,
            countf = null,
            countg = null,
            counth = null,
            counti = null,
            countj = null,
            countk = null,
            countl = null,
            countm = null,
            countn = null,
            counto = null,
            countp = null,
            countq = null,
            countr = null,
            counts = null,
            countt = null,

            countu = null,
            countv = null,
            countw = null,
            countx = null,
            county = null,
            countz = null,

            countaa = null,
            countab = null,
            countac = null,
            countad = null,
            countae = null,
            countaf = null,
            countag = null,
            countah = null,
            countai = null,
            countaj = null,
            countak = null,
            countal = null,
            countam = null,
            countan = null,
            countao = null,
            countap = null,

            countaq = null,
            countar = null,
            countas = null,
            countat = null,
            countau = null,
            countav = null,
            countaw = null,
            countax = null,
            countay = null,
            countaz = null,

            countba = null,
            countbb = null,
            countbc = null,
            countbd = null,
            countbe = null,
            countbf = null,
            countbg = null,
            countbh = null,
            countbi = null,
            countbj = null,
            countbk = null,
            countbl = null,
            countbm = null,
            countbn = null,
            countbo = null,
            countbp = null,
            countbq = null,
            countbr = null,
            countbs = null,
            countbt = null,
            countbu = null,
            countbv = null,
            countbw = null,
            countbx = null,
            countby = null,
            countbz = null,

            countca = null,
            countcb = null,
            countcc = null;

        var dataNews = [];
        if (Libs.isArrayData(dataList)) {
            for (var i = 0; i < curItem.totalRow; i++) {
                if (!Libs.isBlank(dataList[i].a)) { counta = counta + Number(dataList[i].a); }
                if (!Libs.isBlank(dataList[i].b)) { countb = countb + Number(dataList[i].b); }
                if (!Libs.isBlank(dataList[i].c)) { countc = countc + Number(dataList[i].c); }
                if (!Libs.isBlank(dataList[i].d)) { countd = countd + Number(dataList[i].d); }
                if (!Libs.isBlank(dataList[i].e)) { counte = counte + Number(dataList[i].e); }
                if (!Libs.isBlank(dataList[i].f)) { countf = countf + Number(dataList[i].f); }
                if (!Libs.isBlank(dataList[i].g)) { countg = countg + Number(dataList[i].g); }
                if (!Libs.isBlank(dataList[i].h)) { counth = counth + Number(dataList[i].h); }
                if (!Libs.isBlank(dataList[i].i)) { counti = counti + Number(dataList[i].i); }
                if (!Libs.isBlank(dataList[i].j)) { countj = countj + Number(dataList[i].j); }
                if (!Libs.isBlank(dataList[i].k)) { countk = countk + Number(dataList[i].k); }
                if (!Libs.isBlank(dataList[i].l)) { countl = countl + Number(dataList[i].l); }
                if (!Libs.isBlank(dataList[i].m)) { countm = countm + Number(dataList[i].m); }
                if (!Libs.isBlank(dataList[i].n)) { countn = countn + Number(dataList[i].n); }
                if (!Libs.isBlank(dataList[i].o)) { counto = counto + Number(dataList[i].o); }
                if (!Libs.isBlank(dataList[i].p)) { countp = countp + Number(dataList[i].p); }
                if (!Libs.isBlank(dataList[i].q)) { countq = countq + Number(dataList[i].q); }
                if (!Libs.isBlank(dataList[i].r)) { countr = countr + Number(dataList[i].r); }
                if (!Libs.isBlank(dataList[i].s)) { counts = counts + Number(dataList[i].s); }
                if (!Libs.isBlank(dataList[i].t)) { countt = countt + Number(dataList[i].t); }

                if (!Libs.isBlank(dataList[i].u)) { countu = countu + Number(dataList[i].u); }
                if (!Libs.isBlank(dataList[i].v)) { countv = countv + Number(dataList[i].v); }
                if (!Libs.isBlank(dataList[i].w)) { countw = countw + Number(dataList[i].w); }
                if (!Libs.isBlank(dataList[i].x)) { countx = countx + Number(dataList[i].x); }
                if (!Libs.isBlank(dataList[i].y)) { county = county + Number(dataList[i].y); }
                if (!Libs.isBlank(dataList[i].z)) { countz = countz + Number(dataList[i].z); }
                if (!Libs.isBlank(dataList[i].aa)) { countaa = countaa + Number(dataList[i].aa); }
                if (!Libs.isBlank(dataList[i].ab)) { countab = countab + Number(dataList[i].ab); }
                if (!Libs.isBlank(dataList[i].ac)) { countac = countac + Number(dataList[i].ac); }
                if (!Libs.isBlank(dataList[i].ad)) { countad = countad + Number(dataList[i].ad); }
                if (!Libs.isBlank(dataList[i].ae)) { countae = countae + Number(dataList[i].ae); }
                if (!Libs.isBlank(dataList[i].af)) { countaf = countaf + Number(dataList[i].af); }
                if (!Libs.isBlank(dataList[i].ag)) { countag = countag + Number(dataList[i].ag); }
                if (!Libs.isBlank(dataList[i].ah)) { countah = countah + Number(dataList[i].ah); }
                if (!Libs.isBlank(dataList[i].ai)) { countai = countai + Number(dataList[i].ai); }
                if (!Libs.isBlank(dataList[i].aj)) { countaj = countaj + Number(dataList[i].aj); }
                if (!Libs.isBlank(dataList[i].ak)) { countak = countak + Number(dataList[i].ak); }
                if (!Libs.isBlank(dataList[i].al)) { countal = countal + Number(dataList[i].al); }
                if (!Libs.isBlank(dataList[i].am)) { countam = countam + Number(dataList[i].am); }
                if (!Libs.isBlank(dataList[i].an)) { countan = countan + Number(dataList[i].an); }
                if (!Libs.isBlank(dataList[i].ao)) { countao = countao + Number(dataList[i].ao); }
                if (!Libs.isBlank(dataList[i].ap)) { countap = countap + Number(dataList[i].ap); }
                if (!Libs.isBlank(dataList[i].aq)) { countap = countap + Number(dataList[i].aq); }
                if (!Libs.isBlank(dataList[i].ar)) { countap = countap + Number(dataList[i].ar); }
                if (!Libs.isBlank(dataList[i].as)) { countap = countap + Number(dataList[i].as); }
                if (!Libs.isBlank(dataList[i].at)) { countap = countap + Number(dataList[i].at); }
                if (!Libs.isBlank(dataList[i].au)) { countap = countap + Number(dataList[i].au); }
                if (!Libs.isBlank(dataList[i].av)) { countap = countap + Number(dataList[i].av); }
                if (!Libs.isBlank(dataList[i].aw)) { countap = countap + Number(dataList[i].aw); }
                if (!Libs.isBlank(dataList[i].ax)) { countap = countap + Number(dataList[i].ax); }
                if (!Libs.isBlank(dataList[i].ay)) { countap = countap + Number(dataList[i].ay); }
                if (!Libs.isBlank(dataList[i].az)) { countap = countap + Number(dataList[i].az); }


                if (!Libs.isBlank(dataList[i].ba)) { countba = countba + Number(dataList[i].ba); }
                if (!Libs.isBlank(dataList[i].bb)) { countbb = countbb + Number(dataList[i].bb); }
                if (!Libs.isBlank(dataList[i].bc)) { countbc = countbc + Number(dataList[i].bc); }
                if (!Libs.isBlank(dataList[i].bd)) { countbd = countbd + Number(dataList[i].bd); }
                if (!Libs.isBlank(dataList[i].be)) { countbe = countbe + Number(dataList[i].be); }
                if (!Libs.isBlank(dataList[i].bf)) { countbf = countbf + Number(dataList[i].bf); }
                if (!Libs.isBlank(dataList[i].bg)) { countbg = countbg + Number(dataList[i].bg); }
                if (!Libs.isBlank(dataList[i].bh)) { countbh = countbh + Number(dataList[i].bh); }
                if (!Libs.isBlank(dataList[i].bi)) { countbi = countbi + Number(dataList[i].bi); }
                if (!Libs.isBlank(dataList[i].bj)) { countbj = countbj + Number(dataList[i].bj); }
                if (!Libs.isBlank(dataList[i].bk)) { countbk = countbk + Number(dataList[i].bk); }
                if (!Libs.isBlank(dataList[i].bl)) { countbl = countbl + Number(dataList[i].bl); }
                if (!Libs.isBlank(dataList[i].bm)) { countbm = countbm + Number(dataList[i].bm); }
                if (!Libs.isBlank(dataList[i].bn)) { countbn = countbn + Number(dataList[i].bn); }
                if (!Libs.isBlank(dataList[i].bo)) { countbo = countbo + Number(dataList[i].bo); }
                if (!Libs.isBlank(dataList[i].bp)) { countbp = countbp + Number(dataList[i].bp); }
                if (!Libs.isBlank(dataList[i].bq)) { countbq = countbq + Number(dataList[i].bq); }
                if (!Libs.isBlank(dataList[i].br)) { countbr = countbr + Number(dataList[i].br); }
                if (!Libs.isBlank(dataList[i].bs)) { countbs = countbs + Number(dataList[i].bs); }
                if (!Libs.isBlank(dataList[i].bt)) { countbt = countbt + Number(dataList[i].bt); }
                if (!Libs.isBlank(dataList[i].bu)) { countbu = countbu + Number(dataList[i].bu); }
                if (!Libs.isBlank(dataList[i].bv)) { countbv = countbv + Number(dataList[i].bv); }
                if (!Libs.isBlank(dataList[i].bw)) { countbw = countbw + Number(dataList[i].bw); }
                if (!Libs.isBlank(dataList[i].bx)) { countbx = countbx + Number(dataList[i].bx); }
                if (!Libs.isBlank(dataList[i].by)) { countby = countby + Number(dataList[i].by); }
                if (!Libs.isBlank(dataList[i].bz)) { countbz = countbz + Number(dataList[i].bz); }

                if (!Libs.isBlank(dataList[i].ca)) { countca = countca + Number(dataList[i].ca); }
                if (!Libs.isBlank(dataList[i].cb)) { countcb = countcb + Number(dataList[i].cb); }
                if (!Libs.isBlank(dataList[i].cc)) { countcc = countcc + Number(dataList[i].cc); }


                dataNews.push(dataList[i]);
            }

            dataCounts.a = Libs.round((counta / curItem.totalRow), 2).toFixed(2);
            dataCounts.b = Libs.round((countb / curItem.totalRow), 2).toFixed(2);
            dataCounts.c = Libs.round((countc / curItem.totalRow), 2).toFixed(2);
            dataCounts.d = Libs.round((countd / curItem.totalRow), 2).toFixed(2);
            dataCounts.e = Libs.round((counte / curItem.totalRow), 2).toFixed(2);
            dataCounts.f = Libs.round((countf / curItem.totalRow), 2).toFixed(2);
            dataCounts.g = Libs.round((countg / curItem.totalRow), 2).toFixed(2);
            dataCounts.h = Libs.round((counth / curItem.totalRow), 2).toFixed(2);
            dataCounts.i = Libs.round((counti / curItem.totalRow), 2).toFixed(2);
            dataCounts.j = Libs.round((countj / curItem.totalRow), 2).toFixed(2);
            dataCounts.k = Libs.round((countk / curItem.totalRow), 2).toFixed(2);
            dataCounts.l = Libs.round((countl / curItem.totalRow), 2).toFixed(2);
            dataCounts.m = Libs.round((countm / curItem.totalRow), 2).toFixed(2);
            dataCounts.n = Libs.round((countn / curItem.totalRow), 2).toFixed(2);
            dataCounts.o = Libs.round((counto / curItem.totalRow), 2).toFixed(2);
            dataCounts.p = Libs.round((countp / curItem.totalRow), 2).toFixed(2);
            dataCounts.q = Libs.round((countq / curItem.totalRow), 2).toFixed(2);
            dataCounts.r = Libs.round((countr / curItem.totalRow), 2).toFixed(2);
            dataCounts.s = Libs.round((counts / curItem.totalRow), 2).toFixed(2);
            dataCounts.t = Libs.round((countt / curItem.totalRow), 2).toFixed(2);
            dataCounts.u = Libs.round((countu / curItem.totalRow), 2).toFixed(2);
            dataCounts.v = Libs.round((countv / curItem.totalRow), 2).toFixed(2);
            dataCounts.w = Libs.round((countw / curItem.totalRow), 2).toFixed(2);
            dataCounts.x = Libs.round((countx / curItem.totalRow), 2).toFixed(2);
            dataCounts.y = Libs.round((county / curItem.totalRow), 2).toFixed(2);
            dataCounts.z = Libs.round((countz / curItem.totalRow), 2).toFixed(2);

            dataCounts.aa = Libs.round((countaa / curItem.totalRow), 2).toFixed(2);
            dataCounts.ab = Libs.round((countab / curItem.totalRow), 2).toFixed(2);
            dataCounts.ac = Libs.round((countac / curItem.totalRow), 2).toFixed(2);
            dataCounts.ad = Libs.round((countad / curItem.totalRow), 2).toFixed(2);
            dataCounts.ae = Libs.round((countae / curItem.totalRow), 2).toFixed(2);
            dataCounts.af = Libs.round((countaf / curItem.totalRow), 2).toFixed(2);
            dataCounts.ag = Libs.round((countag / curItem.totalRow), 2).toFixed(2);
            dataCounts.ah = Libs.round((countah / curItem.totalRow), 2).toFixed(2);
            dataCounts.ai = Libs.round((countai / curItem.totalRow), 2).toFixed(2);
            dataCounts.aj = Libs.round((countaj / curItem.totalRow), 2).toFixed(2);
            dataCounts.ak = Libs.round((countak / curItem.totalRow), 2).toFixed(2);
            dataCounts.al = Libs.round((countal / curItem.totalRow), 2).toFixed(2);
            dataCounts.am = Libs.round((countam / curItem.totalRow), 2).toFixed(2);
            dataCounts.an = Libs.round((countan / curItem.totalRow), 2).toFixed(2);
            dataCounts.ao = Libs.round((countao / curItem.totalRow), 2).toFixed(2);
            dataCounts.ap = Libs.round((countap / curItem.totalRow), 2).toFixed(2);
            dataCounts.aq = Libs.round((countaq / curItem.totalRow), 2).toFixed(2);
            dataCounts.ar = Libs.round((countar / curItem.totalRow), 2).toFixed(2);
            dataCounts.as = Libs.round((countas / curItem.totalRow), 2).toFixed(2);
            dataCounts.at = Libs.round((countat / curItem.totalRow), 2).toFixed(2);
            dataCounts.au = Libs.round((countau / curItem.totalRow), 2).toFixed(2);
            dataCounts.av = Libs.round((countav / curItem.totalRow), 2).toFixed(2);
            dataCounts.aw = Libs.round((countaw / curItem.totalRow), 2).toFixed(2);
            dataCounts.ax = Libs.round((countax / curItem.totalRow), 2).toFixed(2);
            dataCounts.ay = Libs.round((countay / curItem.totalRow), 2).toFixed(2);
            dataCounts.az = Libs.round((countaz / curItem.totalRow), 2).toFixed(2);

            dataCounts.ba = Libs.round((countba / curItem.totalRow), 2).toFixed(2);
            dataCounts.bb = Libs.round((countbb / curItem.totalRow), 2).toFixed(2);
            dataCounts.bc = Libs.round((countbc / curItem.totalRow), 2).toFixed(2);
            dataCounts.bd = Libs.round((countbd / curItem.totalRow), 2).toFixed(2);
            dataCounts.be = Libs.round((countbe / curItem.totalRow), 2).toFixed(2);
            dataCounts.bf = Libs.round((countbf / curItem.totalRow), 2).toFixed(2);
            dataCounts.bg = Libs.round((countbg / curItem.totalRow), 2).toFixed(2);
            dataCounts.bh = Libs.round((countbh / curItem.totalRow), 2).toFixed(2);
            dataCounts.bi = Libs.round((countbi / curItem.totalRow), 2).toFixed(2);
            dataCounts.bj = Libs.round((countbj / curItem.totalRow), 2).toFixed(2);
            dataCounts.bk = Libs.round((countbk / curItem.totalRow), 2).toFixed(2);
            dataCounts.bl = Libs.round((countbl / curItem.totalRow), 2).toFixed(2);
            dataCounts.bm = Libs.round((countbm / curItem.totalRow), 2).toFixed(2);
            dataCounts.bn = Libs.round((countbn / curItem.totalRow), 2).toFixed(2);
            dataCounts.bo = Libs.round((countbo / curItem.totalRow), 2).toFixed(2);
            dataCounts.bp = Libs.round((countbp / curItem.totalRow), 2).toFixed(2);
            dataCounts.bq = Libs.round((countbq / curItem.totalRow), 2).toFixed(2);
            dataCounts.br = Libs.round((countbr / curItem.totalRow), 2).toFixed(2);
            dataCounts.bs = Libs.round((countbs / curItem.totalRow), 2).toFixed(2);
            dataCounts.bt = Libs.round((countbt / curItem.totalRow), 2).toFixed(2);
            dataCounts.bu = Libs.round((countbu / curItem.totalRow), 2).toFixed(2);
            dataCounts.bv = Libs.round((countbv / curItem.totalRow), 2).toFixed(2);
            dataCounts.bw = Libs.round((countbw / curItem.totalRow), 2).toFixed(2);
            dataCounts.bx = Libs.round((countbx / curItem.totalRow), 2).toFixed(2);
            dataCounts.by = Libs.round((countby / curItem.totalRow), 2).toFixed(2);
            dataCounts.bz = Libs.round((countbz / curItem.totalRow), 2).toFixed(2);

            dataCounts.ca = Libs.round((countca / curItem.totalRow), 2).toFixed(2);
            dataCounts.cb = Libs.round((countcb / curItem.totalRow), 2).toFixed(2);
            dataCounts.cc = Libs.round((countcc / curItem.totalRow), 2).toFixed(2);


        }

        

        this.setState({
            dataCounts: dataCounts,
            dataList: dataNews,
            curItemReset: curItemReset
        }, () => {
            self.applyFunction(1);
        });
    }

    render() {
        return this.jsxTemplate.call(this)
    }
}



const HighOrderComponentTranslated = withTranslation('common')(DataRegressionSeven)
export default HighOrderComponentTranslated;