import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { RText } from '../../../../components/Controls';
export default function () {
    var { curItem } = this.state;
    return (
        <Modal visible={true} className="modal-add form-add-value" onClickBackdrop={this.props.onClickCloseShowForm.bind(this, null) } dialogClassName="modal-small modal-dialog-centered" >
            <div className="modal-body">

                <p>Giá trị cộng thêm</p>
                <div className='fm'>
                    <RText
                        inputClass="form-control"
                        inputId={"value_add"}
                        inputName="value_add"
                        value={curItem.value_add}
                        onChange={(e) => { this.handleInputChange(e) }}
                    />
                    <div className='save-form' onClick={this.onSave.bind(this)}></div>
                </div>

            </div>

        </Modal>

    )
}