
import BaseComponent from '../../../BaseComponent';
import Libs from '../../../../utils/Libs';
import AddMultiVariableJsx from './AddMultiVariable.jsx';
import AddMultiVariableValidate from './AddMultiVariableValidate';


class AddMultiVariable extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItem
        }
        this.jsxTemplate = AddMultiVariableJsx;
    }

    componentDidMount() {
        var { curItem } = this.state;
        var dataForm = curItem.dataForm;
        var fields = "", keys = "";
        if (Libs.isArrayData(dataForm)) {
            dataForm.map((item, index) => {
                fields += item.name + (index == dataForm.length - 1 ? "" : "\n");
                keys += item.name + (index == dataForm.length - 1 ? "" : "\n");;
            })
        }
        curItem.fields = fields;
        curItem.keys = keys;
        this.setState({
            curItem: curItem
        })
    }
    /** 
     * @description Save info GoogleForm
     * @author long.pham 28/07/2019
    */
    async onSaveAction() {
        let { curItem } = this.state, self = this;

        if (Libs.isBlank(curItem.fields) || Libs.isBlank(curItem.keys)) { return; }
        var fields = curItem.fields;
        var fieldArr = fields.split('\n');

        var keys = curItem.keys;
        var keysArr = keys.split('\n');
        this.props.callbackListFields(fieldArr, keysArr);
        curItem.fields = "";
        curItem.keys = "";
    }

    /**
     * @description validate a field input
     * @author long.pham 28/07/2022
     * @param {*} event 
     */
    async validateOne(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value
        if (name) {
            let param = {
                [name]: value
            }
            let v = new AddMultiVariableValidate(this.props);
            let error = await v.validateOne(param, name);
            if (error != null) {
                this.setValidateMessage(error, true);
            }
        }
    }
}
export default AddMultiVariable;