import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { RButton, RTextArea } from '../../../../components/Controls';
import Constants from '../../../../utils/Constants';



export default function () {
    var { curItem } = this.state;
    const { t } = this.props;
    return (
        <Modal visible={true} className="modal-add google-form multi-variable" dialogClassName="modal-lg modal-dialog-scrollable" >
            <div className="modal-header">
                <h5 className="modal-title">
                    Nhập danh sách tên biến và key
                </h5>
                <button type="button" onClick={this.props.onCloseAddMultiVariable.bind(this)} className="bootbox-close-button close" data-dismiss="modal" aria-hidden="true"><span>×</span></button>
            </div>
            <div className="modal-body">

                {curItem.screen_mode != Constants.SCREEN_MODE.VIEW ?
                    <div className="box-info">
                        <div className="box-translate">
                            <div className="row">

                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="mb-3">
                                        <RTextArea label="Nhập danh sách tên biến (mỗi biến nằm trên 1 hàng)"
                                            inputClass="form-control"
                                            required="required"
                                            inputId="fields" inputName="fields"
                                            value={curItem.fields}
                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                            style={{ height: "400px" }}
                                            placeholder={"Biến 1 \nBiến 2 \nBiến n"}
                                        />
                                    </div>
                                </div>

                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="mb-3">
                                        <RTextArea label="Nhập danh sách key (mỗi key nằm trên 1 hàng)"
                                            inputClass="form-control"
                                            required="required"
                                            inputId="keys" inputName="keys"
                                            value={curItem.keys}
                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e) }}
                                            style={{ height: "400px" }}
                                            placeholder={"Key 1 \nKey 2 \nKey n"}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    : ""}
            </div>
            <div className="modal-footer">
                <RButton
                    onClick={this.props.onCloseAddMultiVariable.bind(this)}
                    className="btn btn-cancel"
                    text={" " + t('common.cancel')}
                    title={t('common.cancel')} />

                <RButton
                    onClick={this.onSaveAction.bind(this)}
                    className="btn btn-danger btn-add"
                    text={"Lưu"}
                    title={"Lưu"} />


            </div>
        </Modal>
    )
}