
import BaseComponent from '../../../BaseComponent';
import Libs from '../../../../utils/Libs';
import DeletePopupJsx from './DeletePopup.jsx';
import GoogleFormService from '../../../../services/GoogleFormService';

class DeletePopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItem,
        }
        this.jsxTemplate = DeletePopupJsx;
    }

    /**
     * @description close popup
     * @author long.pham 28/07/2019
     */
    onCloseDeletePopup() {
        if (!this.props.onCloseDeletePopup || typeof this.props.onCloseDeletePopup !== 'function') return;
        this.props.onCloseDeletePopup(false);
    }

    onSelectEventDelete = (event) => {
        let self = this;
        let target = event.target;
        let name = target.name;
        let value = target.value;
        if (name) {
            let curItem = self.state.curItem;
            curItem.status = -1;
            curItem[name] = (event.target.validity.valid) ? value : curItem[name];
            self.setState({
                curItem: curItem
            });
        }
    }

    /** 
     * @description delete info store
     * @author long.pham 28/08/2022
    */
    async onDeleteAction() {
        let params = this.state.curItem, self = this;
        GoogleFormService.instance.delete(params, function (status, data, msg) {
            if (status) {
                self.toast(msg, "info");
                self.props.onCloseDeletePopup(true);
            }
            else if (data) {
                this.setValidateMessage(data);
            }
            else if (!Libs.isBlank(msg)) {
                self.toast(msg, "error");
            }
        }, true);
    }
}
export default DeletePopup;