// var baseUrl = window.location.protocol + "//" + window.location.host;
var url = window.location.hostname;
var serverAPI = '';
switch (url) {
    case 'ext.hocnghiencuu.com':
    case 'www.ext.hocnghiencuu.com':
        serverAPI = 'https://api-forms.hocnghiencuu.com';
        break;
    default:
        // serverAPI = 'https://api-forms.hocnghiencuu.com';
        // serverAPI = 'http://116.118.49.186:3010';
        serverAPI = "http://localhost:3023";
        
        break;
}

const Constants = {
    "API_HOST": serverAPI,
    "TINY_API_KEY": "w1ac8su5n6gkls7pzfyeg9b7ovfjb6988fm90c8mtr3zs8wt",
    "STATIC_KEY" : "6UTcKodwS4mSBIZgI9pC11vLfcTobNkz5ivqVVGn0PiC44Hev9w3vGZ4NU1RZqQL",
    "COMMON": {
        "EMPLOYEE_INFO": "staff",
        "LIMIT_IMG": 15,
        "MAX_IMAGE_SIZE": 1048576,
        "MAX_VIDEO_SIZE": 62914560,
        "LIMIT": 5,
        "TOKEN": "trek-access-token",
        "ACCESS_PARAM": "access-param"
    },
    "URL": {

        "GOOGLE_FORM": {
            "LIST": "/GoogleForm/getList",
            "UPDATE_STATUS": "/GoogleForm/updateStatus",
            "SAVE": "/GoogleForm/saveAction",
            "DELETE": "/GoogleForm/deleteAction",
            "DROPDOWN_LIST": "/GoogleForm/getDropdownList",
            "DETAIL": "/GoogleForm/getDetail",
            "UPLOAD_GOOGLE_FORM": "/GoogleForm/uploadGoogleFormAction",
        },

        "EMPLOYEE": {
            "LOGIN": "/auth/login",
            "FORGOT_PASSWORD": "/employee/forgotPassword",
            "RESET_PASSWORD": "/employee/resetPassword",
            "DETAIL_UPDATE_PROFILE": "/employee/getDetailUpdateProfile",
            "SAVE_UPDATE_PROFILE": "/employee/saveUpdateProfileAction",
            "CHANGE_PASSWORD": "/employee/changePassword",
            "LIST": "/employee/getList",
            "LIST_ALL": "/employee/getListAll",
            "SAVE": "/employee/saveAction",
            "UPDATE_STATUS": "/employee/updateStatus",
            "DELETE": "/employee/deleteAction",
            "DETAIL": "/employee/getDetail",
            "LIST_CONFIG_MAIL": "/employee/getListProjectConfigMail",
            "UPDATE_STATUS_MAIL_CONFIG":  "/employee/updateStatusMailConfig"
        },

        "ROLE": {
            "SAVE": "/role/saveAction",
            "LIST": "/role/getList",
            "UPDATE_STATUS": "/role/updateStatus",
            "DELETE": "/role/deleteAction",
            "DETAIL": "/role/getDetail",
            "DROPDOWN_LIST": "/role/getDropDownList",
            "PERMISSION": "/role/getListScreenPermissions",
            "UPDATE_PERMISSIONS": "/role/updateRolePermissions",
            "UPDATE_ROLE_MAP_SCREEN": "/role/updateRoleMapScreen"
        },

    },

    "SITE_URL": {
        "PUBLIC_URL": ['/', '/forgot-password', '/reset-password', '/google-form'],
        "LOGIN": "/",
        "DEFAULT_PAGE": "/dashboard"
    },

    "CONTENT_TYPE": {
        json: "application/json",
        stream: "application/octet-stream",
        excel: "application/vnd.ms-excel",
        multipart: "multipart/form-data"
    },

    "METHOD": {
        POST: "post",
        GET: "get",
        DELETE: "delete",
        PUT: "put",
        POSTEXCEL: "postexcel",
        POSTPDF: "postpdf"
    },

    "ERROR_MSG_TIMEOUT": 8000,

    "ERROR_CODE": {
        "CODE_01": 1,
        "CODE_02": 1,
    },

    "SCREEN_MODE": {
        "VIEW": 0,
        "ADD": 1,
        "EDIT": 2
    },
    "AUTH_MODE": {
        "VIEW": 0,
        "NEW": 1,
        "DEL": 2,
        "EDIT": 3,
        "EXCEL": 4,
        "PDF": 5,
        "PRINT": 6,
        "TRANSLATE": 7,
        "APPROVAL": 8,
        "SET": 9,
        "FULL" : 511
    },
    "AUTH_DATA_KEY" : {
        "VIEW": "view",
        "NEW": "new",
        "EDIT": "edit",
        "DEL": "delete",
        "PRINT": "print",
        "PDF": "pdf",
        "EXCEL": "excel",
        "TRANSLATE": "translate",
        "APPROVAL":"approval",
        "FULL": "auths"
    },
};
export default Constants;