import React from 'react';
import { RText, RCheckbox } from '../../../../components/Controls';
import MetaTags from 'react-meta-tags';
import AddPopup from './AddPopup';
import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react';
import { textRenderer } from 'handsontable/renderers';
import Libs from '../../../../utils/Libs';
registerAllModules();



export default function () {
  var { dataList, curItem, dataCounts, dataInput, dataOutput, indexChange } = this.state;
  var inputBtn = [], outputBtn = [];


  function firstRowRenderer(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.color = 'red';
    td.style.background = '#f3ff00';
    td.style.textAlign = 'center';
  }


  function backgroudRowRenderer(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = '#f3ff00';
    td.style.textAlign = 'center';
  }

  function backgroudRowRendererOut1(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = '#e0eaf6';
    td.style.textAlign = 'center';
  }

  function backgroudRowRendererOut2(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = '#ededed';
    td.style.textAlign = 'center';
  }

  function backgroudRowRendererOut3(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = '#f8e5d8';
    td.style.textAlign = 'center';
  }

  function backgroudRowRendererOut4(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = '#e4efdc';
    td.style.textAlign = 'center';
  }

  if (!Libs.isBlank(curItem.input) && curItem.input > 0) {
    for (var i = 0; i < curItem.input; i++) {
      inputBtn.push(
        <li key={i}>
          <RText
            inputClass="form-control text-center"
            inputId={"value_input_" + i}
            inputName={"value_input_" + i}
            value={dataInput[i]}
            onChange={this.handleInputSubChange.bind(this, i)}
            onKeyDown={this.handleInputSubChangeEnter.bind(this, i)}
          />
        </li>
      );
    }
  }

  if (!Libs.isBlank(curItem.input) && curItem.input > 0) {
    for (var i = 0; i < curItem.input; i++) {
      outputBtn.push(
        <li key={i}>
          <div id={i} className='item' onClick={this.onClickShowForm.bind(this, i)}>
            -
          </div>
        </li>

      );
    }
  }


  var width = document.body.clientWidth;
  var height = document.body.clientHeight;
  var nestedHeaders = [
    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
      'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ',
      'BA', 'BB', 'BC', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BK', 'BL', 'BM', 'BN', 'BO', 'BP', 'BQ', 'BR', 'BS', 'BT', 'BU', 'BV', 'BW', 'BX', 'BY', 'BZ',
      'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'CG', 'CH', 'CI', 'CJ', 'CK',
    ],
    [
      dataCounts.a,
      dataCounts.b,
      dataCounts.c,
      dataCounts.d,
      dataCounts.e,
      dataCounts.f,
      dataCounts.g,
      dataCounts.h,
      dataCounts.i,
      dataCounts.j,
      dataCounts.k,
      dataCounts.l,
      dataCounts.m,
      dataCounts.n,
      dataCounts.o,
      dataCounts.p,
      dataCounts.q,
      dataCounts.r,
      dataCounts.s,
      dataCounts.t,
      dataCounts.u,
      dataCounts.v,
      dataCounts.w,
      dataCounts.x,
      dataCounts.y,
      dataCounts.z,

      dataCounts.aa,
      dataCounts.ab,
      dataCounts.ac,
      dataCounts.ad,
      dataCounts.ae,
      dataCounts.af,
      dataCounts.ag,
      dataCounts.ah,
      dataCounts.ai,
      dataCounts.aj,
      dataCounts.ak,
      dataCounts.al,
      dataCounts.am,
      dataCounts.an,
      dataCounts.ao,
      dataCounts.ap,
      dataCounts.aq,
      dataCounts.ar,
      dataCounts.as,
      dataCounts.at,
      dataCounts.au,
      dataCounts.av,
      dataCounts.aw,
      dataCounts.ax,
      dataCounts.ay,
      dataCounts.az,

      dataCounts.ba,
      dataCounts.bb,
      dataCounts.bc,
      dataCounts.bd,
      dataCounts.be,
      dataCounts.bf,
      dataCounts.bg,
      dataCounts.bh,
      dataCounts.bi,
      dataCounts.bj,
      dataCounts.bk,
      dataCounts.bl,
      dataCounts.bm,
      dataCounts.bn,
      dataCounts.bo,
      dataCounts.bp,
      dataCounts.bq,
      dataCounts.br,
      dataCounts.bs,
      dataCounts.bt,
      dataCounts.bu,
      dataCounts.bv,
      dataCounts.bw,
      dataCounts.bx,
      dataCounts.by,
      dataCounts.bz,

      dataCounts.ca,
      dataCounts.cb,
      dataCounts.cc,
    ]

  ];

  var layoutAddForm = this.state.showForm ?
    <AddPopup
      onClickCloseShowForm={this.onClickCloseShowForm.bind(this)}
      curItem={curItem}
      auth={this.props.auth}
      actions={this.actions}
    />
    : null;
  return (
    <React.Fragment>
      <MetaTags>
        <title>Hồi quy Likert 7</title>
      </MetaTags>

      {layoutAddForm}
      <div className="regression" >
        <div className="main">
          <section className="content-header">
            <div className="row">
              <div className="col-lg-9 col-sm-9 col-md-9 col-sx-9">
                <div className='row'>
                  <div className='col-md-2'>
                    <div className='mb-3'>
                      <RText
                        label="Hàm 1"
                        inputClass="form-control"
                        placeholder="Ví dụ: A:B:C:D:E,A,B"
                        inputId={"func_one"}
                        inputName="func_one"
                        value={curItem.func_one}
                        onChange={(e) => { this.handleInputChange(e) }}
                      />
                    </div>
                  </div>

                  <div className='col-md-1'></div>

                  <div className='col-md-2'>
                    <div className='mb-3'>
                      <RText
                        inputClass="form-control"
                        label="Hàm 2"
                        placeholder="Ví dụ: A:B:C:D:E,A,B"
                        inputId={"func_two"}
                        inputName="func_two"
                        value={curItem.func_two}
                        onChange={(e) => { this.handleInputChange(e) }}
                      />
                    </div>
                  </div>

                  <div className='col-md-1'></div>

                  <div className='col-md-2'>
                    <div className='mb-3'>
                      <RText
                        inputClass="form-control"
                        label="Hàm 3"
                        placeholder="Ví dụ: A:B:C:D:E,A,B"
                        inputId={"func_three"}
                        inputName="func_three"
                        value={curItem.func_three}
                        onChange={(e) => { this.handleInputChange(e) }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-md-3 col-sx-3">
                <div className='groupcheckbox text-end'>
                  <RCheckbox
                    label="Enable Ouput"
                    inputId="enable_output"
                    inputName="enable_output"
                    checked={(curItem.enable_output * 1 == 1) ? 1 : 0}
                    onChange={this.handleInputChange.bind(this)}
                  />
                </div>
              </div>

              <div className="col-lg-12 col-sm-12 col-md-12 col-sx-12">
                <div className='list-act'>
                  <ul>
                    <li className='title'>Số hàng áp dụng</li>
                    <li className='limit_row'>
                      <RText
                        inputClass="form-control text-center"
                        inputId={"row_apply"}
                        inputName="row_apply"
                        value={curItem.row_apply}
                        onChange={(e) => { this.handleInputChange(e) }}
                        disabled={curItem.row_percent != 0 ? true : false}
                      />
                    </li>

                    <li className='action'>
                      <div className={curItem.row_percent == 10 ? 'percent active' : 'percent'} onClick={(e) => { this.onClickChangePercent(10) }}>10%</div>
                    </li>
                    <li className='action'>
                      <div className={curItem.row_percent == 20 ? 'percent active' : 'percent'} onClick={(e) => { this.onClickChangePercent(20) }}>20%</div>
                    </li>
                    <li className='action'>
                      <div className={curItem.row_percent == 30 ? 'percent active' : 'percent'} onClick={(e) => { this.onClickChangePercent(30) }}>30%</div>
                    </li>
                    <li className='action'>
                      <div className={curItem.row_percent == 40 ? 'percent active' : 'percent'} onClick={(e) => { this.onClickChangePercent(40) }}>40%</div>
                    </li>
                    <li className='action'>
                      <div className={curItem.row_percent == 50 ? 'percent active' : 'percent'} onClick={(e) => { this.onClickChangePercent(50) }}><strong>50%</strong></div>
                    </li>
                    <li className='action'>
                      <div className={curItem.row_percent == 60 ? 'percent active' : 'percent'} onClick={(e) => { this.onClickChangePercent(60) }}>60%</div>
                    </li>
                    <li className='action'>
                      <div className={curItem.row_percent == 70 ? 'percent active' : 'percent'} onClick={(e) => { this.onClickChangePercent(70) }}>70%</div>
                    </li>
                    <li className='action'>
                      <div className={curItem.row_percent == 80 ? 'percent active' : 'percent'} onClick={(e) => { this.onClickChangePercent(80) }}><strong>80%</strong></div>
                    </li>
                    <li className='action'>
                      <div className={curItem.row_percent == 90 ? 'percent active' : 'percent'} onClick={(e) => { this.onClickChangePercent(90) }}><strong>90%</strong></div>
                    </li>
                    <li className='action'>
                      <div className={curItem.row_percent == 100 ? 'percent active' : 'percent'} onClick={(e) => { this.onClickChangePercent(100) }}>100%</div>
                    </li>

                    <li className='action'>
                      <div className='ac-button' onClick={this.applyFunction.bind(this, 0)}></div>
                    </li>

                  </ul>
                </div>
              </div>

              <div className="col-lg-12 col-sm-12 col-md-12 col-sx-12">
                <div className='list1'>
                  <div className='row'>
                    <div className='col-md-7'>
                      <div className='group1'>
                        <ul>
                          {inputBtn}
                          <li>
                            <div onClick={this.applyDefaultValue.bind(this)} className='def15'>
                              <RText
                                inputClass="form-control"
                                inputId={"default_add"}
                                inputName="default_add"
                                value={curItem.default_add}
                                onChange={(e) => { this.handleInputChange(e) }}
                                onKeyDown={(e) => { this.applyDefaultValue(e); }}
                              />
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className='group2'>
                        <ul>
                          {outputBtn}
                        </ul>
                      </div>
                    </div>
                    <div className='col-md-5'>
                      <div className='group3'>
                        <ul>
                          <li>
                            <div onClick={this.resetFunctionDefault.bind(this)} className='reset'>Reset</div>
                          </li>

                          <li>
                            <div onClick={this.changeData.bind(this)} className='rotate margin-right30'>Đảo</div>
                          </li>
                          <li>
                            Input
                          </li>
                          <li>
                            <RText
                              inputClass="form-control"
                              inputId={"input"}
                              inputName="input"
                              value={curItem.input}
                              onChange={(e) => { this.handleInputChange(e) }}
                              onKeyDown={(e) => { this.onKeyDownChange(e) }}
                            />
                          </li>
                          <li>
                            Output
                          </li>
                          <li>
                            <RText
                              inputClass="form-control"
                              inputId={"output"}
                              inputName="output"
                              value={curItem.output}
                              onChange={(e) => { this.handleInputChange(e) }}
                              onKeyDown={(e) => { this.onKeyDownChange(e) }}
                            />
                          </li>

                          <li>
                            <RText
                              inputClass="form-control"
                              inputId={"totalRow"}
                              inputName="totalRow"
                              value={curItem.totalRow}
                              onChange={(e) => { this.handleInputChange(e) }}
                              onKeyDown={(e) => { this.onKeyDownChange(e) }}
                            />
                          </li>

                        </ul>
                      </div>


                      {/* <div className='group4'>
                        <ul>
                          <li>
                            <RText
                              inputClass="form-control"
                              inputId={"totalRow"}
                              inputName="totalRow"
                              value={curItem.totalRow}
                              onChange={(e) => { this.handleInputChange(e) }}
                              onKeyDown={(e) => { this.onKeyDownChange(e) }}
                            />
                          </li>
                          <li>
                            <div className='top'>Top</div>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <div className="body">
            <HotTable
              className='regression-form'
              data={dataList}
              nestedHeaders={nestedHeaders}
              manualColumnResize={false}
              rowHeaders={true}
              // colHeaders={true}
              fixedColumnsStart={0}
              height={height - 300}
              // colWidths={(width - 80) / 49}
              // colWidths="60"
              colWidths={[40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40,
                40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40
              ]}
              rowHeights="24"
              // width="100%"
              contextMenu={true}
              minSpareRows={0}
              // stretchH="all"

              // scrollH = 'all'
              // scrollV = 'all'
              // stretchH = 'all' 

              // scrollH='auto'
              // scrollV='auto'
              // stretchH='hybrid'

              autoWrapCol={true}
              autoWrapRow={true}


              licenseKey="non-commercial-and-evaluation" // for non-commercial use only
              afterChange={(changes, source) => {
                this.changeHot(changes);
              }}

              afterPaste={(changes, source) => {
                this.changeHot(changes);
              }}

              afterScrollVertically={(index) => {
              }}

              afterSelection={(row, column, row2, column2, preventScrolling) => {
                preventScrolling.value = true;
              }}
              afterSelectionEnd={(row, column) => {
                // hot.scrollViewportTo(row, column, false, false)
              }}

              cells={function (row, col) {
                const cellProperties = {};
                for (var i = 0; i < parseInt(curItem.input); i++) {
                  if (col == i) {
                    cellProperties.renderer = backgroudRowRenderer;
                  }
                }

                for (var i = 0; i < parseInt(curItem.output); i++) {
                  if (col == (i + parseInt(curItem.input))) {
                    cellProperties.renderer = backgroudRowRendererOut1;
                  }

                  // if (curItem.enable_output == 1) {
                    if (col == (i + parseInt(curItem.input) + parseInt(curItem.output))) {
                      cellProperties.renderer = backgroudRowRendererOut2;
                    }

                    if (col == i + parseInt(curItem.input) + (parseInt(curItem.output) * 2)) {
                      cellProperties.renderer = backgroudRowRendererOut3;
                    }

                    if (col == i + parseInt(curItem.input) + (parseInt(curItem.output) * 3)) {
                      cellProperties.renderer = backgroudRowRendererOut4;
                    }

                  // }

                }

                if ( Libs.isArrayData(indexChange) && !Libs.isObjectEmpty(Libs.find(indexChange, 'id', col))) {
                  cellProperties.renderer = firstRowRenderer;
                }
                return cellProperties;

              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>

  )
}