import React from 'react';
import './login.scss';
import Logo from '../../../assets/images/logo-color.png';
import { RPassword, RText, RButton } from '../../../components/Controls';
import { NavLink } from 'react-router-dom';

export default function Login() {
    const { t } = this.props;
    var { curItem } = this.state;
    return (
        <div className="login">
            <div className="main-login">
                <div className="content">
                    <div className="main-form">
                        <div className="logo">
                            <NavLink to="/"><img src={Logo} alt="Solarmon"/></NavLink>
                        </div>
                        <h1>{t('login.login_title')}</h1>
                        <div className="form">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-4 col-12">
                                    <div className="mb-3">
                                        <RText label={t('login.email')}
                                            inputClass="form-control"
                                            inputId="email"
                                            inputName="email"
                                            value={curItem.email}
                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                            maxLength={100} />
                                    </div>
                                </div>

                                <div className="col-xl-12 col-lg-12 col-md-4 col-12">
                                    <div className="mb-3">
                                        <RPassword label={t('login.password')}
                                            inputClass="form-control"
                                            inputId={"password"}
                                            inputName={"password"}
                                            value={curItem.password}
                                            onChange={(e) => { this.handleInputChange(e); this.validateOne(e); }}
                                            maxLength={30} />
                                    </div>
                                </div>
                                

                                <div className="col-xl-12 col-lg-12 col-md-4 col-12">
                                    <RButton
                                        onClick={this.onSaveAction.bind(this)}
                                        className="btn btn-save"
                                        text={t('login.save')}
                                        title={t('login.save')} />
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>

    )
}