import React from 'react';
import { RText, RButton } from '../../../../components/Controls';
import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react';
import MetaTags from 'react-meta-tags';
import { textRenderer } from 'handsontable/renderers';
registerAllModules();



export default function () {
  var { dataList, curItem, dataCounts } = this.state;
  var width = document.body.clientWidth;
  var height = document.body.clientHeight;
  var nestedHeaders = [
    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
      'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ',
      'BA', 'BB', 'BC', 'BD'],
    [
      dataCounts.a,
      dataCounts.b,
      dataCounts.c,
      dataCounts.d,
      dataCounts.e,
      dataCounts.f,
      dataCounts.g,
      dataCounts.h,
      dataCounts.i,
      dataCounts.j,
      dataCounts.k,
      dataCounts.l,
      dataCounts.m,
      dataCounts.n,
      dataCounts.o,
      dataCounts.p,
      dataCounts.q,
      dataCounts.r,
      dataCounts.s,
      dataCounts.t,
      dataCounts.u,
      dataCounts.v,
      dataCounts.w,
      dataCounts.x,
      dataCounts.y,
      dataCounts.z,
      dataCounts.aa,
      dataCounts.ab,
      dataCounts.ac,
      dataCounts.ad,
      dataCounts.ae,
      dataCounts.af,
      dataCounts.ag,
      dataCounts.ah,
      dataCounts.ai,
      dataCounts.aj,
      dataCounts.ak,
      dataCounts.al,
      dataCounts.am,
      dataCounts.an,
      dataCounts.ao,
      dataCounts.ap,
      dataCounts.aq,
      dataCounts.ar,
      dataCounts.as,
      dataCounts.at,
      dataCounts.au,
      dataCounts.av,
      dataCounts.aw,
      dataCounts.ax,
      dataCounts.ay,
      dataCounts.az,
      dataCounts.ba,
      dataCounts.bb,
      dataCounts.bc,
      dataCounts.bd
    ]

  ];

  function group1(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = '#4f71be';
  }
  function group2(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #f19e70';
  }

  function group3(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #b7c5e4';
  }

  function group4(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #f5c242';
  }

  function group5(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #9fce63';
  }


  function group6(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #fbe7a3';
  }
  function group7(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #de8344';
  }
  function group8(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #ee8383';
  }

  function group9(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' red';
  }

  function group10(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #f5c242';
  }

  function group11(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #9fce63';
  }

  function group12(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #ffff54';
  }

  function group13(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #4fadea';
  }

  function group14(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #ffff54';
  }

  function group15(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #f5c242';
  }

  function group16(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);
    td.style.background = ' #de8344';
  }


  return (
    <React.Fragment>
      <MetaTags>
        <title>Dữ liệu Likert 5</title>
      </MetaTags>

      <div className="form-spss" >
        <div className="main">
          <section className="content-header">
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-md-6 col-sx-12">
                <div className='act text-start'>
                  <ul>
                    <li>
                      <RButton className="btn btn-danger btn-add margin-left"
                        onClick={this.copyATToA.bind(this)}
                        title="Lấy dữ liệu"
                        text="Lấy dữ liệu"
                      />
                    </li>

                    <li>
                      <RButton className="btn btn-danger btn-add btn-change"
                        onClick={this.copyATToAs.bind(this)}
                        title="Đổi"
                        text="Đổi"
                      />
                    </li>

                    <li>
                      <RButton className="btn btn-danger btn-add"
                        onClick={this.copyAJToA.bind(this)}
                        title="Lấy dữ liệu nghịch"
                        text="Lấy dữ liệu nghịch"
                      />
                    </li>

                    <li>
                      <RButton className="btn btn-danger btn-add margin-left"
                        onClick={this.changeData.bind(this)}
                        title="Đảo"
                        text="Đảo"
                      />
                    </li>

                  </ul>
                </div>
              </div>

              <div className="col-lg-6 col-sm-6 col-md-6 col-sx-12">
                <div className='act'>
                  <ul>
                    <li>
                      <RText
                        inputClass="form-control"
                        inputId={"totalRow"}
                        inputName="totalRow"
                        value={curItem.totalRow}
                        onChange={(e) => { this.handleInputRenderRowChange(e) }}
                        onBlur={(e) => { this.handleInputChangeRow(e) }}
                      />
                    </li>


                    {/* <li>
                      <RButton className="btn btn-danger btn-add"
                        onClick={this.copyATToAs.bind(this)}
                        title="TOP"
                        text="TOP"
                        
                      />
                    </li> */}
                  </ul>
                </div>

              </div>


            </div>
          </section>


          <div className="body">
            <div className='row'>
              <div className='col-md-12'>
                <HotTable
                  data={dataList}
                  nestedHeaders={nestedHeaders}

                  manualColumnResize={true}
                  rowHeaders={true}
                  // colHeaders={true}
                  fixedColumnsStart={0}
                  height={height - 140}
                  // colWidths={(width - 80) / 56}
                  colWidths= {38}
                  rowHeights="24"
                  width="100%"
                  contextMenu={true}
                  minSpareRows={0}
                  // stretchH="all"

                  // scrollH = 'all'
                  // scrollV = 'all'
                  // stretchH = 'all' 

                  scrollH='auto'
                  scrollV='auto'
                  stretchH='hybrid'
                  autoWrapCol={true}
                  autoWrapRow={true}


                  licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                  afterChange={(changes, source) => {
                    this.changeHot(changes);
                  }}

                  afterPaste={(changes, source) => {
                    this.changeHot(changes);
                  }}

                  afterScrollVertically={(index) => {
                  }}

                  afterSelection={(row, column, row2, column2, preventScrolling) => {
                    preventScrolling.value = true;
                  }}
                  afterSelectionEnd={(row, column) => {
                    // hot.scrollViewportTo(row, column, false, false)
                  }}

                  cells={function (row, col) {
                    const cellProperties = {};
                    // for (var i = 0; i < curItem.input; i++) {
                    //   if (col == i) {
                    //     cellProperties.renderer = backgroudRowRenderer;
                    //   }
                    // }
                    switch (col) {
                      case 0:
                        cellProperties.renderer = group1;
                        break;
                      case 1:
                      case 2:
                      case 3:
                      case 4:
                      case 5:
                        cellProperties.renderer = group2;
                        break;

                      case 6:
                      case 7:
                      case 8:
                      case 9:
                      case 10:
                        cellProperties.renderer = group3;
                        break

                      case 11:
                      case 12:
                      case 13:
                      case 14:
                      case 15:
                        cellProperties.renderer = group4;
                        break

                      case 16:
                      case 17:
                      case 18:
                      case 19:
                      case 20:
                      case 21:
                      case 22:
                      case 23:
                        cellProperties.renderer = group5;
                        break

                      case 24:
                      case 25:
                      case 26:
                      case 27:
                      case 28:
                      case 29:
                        cellProperties.renderer = group6;
                        break

                      case 30:
                      case 31:
                      case 32:
                      case 33:
                      case 34:
                        cellProperties.renderer = group7;
                        break

                      case 35:
                        cellProperties.renderer = group8;
                        break
                      case 37:
                        cellProperties.renderer = group9;
                        break
                      case 38:
                        cellProperties.renderer = group10;
                        break

                      case 40:
                        cellProperties.renderer = group11;
                        break
                      case 41:
                        cellProperties.renderer = group12;
                        break

                      case 43:
                      case 44:
                      case 45:
                      case 46:
                      case 47:
                      case 48:
                        cellProperties.renderer = group13;
                        break

                      case 49:
                        cellProperties.renderer = group14;
                        break

                      case 50:
                        cellProperties.renderer = group15;
                        break

                      case 51:
                        cellProperties.renderer = group16;
                        break
                    }
                    return cellProperties;

                  }}

                />
              </div>
            </div>


          </div>
        </div>
      </div>
    </React.Fragment>

  )
}