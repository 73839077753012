
// import React from 'react';
import Libs from './Libs';
var LoadDataDefault = {};

LoadDataDefault.LoadData = function (curItem, dataCounts, dataInput) {
	// var dataList = [], dataInput = [], dataCounts = [], self = this;
	// var { curItem, dataCounts, dataInput } = this.state;
	var dataList = [], data = [], fieldInput = [], dataFieldOutPut = [], dataFieldOutPutOne = [], dataFieldOutPutTwo = [];
	// create array data field output
	if (!Libs.isBlank(curItem.input)) {
		for (var i = 0; i < curItem.input; i++) {
			dataInput.push(null);
			switch (i) {
				case 0: fieldInput.push({ id: 'a' }); break;
				case 1: fieldInput.push({ id: 'b' }); break;
				case 2: fieldInput.push({ id: 'c' }); break;
				case 3: fieldInput.push({ id: 'd' }); break;
				case 4: fieldInput.push({ id: 'e' }); break;
				case 5: fieldInput.push({ id: 'f' }); break;
				case 6: fieldInput.push({ id: 'g' }); break;
				case 7: fieldInput.push({ id: 'h' }); break;
				case 8: fieldInput.push({ id: 'i' }); break;
				case 9: fieldInput.push({ id: 'j' }); break;
				case 10: fieldInput.push({ id: 'k' }); break;
				case 11: fieldInput.push({ id: 'l' }); break;
				case 12: fieldInput.push({ id: 'm' }); break;
				case 13: fieldInput.push({ id: 'n' }); break;
				case 14: fieldInput.push({ id: 'o' }); break;
				case 15: fieldInput.push({ id: 'p' }); break;
				case 16: fieldInput.push({ id: 'q' }); break;
				case 17: fieldInput.push({ id: 'r' }); break;
				case 18: fieldInput.push({ id: 's' }); break;
				case 19: fieldInput.push({ id: 't' }); break;
			}
		}
	}


	var countTmp = parseInt(curItem.input) + parseInt(curItem.output) - 1;
	for (var v = (parseInt(curItem.input) - 1); v < countTmp; v++) {
		switch (v) {
			case 0: dataFieldOutPut.push({ id: 'b' }); break;
			case 1: dataFieldOutPut.push({ id: 'c' }); break;
			case 2: dataFieldOutPut.push({ id: 'd' }); break;
			case 3: dataFieldOutPut.push({ id: 'e' }); break;
			case 4: dataFieldOutPut.push({ id: 'f' }); break;
			case 5: dataFieldOutPut.push({ id: 'g' }); break;
			case 6: dataFieldOutPut.push({ id: 'h' }); break;
			case 7: dataFieldOutPut.push({ id: 'i' }); break;
			case 8: dataFieldOutPut.push({ id: 'j' }); break;
			case 9: dataFieldOutPut.push({ id: 'k' }); break;
			case 10: dataFieldOutPut.push({ id: 'l' }); break;
			case 11: dataFieldOutPut.push({ id: 'm' }); break;
			case 12: dataFieldOutPut.push({ id: 'n' }); break;
			case 13: dataFieldOutPut.push({ id: 'o' }); break;
			case 14: dataFieldOutPut.push({ id: 'p' }); break;
			case 15: dataFieldOutPut.push({ id: 'q' }); break;
			case 16: dataFieldOutPut.push({ id: 'r' }); break;
			case 17: dataFieldOutPut.push({ id: 's' }); break;
			case 18: dataFieldOutPut.push({ id: 't' }); break;
			case 19: dataFieldOutPut.push({ id: 'u' }); break;
			case 20: dataFieldOutPut.push({ id: 'v' }); break;
			case 21: dataFieldOutPut.push({ id: 'w' }); break;
			case 22: dataFieldOutPut.push({ id: 'x' }); break;
			case 23: dataFieldOutPut.push({ id: 'y' }); break;
			case 24: dataFieldOutPut.push({ id: 'z' }); break;
			case 25: dataFieldOutPut.push({ id: 'aa' }); break;
			case 26: dataFieldOutPut.push({ id: 'ab' }); break;
			case 27: dataFieldOutPut.push({ id: 'ac' }); break;
			case 28: dataFieldOutPut.push({ id: 'ad' }); break;
			case 29: dataFieldOutPut.push({ id: 'ae' }); break;
			case 30: dataFieldOutPut.push({ id: 'af' }); break;
			case 31: dataFieldOutPut.push({ id: 'ag' }); break;
			case 32: dataFieldOutPut.push({ id: 'ah' }); break;
			case 33: dataFieldOutPut.push({ id: 'ai' }); break;
			case 34: dataFieldOutPut.push({ id: 'aj' }); break;
			case 35: dataFieldOutPut.push({ id: 'ak' }); break;
			case 36: dataFieldOutPut.push({ id: 'al' }); break;
			case 37: dataFieldOutPut.push({ id: 'am' }); break;
			case 38: dataFieldOutPut.push({ id: 'an' }); break;
			case 39: dataFieldOutPut.push({ id: 'ao' }); break;
			case 40: dataFieldOutPut.push({ id: 'ap' }); break;

		}
	}

	var countStartOne = parseInt(curItem.input) + (parseInt(curItem.output) * 2) - 1;
	for (var t = ((parseInt(curItem.input) + parseInt(curItem.output)) - 1); t < countStartOne; t++) {
		switch (t) {
			case 0: dataFieldOutPutOne.push({ id: 'b' }); break;
			case 1: dataFieldOutPutOne.push({ id: 'c' }); break;
			case 2: dataFieldOutPutOne.push({ id: 'd' }); break;
			case 3: dataFieldOutPutOne.push({ id: 'e' }); break;
			case 4: dataFieldOutPutOne.push({ id: 'f' }); break;
			case 5: dataFieldOutPutOne.push({ id: 'g' }); break;
			case 6: dataFieldOutPutOne.push({ id: 'h' }); break;
			case 7: dataFieldOutPutOne.push({ id: 'i' }); break;
			case 8: dataFieldOutPutOne.push({ id: 'j' }); break;
			case 9: dataFieldOutPutOne.push({ id: 'k' }); break;
			case 10: dataFieldOutPutOne.push({ id: 'l' }); break;
			case 11: dataFieldOutPutOne.push({ id: 'm' }); break;
			case 12: dataFieldOutPutOne.push({ id: 'n' }); break;
			case 13: dataFieldOutPutOne.push({ id: 'o' }); break;
			case 14: dataFieldOutPutOne.push({ id: 'p' }); break;
			case 15: dataFieldOutPutOne.push({ id: 'q' }); break;
			case 16: dataFieldOutPutOne.push({ id: 'r' }); break;
			case 17: dataFieldOutPutOne.push({ id: 's' }); break;
			case 18: dataFieldOutPutOne.push({ id: 't' }); break;
			case 19: dataFieldOutPutOne.push({ id: 'u' }); break;
			case 20: dataFieldOutPutOne.push({ id: 'v' }); break;
			case 21: dataFieldOutPutOne.push({ id: 'w' }); break;
			case 22: dataFieldOutPutOne.push({ id: 'x' }); break;
			case 23: dataFieldOutPutOne.push({ id: 'y' }); break;
			case 24: dataFieldOutPutOne.push({ id: 'z' }); break;
			case 25: dataFieldOutPutOne.push({ id: 'aa' }); break;
			case 26: dataFieldOutPutOne.push({ id: 'ab' }); break;
			case 27: dataFieldOutPutOne.push({ id: 'ac' }); break;
			case 28: dataFieldOutPutOne.push({ id: 'ad' }); break;
			case 29: dataFieldOutPutOne.push({ id: 'ae' }); break;
			case 30: dataFieldOutPutOne.push({ id: 'af' }); break;
			case 31: dataFieldOutPutOne.push({ id: 'ag' }); break;
			case 32: dataFieldOutPutOne.push({ id: 'ah' }); break;
			case 33: dataFieldOutPutOne.push({ id: 'ai' }); break;
			case 34: dataFieldOutPutOne.push({ id: 'aj' }); break;
			case 35: dataFieldOutPutOne.push({ id: 'ak' }); break;
			case 36: dataFieldOutPutOne.push({ id: 'al' }); break;
			case 37: dataFieldOutPutOne.push({ id: 'am' }); break;
			case 38: dataFieldOutPutOne.push({ id: 'an' }); break;
			case 39: dataFieldOutPutOne.push({ id: 'ao' }); break;
			case 40: dataFieldOutPutOne.push({ id: 'ap' }); break;
			case 41: dataFieldOutPutOne.push({ id: 'aq' }); break;
			case 42: dataFieldOutPutOne.push({ id: 'ar' }); break;
			case 43: dataFieldOutPutOne.push({ id: 'as' }); break;
			case 44: dataFieldOutPutOne.push({ id: 'at' }); break;
			case 45: dataFieldOutPutOne.push({ id: 'au' }); break;
			case 46: dataFieldOutPutOne.push({ id: 'av' }); break;
			case 47: dataFieldOutPutOne.push({ id: 'aw' }); break;
			case 48: dataFieldOutPutOne.push({ id: 'ax' }); break;
			case 49: dataFieldOutPutOne.push({ id: 'ay' }); break;
			case 50: dataFieldOutPutOne.push({ id: 'az' }); break;
			case 51: dataFieldOutPutOne.push({ id: 'ba' }); break;
			case 52: dataFieldOutPutOne.push({ id: 'bb' }); break;
			case 53: dataFieldOutPutOne.push({ id: 'bc' }); break;
			case 54: dataFieldOutPutOne.push({ id: 'bd' }); break;
			case 55: dataFieldOutPutOne.push({ id: 'be' }); break;
			case 56: dataFieldOutPutOne.push({ id: 'bf' }); break;
			case 57: dataFieldOutPutOne.push({ id: 'bg' }); break;
			case 58: dataFieldOutPutOne.push({ id: 'bh' }); break;
			case 59: dataFieldOutPutOne.push({ id: 'bi' }); break;
			case 60: dataFieldOutPutOne.push({ id: 'bj' }); break;
			case 61: dataFieldOutPutOne.push({ id: 'bk' }); break;
			case 62: dataFieldOutPutOne.push({ id: 'bl' }); break;
			case 63: dataFieldOutPutOne.push({ id: 'bm' }); break;
			case 64: dataFieldOutPutOne.push({ id: 'bn' }); break;
			case 65: dataFieldOutPutOne.push({ id: 'bo' }); break;
			case 66: dataFieldOutPutOne.push({ id: 'bp' }); break;
			case 67: dataFieldOutPutOne.push({ id: 'bq' }); break;
			case 68: dataFieldOutPutOne.push({ id: 'br' }); break;
			case 69: dataFieldOutPutOne.push({ id: 'bs' }); break;
			case 70: dataFieldOutPutOne.push({ id: 'bt' }); break;
			case 71: dataFieldOutPutOne.push({ id: 'bu' }); break;
			case 72: dataFieldOutPutOne.push({ id: 'bv' }); break;
			case 73: dataFieldOutPutOne.push({ id: 'bw' }); break;
			case 74: dataFieldOutPutOne.push({ id: 'bx' }); break;
			case 75: dataFieldOutPutOne.push({ id: 'by' }); break;
			case 76: dataFieldOutPutOne.push({ id: 'bz' }); break;
			case 77: dataFieldOutPutOne.push({ id: 'ca' }); break;
			case 78: dataFieldOutPutOne.push({ id: 'cb' }); break;
			case 79: dataFieldOutPutOne.push({ id: 'cc' }); break;
			case 80: dataFieldOutPutOne.push({ id: 'cd' }); break;


		}
	}

	var countStartTwo = parseInt(curItem.input) + (parseInt(curItem.output) * 3) - 1;

	for (var x = ((parseInt(curItem.input) + (parseInt(curItem.output) * 2)) - 1); x < countStartTwo; x++) {
		switch (x) {
			case 0: dataFieldOutPutTwo.push({ id: 'b' }); break;
			case 1: dataFieldOutPutTwo.push({ id: 'c' }); break;
			case 2: dataFieldOutPutTwo.push({ id: 'd' }); break;
			case 3: dataFieldOutPutTwo.push({ id: 'e' }); break;
			case 4: dataFieldOutPutTwo.push({ id: 'f' }); break;
			case 5: dataFieldOutPutTwo.push({ id: 'g' }); break;
			case 6: dataFieldOutPutTwo.push({ id: 'h' }); break;
			case 7: dataFieldOutPutTwo.push({ id: 'i' }); break;
			case 8: dataFieldOutPutTwo.push({ id: 'j' }); break;
			case 9: dataFieldOutPutTwo.push({ id: 'k' }); break;
			case 10: dataFieldOutPutTwo.push({ id: 'l' }); break;
			case 11: dataFieldOutPutTwo.push({ id: 'm' }); break;
			case 12: dataFieldOutPutTwo.push({ id: 'n' }); break;
			case 13: dataFieldOutPutTwo.push({ id: 'o' }); break;
			case 14: dataFieldOutPutTwo.push({ id: 'p' }); break;
			case 15: dataFieldOutPutTwo.push({ id: 'q' }); break;
			case 16: dataFieldOutPutTwo.push({ id: 'r' }); break;
			case 17: dataFieldOutPutTwo.push({ id: 's' }); break;
			case 18: dataFieldOutPutTwo.push({ id: 't' }); break;
			case 19: dataFieldOutPutTwo.push({ id: 'u' }); break;
			case 20: dataFieldOutPutTwo.push({ id: 'v' }); break;
			case 21: dataFieldOutPutTwo.push({ id: 'w' }); break;
			case 22: dataFieldOutPutTwo.push({ id: 'x' }); break;
			case 23: dataFieldOutPutTwo.push({ id: 'y' }); break;
			case 24: dataFieldOutPutTwo.push({ id: 'z' }); break;
			case 25: dataFieldOutPutTwo.push({ id: 'aa' }); break;
			case 26: dataFieldOutPutTwo.push({ id: 'ab' }); break;
			case 27: dataFieldOutPutTwo.push({ id: 'ac' }); break;
			case 28: dataFieldOutPutTwo.push({ id: 'ad' }); break;
			case 29: dataFieldOutPutTwo.push({ id: 'ae' }); break;
			case 30: dataFieldOutPutTwo.push({ id: 'af' }); break;
			case 31: dataFieldOutPutTwo.push({ id: 'ag' }); break;
			case 32: dataFieldOutPutTwo.push({ id: 'ah' }); break;
			case 33: dataFieldOutPutTwo.push({ id: 'ai' }); break;
			case 34: dataFieldOutPutTwo.push({ id: 'aj' }); break;
			case 35: dataFieldOutPutTwo.push({ id: 'ak' }); break;
			case 36: dataFieldOutPutTwo.push({ id: 'al' }); break;
			case 37: dataFieldOutPutTwo.push({ id: 'am' }); break;
			case 38: dataFieldOutPutTwo.push({ id: 'an' }); break;
			case 39: dataFieldOutPutTwo.push({ id: 'ao' }); break;
			case 40: dataFieldOutPutTwo.push({ id: 'ap' }); break;
			case 41: dataFieldOutPutTwo.push({ id: 'aq' }); break;
			case 42: dataFieldOutPutTwo.push({ id: 'ar' }); break;
			case 43: dataFieldOutPutTwo.push({ id: 'as' }); break;
			case 44: dataFieldOutPutTwo.push({ id: 'at' }); break;
			case 45: dataFieldOutPutTwo.push({ id: 'au' }); break;
			case 46: dataFieldOutPutTwo.push({ id: 'av' }); break;
			case 47: dataFieldOutPutTwo.push({ id: 'aw' }); break;
			case 48: dataFieldOutPutTwo.push({ id: 'ax' }); break;
			case 49: dataFieldOutPutTwo.push({ id: 'ay' }); break;
			case 50: dataFieldOutPutTwo.push({ id: 'az' }); break;
			case 51: dataFieldOutPutTwo.push({ id: 'ba' }); break;
			case 52: dataFieldOutPutTwo.push({ id: 'bb' }); break;
			case 53: dataFieldOutPutTwo.push({ id: 'bc' }); break;
			case 54: dataFieldOutPutTwo.push({ id: 'bd' }); break;
			case 55: dataFieldOutPutTwo.push({ id: 'be' }); break;
			case 56: dataFieldOutPutTwo.push({ id: 'bf' }); break;
			case 57: dataFieldOutPutTwo.push({ id: 'bg' }); break;
			case 58: dataFieldOutPutTwo.push({ id: 'bh' }); break;
			case 59: dataFieldOutPutTwo.push({ id: 'bi' }); break;
			case 60: dataFieldOutPutTwo.push({ id: 'bj' }); break;
			case 61: dataFieldOutPutTwo.push({ id: 'bk' }); break;
			case 62: dataFieldOutPutTwo.push({ id: 'bl' }); break;
			case 63: dataFieldOutPutTwo.push({ id: 'bm' }); break;
			case 64: dataFieldOutPutTwo.push({ id: 'bn' }); break;
			case 65: dataFieldOutPutTwo.push({ id: 'bo' }); break;
			case 66: dataFieldOutPutTwo.push({ id: 'bp' }); break;
			case 67: dataFieldOutPutTwo.push({ id: 'bq' }); break;
			case 68: dataFieldOutPutTwo.push({ id: 'br' }); break;
			case 69: dataFieldOutPutTwo.push({ id: 'bs' }); break;
			case 70: dataFieldOutPutTwo.push({ id: 'bt' }); break;
			case 71: dataFieldOutPutTwo.push({ id: 'bu' }); break;
			case 72: dataFieldOutPutTwo.push({ id: 'bv' }); break;
			case 73: dataFieldOutPutTwo.push({ id: 'bw' }); break;
			case 74: dataFieldOutPutTwo.push({ id: 'bx' }); break;
			case 75: dataFieldOutPutTwo.push({ id: 'by' }); break;
			case 76: dataFieldOutPutTwo.push({ id: 'bz' }); break;
			case 77: dataFieldOutPutTwo.push({ id: 'ca' }); break;
			case 78: dataFieldOutPutTwo.push({ id: 'cb' }); break;
			case 79: dataFieldOutPutTwo.push({ id: 'cc' }); break;
			case 80: dataFieldOutPutTwo.push({ id: 'cd' }); break;
		}
	}


	if (!Libs.isBlank(curItem.totalRow)) {
		for (var i = 0; i < curItem.totalRow; i++) {
			var item = {
				a: null,
				b: null,
				c: null,
				d: null,
				e: null,
				f: null,
				g: null,
				h: null,
				i: null,
				j: null,
				k: null,
				l: null,
				m: null,
				n: null,
				o: null,
				p: null,
				q: null,
				r: null,
				s: null,
				t: null,
				u: null,
				v: null,
				w: null,
				x: null,
				y: null,
				z: null,
				aa: null,
				ab: null,
				ac: null,
				ad: null,
				ae: null,
				af: null,
				ag: null,
				ah: null,
				ai: null,
				aj: null,
				ak: null,
				al: null,
				am: null,
				an: null,
				ao: null,
				ap: null,
				aq: null,
				ar: null,
				as: null,
				at: null,
				au: null,
				av: null,
				aw: null,
				ax: null,
				ay: null,
				az: null,
				ba: null,
				bb: null,
				bc: null,
				bd: null,
				be: null,
				bf: null,
				bg: null,
				bh: null,
				bi: null,
				bj: null,
				bk: null,
				bl: null,
				bm: null,
				bn: null,
				bo: null,
				bp: null,
				bq: null,
				br: null,
				bs: null,
				bt: null,
				bu: null,
				bv: null,
				bw: null,
				bx: null,
				by: null,
				bz: null,
				ca: null,
				cb: null,
				cc: null
			};


			// item.a = Libs.getRandomFloat(2.02, 4.04, 2).toFixed(2); //dataDefault[0];
			// item.b = Libs.getRandomFloat(2.02, 4.04, 2).toFixed(2); //dataDefault[1];
			// item.c = Libs.getRandomFloat(2.02, 4.04, 2).toFixed(2); //dataDefault[2];
			// item.d = Libs.getRandomFloat(2.02, 4.04, 2).toFixed(2); //dataDefault[3];
			// item.e = Libs.getRandomFloat(2.02, 4.04, 2).toFixed(2); //dataDefault[4];
			
			


			// item.f = Libs.getRandomFloat(2.02, 4.04, 2).toFixed(2); //dataDefault[4];
			// item.g = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];
			// item.h = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];
			// item.i = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];
			// item.j = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];
			// item.k = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];
			// item.l = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];

			// item.m = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];
			// item.n = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];
			// item.o = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];
			// item.p = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];
			// item.q = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];
			// item.r = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];
			// item.s = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];
			// item.t = Libs.getRandomFloat(2.02, 4.04, 2); //dataDefault[4];

			// set data output
			// =CHOOSE(RANDBETWEEN(1;3);INT(AVERAGE($A1:$E1));ROUND(AVERAGE($A1:$E1);0);ROUND(MEDIAN($A1:$E1);0))
			var total = null, arrMedian = [], median = null;
			// var total = item.a + item.b + item.c + item.d + item.e;
			// var median = Math.round(Libs.medianOfArray([item.a, item.b, item.c, item.d, item.e]));

			var totalOutPut2 = 0, medianOutPut2 = 0, arrMedianOutPut2 = [];
			var totalOutPut3 = 0, medianOutPut3 = 0, arrMedianOutPut3 = [];
			// var totalOutPut2 = (item.a + 0.8) + (item.b + 0.8) + (item.c + 0.8) + (item.d + 0.8) + (item.e + 0.8);
			// var medianOutPut2 = Math.round(Libs.medianOfArray([(item.a + 0.8), (item.b + 0.8), (item.c + 0.8), (item.d + 0.8), (item.e + 0.8)]));

			// var totalOutPut3 = (item.a - 0.9) + (item.b - 0.9) + (item.c - 0.9) + (item.d - 0.9) + (item.e - 0.9);
			// var medianOutPut3 = Math.round(Libs.medianOfArray([(item.a - 0.9), (item.b - 0.9), (item.c - 0.9), (item.d - 0.9), (item.e - 0.9)]));
			fieldInput.map((v, index) => {
				total = total + Number(item[v.id]);
				arrMedian.push(Number(item[v.id]));

				totalOutPut2 = totalOutPut2 + (Number(item[v.id]) + 0.8);
				arrMedianOutPut2.push((Number(item[v.id]) + 0.8));

				totalOutPut3 = totalOutPut3 + (Number(item[v.id]) - 0.9);
				arrMedianOutPut3.push((Number(item[v.id]) - 0.9));

			});
			
			medianOutPut2 = Math.round(Libs.medianOfArray(arrMedianOutPut2));
			medianOutPut3 = Math.round(Libs.medianOfArray(arrMedianOutPut3));

			median = Math.round(Libs.medianOfArray(arrMedian));
			if(total > 0){
				dataFieldOutPut.map((v, index) => {
					item[v.id] = (Libs.randomNumber(Math.floor(total / parseInt(curItem.input)) + "" + Math.round(total / parseInt(curItem.input)) + "" + median, 1));
					if(Number(item[v.id]) < 1) { item[v.id] = 1;}
					if(Number(item[v.id]) > 5) { item[v.id] = 5;}
				});

				if(curItem.enable_output == 1){
					dataFieldOutPutOne.map((v, index) => {
						item[v.id] = Libs.randomNumber(Math.floor(totalOutPut2 / parseInt(curItem.input)) + "" + Math.round(totalOutPut2 / parseInt(curItem.input)) + "" + medianOutPut2, 1);
						if(Number(item[v.id]) < 1) { item[v.id] = 1;}
						if(Number(item[v.id]) > 5) { item[v.id] = 5;}
					
					});
	
					dataFieldOutPutTwo.map((v, index) => {
						item[v.id] = Libs.randomNumber(Math.floor(totalOutPut3 / parseInt(curItem.input)) + "" + Math.round(totalOutPut3 / parseInt(curItem.input)) + "" + medianOutPut3, 1);
						if(Number(item[v.id]) < 1) { item[v.id] = 1;}
						if(Number(item[v.id]) > 5) { item[v.id] = 5;}
					});
				}
				
			}
			


			dataList.push(item);
		}
	}

	data[0] = dataList;
	data[1] = dataCounts;
	data[2] = dataInput;
	return data;
}


export default LoadDataDefault;


