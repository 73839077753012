import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
class RowItem extends Component {
    constructor(props) {
        super(props);
    }

    /**
   * @description Item click event
   * @author long.pham 27-07-2019
   */
    onItemClickView = () => {
        if (!this.props.onItemClickView || typeof this.props.onItemClickView !== 'function') return;
        this.props.onItemClickView(this.props.index);
    }

    /**
     * @description Item click event
     * @author long.pham 27-07-2019
     */
    onItemClick = () => {
        if (!this.props.onItemClick || typeof this.props.onItemClick !== 'function') return;
        this.props.onItemClick(this.props.index);
    }

    /**
     * @description Item click event delete
     * @author long.pham 27-07-2019
     */
    onItemClickDelete = () => {
        if (!this.props.onItemClickDelete || typeof this.props.onItemClickDelete !== 'function') return;
        this.props.onItemClickDelete(this.props.index);
    }




    render() {
        var { t } = this.props;
        var item = this.props.dataItem;
        return (
            <div className={item.status == 0 ? "body-item is_delete" : "body-item"} key={item.id}>
                <ReactTooltip />
                <div className="body-col width30"><p>{item.name_form}</p></div>
                <div className="body-col width60"><p>{item.url_form}</p></div>
                <div className="body-col width10">
                    <div className="function">
                        <ul>
                            <li><a onClick={this.onItemClickView.bind(this)} data-tip="Run" ><var><img style={{ width: "20p", position: "relative", top: "-1px", display: "inline-block" }} src='./play.png' /></var></a></li>
                            <li><a onClick={this.onItemClick.bind(this)} data-tip={t('common.edit')} ><var className="icon-pencil-square-o"></var></a></li>
                            <li><a onClick={this.onItemClickDelete.bind(this)} data-tip={t('common.delete')} ><var className="icon-trash-o"></var></a></li>
                        </ul>
                    </div>

                </div>
            </div>
        );
    }
}
export default RowItem;
