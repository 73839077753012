import BaseComponent from '../../../BaseComponent';
import FileSPSSJsx from './FileSPSS.jsx';
import { withTranslation } from 'react-i18next';
import './FileSPSS.scss';
import Libs from '../../../../utils/Libs';
import $ from 'jquery';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

class FileSPSS extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.jsxTemplate = FileSPSSJsx;
        this.state = {
            curItem: {
                totalRow: 1215
            },
            dataList: [],
            dataCounts: {
                a: null,
                b: null,
                c: null,
                d: null,
                e: null,
                f: null,
                g: null,
                h: null,
                i: null,
                j: null,
                k: null,
                l: null,
                m: null,
                n: null,
                o: null,
                p: null,
                q: null,
                r: null,
                s: null,
                t: null,
                u: null,
                v: null,
                w: null,
                x: null,
                y: null,
                z: null,
                aa: null,
                ab: null,
                ac: null,
                ad: null,
                ae: null,
                af: null,
                ag: null,
                ah: null,
                ai: null,
                aj: null,
                ak: null,
                al: null,
                am: null,
                an: null,
                ao: null,
                ap: null,
                aq: null,
                ar: null,
                as: null,
                at: null,
                au: null,
                av: null,
                aw: null,
                ax: null,
                ay: null,
                az: null,
                ba: null,
                bb: null,
                bc: null,
                bd: null
            }
        };
    }

    componentDidMount() {
        this.loadDefaultData();
    }

    copyPasteDataA() {

    }

    handleInputChangeRow(event) {
        this.loadDefaultData();
    }

    handleInputRenderRowChange(event) {
        let target = event.target;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        var numericExpression = /^[0-9\b]+$/;
        if (value == "" || numericExpression.test(value)) {
            var { curItem } = this.state;
            curItem.totalRow = value;
            this.setState({
                curItem: curItem
            })
        }


    };
    handleInputChange = (event) => {
        this.reRenderData();
    }

    changeHot(data) {
        if (Libs.isArrayData(data)) {
            this.reRenderData();
        }

    }
    loadDefaultData() {
        var dataList = [];
        var { curItem, dataCounts } = this.state;
        var dataAzDefault = [
            2,3,2,3,4,2,4,4,4,3,3,3,2,2,1,3,2,3,1,4,3,4,1,5,2,4,3,3,3,3,3,1,3,2,2,2,3,3,3,3,3,3,4,2,3,4,3,4,3,4,3,3,2,3,3,3,3,2,
            2,3,3,4,4,4,2,3,1,2,4,3,3,3,3,2,3,2,4,4,2,2,2,3,3,2,3,4,3,3,3,2,2,2,4,4,5,3,3,3,4,5,3,3,1,2,3,2,4,2,4,3,3,3,2,4,3,3,
            4,3,3,2,3,3,3,2,4,1,3,3,2,2,3,2,3,2,4,2,4,3,1,3,3,4,4,3,5,3,2,4,3,3,2,3,2,3,2,2,3,3,3,2,3,3,4,2,4,5,4,3,1,4,2,4,3,3,
            3,4,3,4,3,2,3,2,3,3,3,2,3,2,5,4,3,4,3,3,4,2,3,3,5,3,2,4,3,5,3,3,3,4,3,3,2,2,3,3,3,3,4,4,2,3,3,5,3,1,4,4,3,4,2,4,4,2,
            2,1,3,3,2,3,3,3,5,3,4,4,4,4,3,3,3,4,1,4,2,3,3,1,2,3,1,3,2,5,2,5,2,3,2,4,3,2,5,3,2,3,4,5,3,3,4,3,3,3,3,3,2,3,3,2,4,3,
            4,3,2,4,5,5,3,4,3,3,2,3,2,2,3,4,1,3,3,3,1,4,1,3,2,2,3,5,3,2,3,2,3,3,4,3,3,3,3,2,3,3,2,3,3,3,4,5,3,4,2,2,5,3,2,3,3,3,
            3,4,2,4,2,1,3,3,4,3,3,5,3,2,3,4,2,2,3,4,4,3,3,4,3,3,5,3,2,2,3,5,4,4,3,4,4,2,4,4,5,4,3,3,3,3,3,3,2,3,4,1,3,3,3,2,4,2,
            3,2,2,2,4,3,2,2,3,5,3,4,2,2,3,2,4,2,3,3,3,2,3,3,3,2,2,4,5,3,2,3,3,2,2,4,3,5,4,4,3,4,3,2,3,2,2,5,4,3,3,2,3,5,2,5,2,3,
            2,1,4,4,3,5,4,4,4,3,2,4,2,2,3,2,3,3,2,3,3,2,4,1,3,2,2,1,3,1,3,2,2,2,4,2,1,3,3,4,2,2,4,3,3,2,5,2,1,4,3,3,2,2,3,2,3,3,
            3,4,5,4,4,3,3,3,2,3,2,4,3,5,4,3,4,4,4,4,2,3,3,2,3,1,3,2,4,3,1,2,4,5,3,4,3,4,1,3,3,4,5,5,2,3,3,4,3,5,2,4,3,3,5,2,3,3,
            3,3,4,2,3,4,2,3,1,3,3,3,3,3,3,4,2,3,3,4,2,2,3,2,2,2,2,3,1,3,4,3,3,4,2,3,3,4,2,2,2,2,2,2,3,4,3,2,1,3,3,3,3,3,4,4,3,5,
            2,1,4,5,3,4,5,2,4,3,2,3,3,3,3,4,4,1,5,4,5,2,1,3,4,3,2,2,2,3,3,3,3,3,3,3,3,5,3,4,2,2,4,2,2,3,4,3,3,4,3,3,2,4,2,3,3,5,
            2,3,3,3,3,3,4,3,3,4,3,3,4,3,2,2,2,3,3,5,4,3,3,3,3,2,4,3,3,4,2,4,4,2,3,5,2,5,3,4,5,2,5,2,3,4,3,3,5,3,3,4,3,3,3,4,4,3,
            3,4,4,2,4,2,2,2,1,3,4,2,2,4,3,4,1,3,3,3,4,4,2,4,2,3,2,4,3,3,3,2,4,2,3,2,3,3,3,3,4,3,3,3,1,3,3,5,2,4,2,3,2,3,1,3,2,4,
            2,3,4,3,4,1,3,3,3,2,2,4,3,3,5,2,4,4,4,2,3,2,3,3,3,2,3,3,4,3,4,4,3,3,3,3,2,5,3,3,3,2,3,2,4,3,2,3,5,4,5,2,3,4,4,4,2,3,
            4,2,1,2,3,5,5,2,3,5,5,4,3,4,4,3,5,3,4,3,3,4,3,3,4,4,4,2,4,3,4,3,3,4,3,3,3,3,2,3,3,2,3,1,3,2,4,3,3,3,4,1,2,1,3,2,4,3,
            3,3,4,4,3,5,2,3,4,4,4,3,3,3,3,3,3,2,3,4,3,3,3,5,1,5,4,5,1,2,3,3,3,3,3,4,3,3,3,4,4,3,3,4,3,2,4,4,3,3,3,3,2,3,3,3,5,1,
            3,3,2,3,4,3,2,4,3,4,2,3,4,4,3,3,3,2,1,4,5,3,3,1,5,3,3,2,3,3,3,2,4,3,5,3,4,3,4,4,4,4,3,5,3,4,3,1,4,2,3,4,2,3,5,5,5,2,
            3,4,4,3,2,3,3,2,1,2,4,3,5,3,3,5,5,3,4,4,5,4,3,4,4,3,3,4,4,3,3,4,3,1,3,4,4,2,2,5,2,4,2,4,1,4,2,2,4,1,2,2,4,3,4,3,5,1,
            3,2,2,1,4,3,3,4,5,5,3,5,3,2,4,4,5,3,3,2,4,4,2,3,4,3,2,4,2,5,2,3,3,5,1,2,3,4,3,2,4,4,3,2,3,4,4,4,4,4,2,2,3,5,3,3,3,3,
            3,4,3,4,3,2,2,3,1,2,3,3,2,3,3,4,3,3,3,4,3,4,2,2,1,3,5,3,4,2,3,2,2,3,2,3,4,3,1,3,3,5,4,3,2,4,3,5,2,2,2,4,5,4,4
        ];
        var a = null;
        if (!Libs.isBlank(curItem.totalRow)) {
            for (var i = 0; i < curItem.totalRow; i++) {
                var item = {
                    a: null,
                    b: null,
                    c: null,
                    d: null,
                    e: null,
                    f: null,
                    g: null,
                    h: null,
                    i: null,
                    j: null,
                    k: null,
                    l: null,
                    m: null,
                    n: null,
                    o: null,
                    p: null,
                    q: null,
                    r: null,
                    s: null,
                    t: null,
                    u: null,
                    v: null,
                    w: null,
                    x: null,
                    y: null,
                    z: null,

                    aa: null,
                    ab: null,
                    ac: null,
                    ad: null,
                    ae: null,
                    af: null,
                    ag: null,
                    ah: null,
                    ai: null,
                    aj: null,
                    ak: null,
                    al: dataAzDefault[i],
                    am: null,
                    an: null,
                    ao: null,
                    ap: null,
                    aq: null,
                    ar: null,
                    as: null,
                    at: null,
                    au: null,
                    av: null,
                    aw: null,
                    ax: null,
                    ay: null,
                    az: null,
                    ba: i + 1,
                    bb: null,
                    bc: null,
                    bd: null
                };

                // ------------------------------------------- b -------------------------
                var b = null;
                if (item.a == 1) {
                    b = Libs.randomNumber('1121', 1);
                } else if (item.a == 2) {
                    b = Libs.randomNumber('11121', 1);
                } else if (item.a == 3) {
                    b = Libs.randomNumber('12232', 1);
                } else if (item.a == 4) {
                    b = Libs.randomNumber('232343', 1);
                } else if (item.a == 5) {
                    b = Libs.randomNumber('3344534', 1);
                }
                item.b = b;
                // ------------------------------------------- c -------------------------
                var c = null;
                if (item.a == 1) {
                    c = Libs.randomNumber('1121', 1);
                } else if (item.a == 2) {
                    c = Libs.randomNumber('11121', 1);
                } else if (item.a == 3) {
                    c = Libs.randomNumber('12232', 1);
                } else if (item.a == 4) {
                    c = Libs.randomNumber('232343', 1);
                } else if (item.a == 5) {
                    c = Libs.randomNumber('3344534', 1);
                }
                item.c = c;
                // ------------------------------------------- d -------------------------
                var d = null;
                if (item.a == 1) {
                    d = Libs.randomNumber('1121', 1);
                } else if (item.a == 2) {
                    d = Libs.randomNumber('11121', 1);
                } else if (item.a == 3) {
                    d = Libs.randomNumber('12232', 1);
                } else if (item.a == 4) {
                    d = Libs.randomNumber('232343', 1);
                } else if (item.a == 5) {
                    d = Libs.randomNumber('3344534', 1);
                }
                item.d = d;
                // ------------------------------------------- e -------------------------
                var e = null;
                if (item.a == 1) {
                    e = Libs.randomNumber('1121', 1);
                } else if (item.a == 2) {
                    e = Libs.randomNumber('11121', 1);
                } else if (item.a == 3) {
                    e = Libs.randomNumber('12232', 1);
                } else if (item.a == 4) {
                    e = Libs.randomNumber('232343', 1);
                } else if (item.a == 5) {
                    e = Libs.randomNumber('3344534', 1);
                }
                item.e = e;
                // ------------------------------------------- f -------------------------
                var f = null;
                if (item.a == 1) {
                    f = Libs.randomNumber('1121', 1);
                } else if (item.a == 2) {
                    f = Libs.randomNumber('11121', 1);
                } else if (item.a == 3) {
                    f = Libs.randomNumber('12232', 1);
                } else if (item.a == 4) {
                    f = Libs.randomNumber('232343', 1);
                } else if (item.a == 5) {
                    f = Libs.randomNumber('3344534', 1);
                }
                item.f = f;

                // =IF($A1 = 1; CHOOSE(RANDBETWEEN(1; 3); 1; 1; 2); IF($A1 = 2; RANDBETWEEN(1; 2); IF($A1 = 3; CHOOSE(RANDBETWEEN(1; 3); 2; 2; 3); IF($A1 = 4; CHOOSE(RANDBETWEEN(1; 4); 2; 3; 3; 4); CHOOSE(RANDBETWEEN(1; 5); 3; 3; 4; 4; 5)))))
                // ------------------------------------------- g -------------------------
                var g = null;
                if (item.a == 1) {
                    g = Libs.randomNumber('112', 1);
                } else if (item.a == 2) {
                    g = Libs.randomNumber('12', 1);
                } else if (item.a == 3) {
                    g = Libs.randomNumber('223', 1);
                } else if (item.a == 4) {
                    g = Libs.randomNumber('2334', 1);
                } else if (item.a == 5) {
                    g = Libs.randomNumber('33445', 1);
                }
                item.g = g;

                // ------------------------------------------- h -------------------------
                var h = null;
                if (item.a == 1) {
                    h = Libs.randomNumber('112', 1);
                } else if (item.a == 2) {
                    h = Libs.randomNumber('12', 1);
                } else if (item.a == 3) {
                    h = Libs.randomNumber('223', 1);
                } else if (item.a == 4) {
                    h = Libs.randomNumber('2334', 1);
                } else if (item.a == 5) {
                    h = Libs.randomNumber('33445', 1);
                }
                item.h = h;

                // ------------------------------------------- i -------------------------
                var irow = null;
                if (item.a == 1) {
                    irow = Libs.randomNumber('112', 1);
                } else if (item.a == 2) {
                    irow = Libs.randomNumber('12', 1);
                } else if (item.a == 3) {
                    irow = Libs.randomNumber('223', 1);
                } else if (item.a == 4) {
                    irow = Libs.randomNumber('2334', 1);
                } else if (item.a == 5) {
                    irow = Libs.randomNumber('33445', 1);
                }
                item.i = irow;

                // ------------------------------------------- j -------------------------
                var j = null;
                if (item.a == 1) {
                    j = Libs.randomNumber('112', 1);
                } else if (item.a == 2) {
                    j = Libs.randomNumber('12', 1);
                } else if (item.a == 3) {
                    j = Libs.randomNumber('223', 1);
                } else if (item.a == 4) {
                    j = Libs.randomNumber('2334', 1);
                } else if (item.a == 5) {
                    j = Libs.randomNumber('33445', 1);
                }
                item.j = j;

                // ------------------------------------------- k -------------------------
                var k = null;
                if (item.a == 1) {
                    k = Libs.randomNumber('112', 1);
                } else if (item.a == 2) {
                    k = Libs.randomNumber('12', 1);
                } else if (item.a == 3) {
                    k = Libs.randomNumber('223', 1);
                } else if (item.a == 4) {
                    k = Libs.randomNumber('2334', 1);
                } else if (item.a == 5) {
                    k = Libs.randomNumber('33445', 1);
                }
                item.k = k;

                // =IF($A1=1;CHOOSE(RANDBETWEEN(1;3);1;1;2);IF($A1=2;CHOOSE(RANDBETWEEN(1;5);1;2;2;3;3);IF($A1=3;RANDBETWEEN(2;3);IF($A1=4;RANDBETWEEN(3;4);RANDBETWEEN(3;5)))))
                // ------------------------------------------- l -------------------------
                var l = null;
                if (item.a == 1) {
                    l = Libs.randomNumber('112', 1);
                } else if (item.a == 2) {
                    l = Libs.randomNumber('12233', 1);
                } else if (item.a == 3) {
                    l = Libs.randomNumber('23', 1);
                } else if (item.a == 4) {
                    l = Libs.randomNumber('34', 1);
                } else if (item.a == 5) {
                    l = Libs.randomNumber('35', 1);
                }
                item.l = l;

                // ------------------------------------------- m -------------------------
                var m = null;
                if (item.a == 1) {
                    m = Libs.randomNumber('112', 1);
                } else if (item.a == 2) {
                    m = Libs.randomNumber('12233', 1);
                } else if (item.a == 3) {
                    m = Libs.randomNumber('23', 1);
                } else if (item.a == 4) {
                    m = Libs.randomNumber('34', 1);
                } else if (item.a == 5) {
                    m = Libs.randomNumber('35', 1);
                }
                item.m = m;

                // ------------------------------------------- n -------------------------
                var n = null;
                if (item.a == 1) {
                    n = Libs.randomNumber('112', 1);
                } else if (item.a == 2) {
                    n = Libs.randomNumber('12233', 1);
                } else if (item.a == 3) {
                    n = Libs.randomNumber('23', 1);
                } else if (item.a == 4) {
                    n = Libs.randomNumber('34', 1);
                } else if (item.a == 5) {
                    n = Libs.randomNumber('35', 1);
                }
                item.n = n;

                // ------------------------------------------- o -------------------------
                var o = null;
                if (item.a == 1) {
                    o = Libs.randomNumber('112', 1);
                } else if (item.a == 2) {
                    o = Libs.randomNumber('12233', 1);
                } else if (item.a == 3) {
                    o = Libs.randomNumber('23', 1);
                } else if (item.a == 4) {
                    o = Libs.randomNumber('34', 1);
                } else if (item.a == 5) {
                    o = Libs.randomNumber('35', 1);
                }
                item.o = o;

                // ------------------------------------------- p -------------------------
                var p = null;
                if (item.a == 1) {
                    p = Libs.randomNumber('112', 1);
                } else if (item.a == 2) {
                    p = Libs.randomNumber('12233', 1);
                } else if (item.a == 3) {
                    p = Libs.randomNumber('23', 1);
                } else if (item.a == 4) {
                    p = Libs.randomNumber('34', 1);
                } else if (item.a == 5) {
                    p = Libs.randomNumber('35', 1);
                }
                item.p = p;

                //=IF($A1=1;CHOOSE(RANDBETWEEN(1;3);1;2;2);IF($A1=2;RANDBETWEEN(2;3);IF($A1=3;RANDBETWEEN(3;4);IF($A1=4;RANDBETWEEN(3;4);RANDBETWEEN(4;5)))))
                // ------------------------------------------- q -------------------------
                var q = null;
                if (item.a == 1) {
                    q = Libs.randomNumber('122', 1);
                } else if (item.a == 2) {
                    q = Libs.randomNumber('23', 1);
                } else if (item.a == 3) {
                    q = Libs.randomNumber('34', 1);
                } else if (item.a == 4) {
                    q = Libs.randomNumber('34', 1);
                } else if (item.a == 5) {
                    q = Libs.randomNumber('45', 1);
                }
                item.q = q;

                // ------------------------------------------- r -------------------------
                var r = null;
                if (item.a == 1) {
                    r = Libs.randomNumber('122', 1);
                } else if (item.a == 2) {
                    r = Libs.randomNumber('23', 1);
                } else if (item.a == 3) {
                    r = Libs.randomNumber('34', 1);
                } else if (item.a == 4) {
                    r = Libs.randomNumber('34', 1);
                } else if (item.a == 5) {
                    r = Libs.randomNumber('45', 1);
                }
                item.r = r;


                // ------------------------------------------- s -------------------------
                var s = null;
                if (item.a == 1) {
                    s = Libs.randomNumber('122', 1);
                } else if (item.a == 2) {
                    s = Libs.randomNumber('23', 1);
                } else if (item.a == 3) {
                    s = Libs.randomNumber('34', 1);
                } else if (item.a == 4) {
                    s = Libs.randomNumber('34', 1);
                } else if (item.a == 5) {
                    s = Libs.randomNumber('45', 1);
                }
                item.s = s;

                // ------------------------------------------- t -------------------------
                var t = null;
                if (item.a == 1) {
                    t = Libs.randomNumber('122', 1);
                } else if (item.a == 2) {
                    t = Libs.randomNumber('23', 1);
                } else if (item.a == 3) {
                    t = Libs.randomNumber('34', 1);
                } else if (item.a == 4) {
                    t = Libs.randomNumber('34', 1);
                } else if (item.a == 5) {
                    t = Libs.randomNumber('45', 1);
                }
                item.t = t;

                // ------------------------------------------- u -------------------------
                var u = null;
                if (item.a == 1) {
                    u = Libs.randomNumber('122', 1);
                } else if (item.a == 2) {
                    u = Libs.randomNumber('23', 1);
                } else if (item.a == 3) {
                    u = Libs.randomNumber('34', 1);
                } else if (item.a == 4) {
                    u = Libs.randomNumber('34', 1);
                } else if (item.a == 5) {
                    u = Libs.randomNumber('45', 1);
                }
                item.u = u;

                // ------------------------------------------- v -------------------------
                var v = null;
                if (item.a == 1) {
                    v = Libs.randomNumber('122', 1);
                } else if (item.a == 2) {
                    v = Libs.randomNumber('23', 1);
                } else if (item.a == 3) {
                    v = Libs.randomNumber('34', 1);
                } else if (item.a == 4) {
                    v = Libs.randomNumber('34', 1);
                } else if (item.a == 5) {
                    v = Libs.randomNumber('45', 1);
                }
                item.v = v;

                // ------------------------------------------- w -------------------------
                var w = null;
                if (item.a == 1) {
                    w = Libs.randomNumber('122', 1);
                } else if (item.a == 2) {
                    w = Libs.randomNumber('23', 1);
                } else if (item.a == 3) {
                    w = Libs.randomNumber('34', 1);
                } else if (item.a == 4) {
                    w = Libs.randomNumber('34', 1);
                } else if (item.a == 5) {
                    w = Libs.randomNumber('45', 1);
                }
                item.w = w;

                // ------------------------------------------- x -------------------------
                var x = null;
                if (item.a == 1) {
                    x = Libs.randomNumber('122', 1);
                } else if (item.a == 2) {
                    x = Libs.randomNumber('23', 1);
                } else if (item.a == 3) {
                    x = Libs.randomNumber('34', 1);
                } else if (item.a == 4) {
                    x = Libs.randomNumber('34', 1);
                } else if (item.a == 5) {
                    x = Libs.randomNumber('45', 1);
                }
                item.x = x;

                //=IF($A1=1;CHOOSE(RANDBETWEEN(1;4);1;2;2;2);IF($A1=2;CHOOSE(RANDBETWEEN(1;4);2;3;3;4);IF($A1=3;CHOOSE(RANDBETWEEN(1;4);3;4;4;4);IF($A1=4;CHOOSE(RANDBETWEEN(1;3);4;4;5);CHOOSE(RANDBETWEEN(1;3);4;5;5)))))
                // ------------------------------------------- y -------------------------
                var y = null;
                if (item.a == 1) {
                    y = Libs.randomNumber('1222', 1);
                } else if (item.a == 2) {
                    y = Libs.randomNumber('3444', 1);
                } else if (item.a == 3) {
                    y = Libs.randomNumber('2334', 1);
                } else if (item.a == 4) {
                    y = Libs.randomNumber('445', 1);
                } else if (item.a == 5) {
                    y = Libs.randomNumber('455', 1);
                }
                item.y = y;

                // ------------------------------------------- z -------------------------
                var z = null;
                if (item.a == 1) {
                    z = Libs.randomNumber('1222', 1);
                } else if (item.a == 2) {
                    z = Libs.randomNumber('3444', 1);
                } else if (item.a == 3) {
                    z = Libs.randomNumber('2334', 1);
                } else if (item.a == 4) {
                    z = Libs.randomNumber('445', 1);
                } else if (item.a == 5) {
                    z = Libs.randomNumber('455', 1);
                }
                item.z = z;

                // ------------------------------------------- aa -------------------------
                var aa = null;
                if (item.a == 1) {
                    aa = Libs.randomNumber('1222', 1);
                } else if (item.a == 2) {
                    aa = Libs.randomNumber('3444', 1);
                } else if (item.a == 3) {
                    aa = Libs.randomNumber('2334', 1);
                } else if (item.a == 4) {
                    aa = Libs.randomNumber('445', 1);
                } else if (item.a == 5) {
                    aa = Libs.randomNumber('455', 1);
                }
                item.aa = aa;

                // ------------------------------------------- ab -------------------------
                var ab = null;
                if (item.a == 1) {
                    ab = Libs.randomNumber('1222', 1);
                } else if (item.a == 2) {
                    ab = Libs.randomNumber('3444', 1);
                } else if (item.a == 3) {
                    ab = Libs.randomNumber('2334', 1);
                } else if (item.a == 4) {
                    ab = Libs.randomNumber('445', 1);
                } else if (item.a == 5) {
                    ab = Libs.randomNumber('455', 1);
                }
                item.ab = ab;

                // ------------------------------------------- ac -------------------------
                var ac = null;
                if (item.a == 1) {
                    ac = Libs.randomNumber('1222', 1);
                } else if (item.a == 2) {
                    ac = Libs.randomNumber('3444', 1);
                } else if (item.a == 3) {
                    ac = Libs.randomNumber('2334', 1);
                } else if (item.a == 4) {
                    ac = Libs.randomNumber('445', 1);
                } else if (item.a == 5) {
                    ac = Libs.randomNumber('455', 1);
                }
                item.ac = ac;


                // ------------------------------------------- ad -------------------------
                var ad = null;
                if (item.a == 1) {
                    ad = Libs.randomNumber('1222', 1);
                } else if (item.a == 2) {
                    ad = Libs.randomNumber('3444', 1);
                } else if (item.a == 3) {
                    ad = Libs.randomNumber('2334', 1);
                } else if (item.a == 4) {
                    ad = Libs.randomNumber('445', 1);
                } else if (item.a == 5) {
                    ad = Libs.randomNumber('455', 1);
                }
                item.ad = ad;

                // =IF($A1=1;CHOOSE(RANDBETWEEN(1;5);1;2;2;3;3);IF($A1=2;CHOOSE(RANDBETWEEN(1;4);2;3;3;4);IF($A1=3;CHOOSE(RANDBETWEEN(1;5);3;4;4;5;5);IF($A1=4;CHOOSE(RANDBETWEEN(1;3);4;5;5);CHOOSE(RANDBETWEEN(1;4);4;5;5;5)))))
                // ------------------------------------------- ae -------------------------
                var ae = null;
                if (item.a == 1) {
                    ae = Libs.randomNumber('12233', 1);
                } else if (item.a == 2) {
                    ae = Libs.randomNumber('2334', 1);
                } else if (item.a == 3) {
                    ae = Libs.randomNumber('34455', 1);
                } else if (item.a == 4) {
                    ae = Libs.randomNumber('455', 1);
                } else if (item.a == 5) {
                    ae = Libs.randomNumber('4555', 1);
                }
                item.ae = ae;

                // ------------------------------------------- af -------------------------
                var af = null;
                if (item.a == 1) {
                    af = Libs.randomNumber('12233', 1);
                } else if (item.a == 2) {
                    af = Libs.randomNumber('2334', 1);
                } else if (item.a == 3) {
                    af = Libs.randomNumber('34455', 1);
                } else if (item.a == 4) {
                    af = Libs.randomNumber('455', 1);
                } else if (item.a == 5) {
                    af = Libs.randomNumber('4555', 1);
                }
                item.af = af;

                // ------------------------------------------- ag -------------------------
                var ag = null;
                if (item.a == 1) {
                    ag = Libs.randomNumber('12233', 1);
                } else if (item.a == 2) {
                    ag = Libs.randomNumber('2334', 1);
                } else if (item.a == 3) {
                    ag = Libs.randomNumber('34455', 1);
                } else if (item.a == 4) {
                    ag = Libs.randomNumber('455', 1);
                } else if (item.a == 5) {
                    ag = Libs.randomNumber('4555', 1);
                }
                item.ag = ag;

                // ------------------------------------------- ah -------------------------
                var ah = null;
                if (item.a == 1) {
                    ah = Libs.randomNumber('12233', 1);
                } else if (item.a == 2) {
                    ah = Libs.randomNumber('2334', 1);
                } else if (item.a == 3) {
                    ah = Libs.randomNumber('34455', 1);
                } else if (item.a == 4) {
                    ah = Libs.randomNumber('455', 1);
                } else if (item.a == 5) {
                    ah = Libs.randomNumber('4555', 1);
                }
                item.ah = ah;

                // ------------------------------------------- ai -------------------------
                var ai = null;
                if (item.a == 1) {
                    ai = Libs.randomNumber('12233', 1);
                } else if (item.a == 2) {
                    ai = Libs.randomNumber('2334', 1);
                } else if (item.a == 3) {
                    ai = Libs.randomNumber('34455', 1);
                } else if (item.a == 4) {
                    ai = Libs.randomNumber('455', 1);
                } else if (item.a == 5) {
                    ai = Libs.randomNumber('4555', 1);
                }
                item.ai = ai;

                // =ROUND(IF(5-G1+1>5;5;5-G1+1);0)
                // ------------------------------------------- aj -------------------------
                var aj = null;
                if ((5 - item.g) + 1 > 5) {
                    aj = null;
                } else {
                    aj = Math.round((5 - item.g) + 1);
                }
                item.aj = aj;

                // =ROUND(IF(5-H1+1>5;5;5-H1+1);0)
                // ------------------------------------------- ak -------------------------


                // ------------------------------------------- al tu nhap -------------------------
                // var ak = null;
                // if ((5 - item.h) + 1 > 5) {
                //     ak = null;
                // } else {
                //     ak = Math.round((5 - item.h) + 1);
                // }
                // item.ak = ak;

                // ------------------------------------------- am -------------------------
                // =ROUND(IF(5-J1+1>5;5;5-J1+1);0)
                var am = null;
                if (item.al == 1) {
                    am = Libs.randomNumber('122', 1);
                } else if (item.al == 2) {
                    am = Libs.randomNumber('12233', 1);
                } else if (item.al == 3) {
                    am = Libs.randomNumber('233443', 1);
                } else if (item.al == 4) {
                    am = Libs.randomNumber('3445', 1);
                } else if (item.al == 5) {
                    am = Libs.randomNumber('34555', 1);
                }
                item.am = am;


                // ------------------------------------------- an set null -------------------------
                // ------------------------------------------- ao tu nhap -------------------------

                // ------------------------------------------- ap -------------------------
                // =IF(AO1=2;CHOOSE(RANDBETWEEN(1;3);1;1;2);IF(AO1=4;CHOOSE(RANDBETWEEN(1;3);5;5;4);AO1))
                var aq = null;
                if (item.a == 2) {
                    aq = Libs.randomNumber('112', 1);
                } else if (item.a == 4) {
                    aq = Libs.randomNumber('554', 1);
                } else {
                    aq = item.a;
                }
                item.aq = aq;

                // ------------------------------------------- aq set null -------------------------
                // ------------------------------------------- ar tu nhap -------------------------
                // ------------------------------------------- as tu nhap -------------------------
                // ------------------------------------------- at tu nhap -------------------------
                // ------------------------------------------- au tu nhap -------------------------
                // ------------------------------------------- av tu nhap -------------------------
                // ------------------------------------------- aw tu nhap -------------------------

                // ------------------------------------------- ax -------------------------
                // =ROUND(AVERAGE(AR1:AW1);0)
                var ax = null;

                if (Libs.isNumber(item.ar) && Libs.isNumber(item.as) && Libs.isNumber(item.at) && Libs.isNumber(item.au) && Libs.isNumber(item.av) && Libs.isNumber(item.aw)) {
                    ax = Math.round((item.ar + item.as + item.at + item.au + item.av + item.aw) / 6);
                }

                item.ax = ax;

                // ------------------------------------------- ax tu nhap -------------------------
                // =INT(AVERAGE(AR1:AW1))
                var ay = null;
                if (Libs.isNumber(item.ar) && Libs.isNumber(item.as) && Libs.isNumber(item.at) && Libs.isNumber(item.au) && Libs.isNumber(item.av) && Libs.isNumber(item.aw)) {
                    ay = Math.floor((item.ar + item.as + item.at + item.au + item.av + item.aw) / 6);
                }
                item.ay = ay;


                // ------------------------------------------- ax tu nhap -------------------------
                // =CHOOSE(RANDBETWEEN(1;2);AX1;AY1)
                if (!Libs.isBlank(ax) && !Libs.isBlank(ay)) {
                    var az = Libs.randomNumber(ax + "" + ay, 1);
                }
                item.az = az;

                dataList.push(item);
                a = a + item.a;
            }
            // counts.a = (a / curItem.totalRow).toFixed(2);
        }

        this.setState({
            dataList: dataList,
            dataCounts: dataCounts
        })

    }

    /**
     * @description translate change
     * @author Long.Pham 12/05/2021
     * @param {*} event 
     */

    handleInputRowChange = (event, index, key) => {
        var target = event.target;
        var value = target.value;
        var { dataList } = this.state;
        var self = this;
        var item = dataList[index];
        if (Libs.isObjectEmpty(item) || Libs.isBlank(key)) return;
        item[key] = value;
        dataList[index] = item;

        this.setState({
            dataList: dataList
        }, () => {
            self.reRenderData();
        })
    }


    reRenderData() {
        var { dataList, curItem, dataCounts } = this.state;

        var dataListNew = [];
        var counta = null,
            countb = null,
            countc = null,
            countd = null,
            counte = null,
            countf = null,
            countg = null,
            counth = null,
            counti = null,
            countj = null,
            countk = null,
            countl = null,
            countm = null,
            countn = null,
            counto = null,
            countp = null,
            countq = null,
            countr = null,
            counts = null,
            countt = null,
            countu = null,
            countv = null,
            countw = null,
            countx = null,
            county = null,
            countz = null,
            countaa = null,
            countab = null,
            countac = null,
            countad = null,
            countae = null,
            countaf = null,
            countag = null,
            countah = null,
            countai = null,
            countaj = null,
            countak = null,
            countal = null,
            countam = null,
            countan = null,
            countao = null,
            countap = null,
            countaq = null,
            countar = null,
            countas = null,
            countat = null,
            countau = null,
            countav = null,
            countaw = null,
            countax = null,
            countay = null,
            countaz = null;

        if (Libs.isArrayData(dataList)) {
            for (var i = 0; i < curItem.totalRow; i++) {
                // for (var i = 0; i < dataList.length; i++) {
                // =IF($A1=1;CHOOSE(RANDBETWEEN(1;5);1;1;1;2;1);IF($A1=2;CHOOSE(RANDBETWEEN(1;4);1;1;2;1);IF($A1=3;CHOOSE(RANDBETWEEN(1;5);1;2;2;3;2);IF($A1=4;CHOOSE(RANDBETWEEN(1;6);2;3;2;3;4;3);CHOOSE(RANDBETWEEN(1;7);3;3;4;4;5;3;4)))))
                // ------------------------------------------- b -------------------------
                var b = null;
                if (dataList[i].a == 1) {
                    b = Libs.randomNumber('11121', 1);
                } else if (dataList[i].a == 2) {
                    b = Libs.randomNumber('1121', 1);
                } else if (dataList[i].a == 3) {
                    b = Libs.randomNumber('12232', 1);
                } else if (dataList[i].a == 4) {
                    b = Libs.randomNumber('232343', 1);
                } else if (dataList[i].a == 5) {
                    b = Libs.randomNumber('3344534', 1);
                }
                dataList[i].b = b;

                // =IF($A1=1;CHOOSE(RANDBETWEEN(1;5);1;1;1;2;1);IF($A1=2;CHOOSE(RANDBETWEEN(1;4);1;1;2;1);IF($A1=3;CHOOSE(RANDBETWEEN(1;5);1;2;2;3;2);IF($A1=4;CHOOSE(RANDBETWEEN(1;6);2;3;2;3;4;3);CHOOSE(RANDBETWEEN(1;7);3;3;4;4;5;3;4)))))
                // ------------------------------------------- c -------------------------
                var c = null;
                if (dataList[i].a == 1) {
                    c = Libs.randomNumber('11121', 1);
                } else if (dataList[i].a == 2) {
                    c = Libs.randomNumber('1121', 1);
                } else if (dataList[i].a == 3) {
                    c = Libs.randomNumber('12232', 1);
                } else if (dataList[i].a == 4) {
                    c = Libs.randomNumber('232343', 1);
                } else if (dataList[i].a == 5) {
                    c = Libs.randomNumber('3344534', 1);
                }
                dataList[i].c = c;
                // ------------------------------------------- d -------------------------
                var d = null;
                if (dataList[i].a == 1) {
                    d = Libs.randomNumber('11121', 1);
                } else if (dataList[i].a == 2) {
                    d = Libs.randomNumber('1121', 1);
                } else if (dataList[i].a == 3) {
                    d = Libs.randomNumber('12232', 1);
                } else if (dataList[i].a == 4) {
                    d = Libs.randomNumber('232343', 1);
                } else if (dataList[i].a == 5) {
                    d = Libs.randomNumber('3344534', 1);
                }
                dataList[i].d = d;
                // ------------------------------------------- e -------------------------
                var e = null;
                if (dataList[i].a == 1) {
                    e = Libs.randomNumber('11121', 1);
                } else if (dataList[i].a == 2) {
                    e = Libs.randomNumber('1121', 1);
                } else if (dataList[i].a == 3) {
                    e = Libs.randomNumber('12232', 1);
                } else if (dataList[i].a == 4) {
                    e = Libs.randomNumber('232343', 1);
                } else if (dataList[i].a == 5) {
                    e = Libs.randomNumber('3344534', 1);
                }
                dataList[i].e = e;
                // ------------------------------------------- f -------------------------
                var f = null;
                if (dataList[i].a == 1) {
                    f = Libs.randomNumber('11121', 1);
                } else if (dataList[i].a == 2) {
                    f = Libs.randomNumber('1121', 1);
                } else if (dataList[i].a == 3) {
                    f = Libs.randomNumber('12232', 1);
                } else if (dataList[i].a == 4) {
                    f = Libs.randomNumber('232343', 1);
                } else if (dataList[i].a == 5) {
                    f = Libs.randomNumber('3344534', 1);
                }
                dataList[i].f = f;

                // =IF($A1 = 1; CHOOSE(RANDBETWEEN(1; 3); 1; 1; 2); IF($A1 = 2; RANDBETWEEN(1; 2); IF($A1 = 3; CHOOSE(RANDBETWEEN(1; 3); 2; 2; 3); IF($A1 = 4; CHOOSE(RANDBETWEEN(1; 4); 2; 3; 3; 4); CHOOSE(RANDBETWEEN(1; 5); 3; 3; 4; 4; 5)))))
                // ------------------------------------------- g -------------------------
                var g = null;
                if (dataList[i].a == 1) {
                    g = Libs.randomNumber('112', 1);
                } else if (dataList[i].a == 2) {
                    g = Libs.randomNumber('12', 1);
                } else if (dataList[i].a == 3) {
                    g = Libs.randomNumber('223', 1);
                } else if (dataList[i].a == 4) {
                    g = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 5) {
                    g = Libs.randomNumber('33445', 1);
                }
                dataList[i].g = g;

                // ------------------------------------------- h -------------------------
                var h = null;
                if (dataList[i].a == 1) {
                    h = Libs.randomNumber('112', 1);
                } else if (dataList[i].a == 2) {
                    h = Libs.randomNumber('12', 1);
                } else if (dataList[i].a == 3) {
                    h = Libs.randomNumber('223', 1);
                } else if (dataList[i].a == 4) {
                    h = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 5) {
                    h = Libs.randomNumber('33445', 1);
                }
                dataList[i].h = h;

                // ------------------------------------------- i -------------------------
                var irow = null;
                if (dataList[i].a == 1) {
                    irow = Libs.randomNumber('112', 1);
                } else if (dataList[i].a == 2) {
                    irow = Libs.randomNumber('12', 1);
                } else if (dataList[i].a == 3) {
                    irow = Libs.randomNumber('223', 1);
                } else if (dataList[i].a == 4) {
                    irow = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 5) {
                    irow = Libs.randomNumber('33445', 1);
                }
                dataList[i].i = irow;

                // ------------------------------------------- j -------------------------
                var j = null;
                if (dataList[i].a == 1) {
                    j = Libs.randomNumber('112', 1);
                } else if (dataList[i].a == 2) {
                    j = Libs.randomNumber('12', 1);
                } else if (dataList[i].a == 3) {
                    j = Libs.randomNumber('223', 1);
                } else if (dataList[i].a == 4) {
                    j = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 5) {
                    j = Libs.randomNumber('33445', 1);
                }
                dataList[i].j = j;

                // ------------------------------------------- k -------------------------
                var k = null;
                if (dataList[i].a == 1) {
                    k = Libs.randomNumber('112', 1);
                } else if (dataList[i].a == 2) {
                    k = Libs.randomNumber('12', 1);
                } else if (dataList[i].a == 3) {
                    k = Libs.randomNumber('223', 1);
                } else if (dataList[i].a == 4) {
                    k = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 5) {
                    k = Libs.randomNumber('33445', 1);
                }
                dataList[i].k = k;

                // =IF($A1=1;CHOOSE(RANDBETWEEN(1;3);1;1;2);IF($A1=2;CHOOSE(RANDBETWEEN(1;5);1;2;2;3;3);IF($A1=3;RANDBETWEEN(2;3);IF($A1=4;RANDBETWEEN(3;4);RANDBETWEEN(3;5)))))
                // ------------------------------------------- l -------------------------
                var l = null;
                if (dataList[i].a == 1) {
                    l = Libs.randomNumber('112', 1);
                } else if (dataList[i].a == 2) {
                    l = Libs.randomNumber('12233', 1);
                } else if (dataList[i].a == 3) {
                    l = Libs.randomNumber('23', 1);
                } else if (dataList[i].a == 4) {
                    l = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 5) {
                    l = Libs.randomNumber('35', 1);
                }
                dataList[i].l = l;

                // ------------------------------------------- m -------------------------
                var m = null;
                if (dataList[i].a == 1) {
                    m = Libs.randomNumber('112', 1);
                } else if (dataList[i].a == 2) {
                    m = Libs.randomNumber('12233', 1);
                } else if (dataList[i].a == 3) {
                    m = Libs.randomNumber('23', 1);
                } else if (dataList[i].a == 4) {
                    m = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 5) {
                    m = Libs.randomNumber('35', 1);
                }
                dataList[i].m = m;

                // ------------------------------------------- n -------------------------
                var n = null;
                if (dataList[i].a == 1) {
                    n = Libs.randomNumber('112', 1);
                } else if (dataList[i].a == 2) {
                    n = Libs.randomNumber('12233', 1);
                } else if (dataList[i].a == 3) {
                    n = Libs.randomNumber('23', 1);
                } else if (dataList[i].a == 4) {
                    n = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 5) {
                    n = Libs.randomNumber('35', 1);
                }
                dataList[i].n = n;

                // ------------------------------------------- o -------------------------
                var o = null;
                if (dataList[i].a == 1) {
                    o = Libs.randomNumber('112', 1);
                } else if (dataList[i].a == 2) {
                    o = Libs.randomNumber('12233', 1);
                } else if (dataList[i].a == 3) {
                    o = Libs.randomNumber('23', 1);
                } else if (dataList[i].a == 4) {
                    o = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 5) {
                    o = Libs.randomNumber('35', 1);
                }
                dataList[i].o = o;

                // ------------------------------------------- p -------------------------
                var p = null;
                if (dataList[i].a == 1) {
                    p = Libs.randomNumber('112', 1);
                } else if (dataList[i].a == 2) {
                    p = Libs.randomNumber('12233', 1);
                } else if (dataList[i].a == 3) {
                    p = Libs.randomNumber('23', 1);
                } else if (dataList[i].a == 4) {
                    p = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 5) {
                    p = Libs.randomNumber('35', 1);
                }
                dataList[i].p = p;

                //=IF($A1=1;CHOOSE(RANDBETWEEN(1;3);1;2;2);IF($A1=2;RANDBETWEEN(2;3);IF($A1=3;RANDBETWEEN(3;4);IF($A1=4;RANDBETWEEN(3;4);RANDBETWEEN(4;5)))))
                // ------------------------------------------- q -------------------------
                var q = null;
                if (dataList[i].a == 1) {
                    q = Libs.randomNumber('122', 1);
                } else if (dataList[i].a == 2) {
                    q = Libs.randomNumber('23', 1);
                } else if (dataList[i].a == 3) {
                    q = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 4) {
                    q = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 5) {
                    q = Libs.randomNumber('45', 1);
                }
                dataList[i].q = q;

                // ------------------------------------------- r -------------------------
                var r = null;
                if (dataList[i].a == 1) {
                    r = Libs.randomNumber('122', 1);
                } else if (dataList[i].a == 2) {
                    r = Libs.randomNumber('23', 1);
                } else if (dataList[i].a == 3) {
                    r = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 4) {
                    r = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 5) {
                    r = Libs.randomNumber('45', 1);
                }
                dataList[i].r = r;


                // ------------------------------------------- s -------------------------
                var s = null;
                if (dataList[i].a == 1) {
                    s = Libs.randomNumber('122', 1);
                } else if (dataList[i].a == 2) {
                    s = Libs.randomNumber('23', 1);
                } else if (dataList[i].a == 3) {
                    s = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 4) {
                    s = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 5) {
                    s = Libs.randomNumber('45', 1);
                }
                dataList[i].s = s;

                // ------------------------------------------- t -------------------------
                var t = null;
                if (dataList[i].a == 1) {
                    t = Libs.randomNumber('122', 1);
                } else if (dataList[i].a == 2) {
                    t = Libs.randomNumber('23', 1);
                } else if (dataList[i].a == 3) {
                    t = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 4) {
                    t = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 5) {
                    t = Libs.randomNumber('45', 1);
                }
                dataList[i].t = t;

                // ------------------------------------------- u -------------------------
                var u = null;
                if (dataList[i].a == 1) {
                    u = Libs.randomNumber('122', 1);
                } else if (dataList[i].a == 2) {
                    u = Libs.randomNumber('23', 1);
                } else if (dataList[i].a == 3) {
                    u = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 4) {
                    u = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 5) {
                    u = Libs.randomNumber('45', 1);
                }
                dataList[i].u = u;

                // ------------------------------------------- v -------------------------
                var v = null;
                if (dataList[i].a == 1) {
                    v = Libs.randomNumber('122', 1);
                } else if (dataList[i].a == 2) {
                    v = Libs.randomNumber('23', 1);
                } else if (dataList[i].a == 3) {
                    v = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 4) {
                    v = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 5) {
                    v = Libs.randomNumber('45', 1);
                }
                dataList[i].v = v;

                // ------------------------------------------- w -------------------------
                var w = null;
                if (dataList[i].a == 1) {
                    w = Libs.randomNumber('122', 1);
                } else if (dataList[i].a == 2) {
                    w = Libs.randomNumber('23', 1);
                } else if (dataList[i].a == 3) {
                    w = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 4) {
                    w = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 5) {
                    w = Libs.randomNumber('45', 1);
                }
                dataList[i].w = w;

                // ------------------------------------------- x -------------------------
                var x = null;
                if (dataList[i].a == 1) {
                    x = Libs.randomNumber('122', 1);
                } else if (dataList[i].a == 2) {
                    x = Libs.randomNumber('23', 1);
                } else if (dataList[i].a == 3) {
                    x = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 4) {
                    x = Libs.randomNumber('34', 1);
                } else if (dataList[i].a == 5) {
                    x = Libs.randomNumber('45', 1);
                }
                dataList[i].x = x;

                //=IF($A1=1;CHOOSE(RANDBETWEEN(1;4);1;2;2;2);IF($A1=2;CHOOSE(RANDBETWEEN(1;4);2;3;3;4);IF($A1=3;CHOOSE(RANDBETWEEN(1;4);3;4;4;4);IF($A1=4;CHOOSE(RANDBETWEEN(1;3);4;4;5);CHOOSE(RANDBETWEEN(1;3);4;5;5)))))
                // ------------------------------------------- y -------------------------
                var y = null;
                if (dataList[i].a == 1) {
                    y = Libs.randomNumber('1222', 1);
                } else if (dataList[i].a == 2) {
                    y = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 3) {
                    y = Libs.randomNumber('3444', 1);
                } else if (dataList[i].a == 4) {
                    y = Libs.randomNumber('445', 1);
                } else if (dataList[i].a == 5) {
                    y = Libs.randomNumber('455', 1);
                }
                dataList[i].y = y;

                // ------------------------------------------- z -------------------------
                var z = null;
                if (dataList[i].a == 1) {
                    z = Libs.randomNumber('1222', 1);
                } else if (dataList[i].a == 2) {
                    z = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 3) {
                    z = Libs.randomNumber('3444', 1);
                } else if (dataList[i].a == 4) {
                    z = Libs.randomNumber('445', 1);
                } else if (dataList[i].a == 5) {
                    z = Libs.randomNumber('455', 1);
                }
                dataList[i].z = z;

                // ------------------------------------------- aa -------------------------
                var aa = null;
                if (dataList[i].a == 1) {
                    aa = Libs.randomNumber('1222', 1);
                } else if (dataList[i].a == 2) {
                    aa = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 3) {
                    aa = Libs.randomNumber('3444', 1);
                } else if (dataList[i].a == 4) {
                    aa = Libs.randomNumber('445', 1);
                } else if (dataList[i].a == 5) {
                    aa = Libs.randomNumber('455', 1);
                }
                dataList[i].aa = aa;

                // ------------------------------------------- ab -------------------------
                var ab = null;
                if (dataList[i].a == 1) {
                    ab = Libs.randomNumber('1222', 1);
                } else if (dataList[i].a == 2) {
                    ab = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 3) {
                    ab = Libs.randomNumber('3444', 1);
                } else if (dataList[i].a == 4) {
                    ab = Libs.randomNumber('445', 1);
                } else if (dataList[i].a == 5) {
                    ab = Libs.randomNumber('455', 1);
                }
                dataList[i].ab = ab;

                // ------------------------------------------- ac -------------------------
                var ac = null;
                if (dataList[i].a == 1) {
                    ac = Libs.randomNumber('1222', 1);
                } else if (dataList[i].a == 2) {
                    ac = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 3) {
                    ac = Libs.randomNumber('3444', 1);
                } else if (dataList[i].a == 4) {
                    ac = Libs.randomNumber('445', 1);
                } else if (dataList[i].a == 5) {
                    ac = Libs.randomNumber('455', 1);
                }
                dataList[i].ac = ac;


                // ------------------------------------------- ad -------------------------
                var ad = null;
                if (dataList[i].a == 1) {
                    ad = Libs.randomNumber('1222', 1);
                } else if (dataList[i].a == 2) {
                    ad = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 3) {
                    ad = Libs.randomNumber('3444', 1);
                } else if (dataList[i].a == 4) {
                    ad = Libs.randomNumber('445', 1);
                } else if (dataList[i].a == 5) {
                    ad = Libs.randomNumber('455', 1);
                }
                dataList[i].ad = ad;

                // =IF($A1=1;CHOOSE(RANDBETWEEN(1;5);1;2;2;3;3);IF($A1=2;CHOOSE(RANDBETWEEN(1;4);2;3;3;4);IF($A1=3;CHOOSE(RANDBETWEEN(1;5);3;4;4;5;5);IF($A1=4;CHOOSE(RANDBETWEEN(1;3);4;5;5);CHOOSE(RANDBETWEEN(1;4);4;5;5;5)))))
                // ------------------------------------------- ae -------------------------
                var ae = null;
                if (dataList[i].a == 1) {
                    ae = Libs.randomNumber('12233', 1);
                } else if (dataList[i].a == 2) {
                    ae = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 3) {
                    ae = Libs.randomNumber('34455', 1);
                } else if (dataList[i].a == 4) {
                    ae = Libs.randomNumber('455', 1);
                } else if (dataList[i].a == 5) {
                    ae = Libs.randomNumber('4555', 1);
                }
                dataList[i].ae = ae;

                // ------------------------------------------- af -------------------------
                var af = null;
                if (dataList[i].a == 1) {
                    af = Libs.randomNumber('12233', 1);
                } else if (dataList[i].a == 2) {
                    af = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 3) {
                    af = Libs.randomNumber('34455', 1);
                } else if (dataList[i].a == 4) {
                    af = Libs.randomNumber('455', 1);
                } else if (dataList[i].a == 5) {
                    af = Libs.randomNumber('4555', 1);
                }
                dataList[i].af = af;

                // ------------------------------------------- ag -------------------------
                var ag = null;
                if (dataList[i].a == 1) {
                    ag = Libs.randomNumber('12233', 1);
                } else if (dataList[i].a == 2) {
                    ag = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 3) {
                    ag = Libs.randomNumber('34455', 1);
                } else if (dataList[i].a == 4) {
                    ag = Libs.randomNumber('455', 1);
                } else if (dataList[i].a == 5) {
                    ag = Libs.randomNumber('4555', 1);
                }
                dataList[i].ag = ag;

                // ------------------------------------------- ah -------------------------
                var ah = null;
                if (dataList[i].a == 1) {
                    ah = Libs.randomNumber('12233', 1);
                } else if (dataList[i].a == 2) {
                    ah = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 3) {
                    ah = Libs.randomNumber('34455', 1);
                } else if (dataList[i].a == 4) {
                    ah = Libs.randomNumber('455', 1);
                } else if (dataList[i].a == 5) {
                    ah = Libs.randomNumber('4555', 1);
                }
                dataList[i].ah = ah;

                // ------------------------------------------- ai -------------------------
                var ai = null;
                if (dataList[i].a == 1) {
                    ai = Libs.randomNumber('12233', 1);
                } else if (dataList[i].a == 2) {
                    ai = Libs.randomNumber('2334', 1);
                } else if (dataList[i].a == 3) {
                    ai = Libs.randomNumber('34455', 1);
                } else if (dataList[i].a == 4) {
                    ai = Libs.randomNumber('455', 1);
                } else if (dataList[i].a == 5) {
                    ai = Libs.randomNumber('4555', 1);
                }
                dataList[i].ai = ai;

                // =ROUND(IF(5-G1+1>5;5;5-G1+1);0)
                // ------------------------------------------- aj -------------------------
                var aj = null;
                if ((5 - dataList[i].g) + 1 > 5) {
                    aj = null;
                } else {
                    aj = Math.round((5 - dataList[i].g) + 1);
                }
                dataList[i].aj = aj;

                // ------------------------------------------- ak set null -------------------------
                // ------------------------------------------- al -------------------------


                // ------------------------------------------- am -------------------------
                // =IF($AL1=1;CHOOSE(RANDBETWEEN(1;3);1;2;2);IF($AL1=2;CHOOSE(RANDBETWEEN(1;5);1;2;2;3;3);IF($AL1=3;CHOOSE(RANDBETWEEN(1;6);2;3;3;4;4;3);IF($AL1=4;CHOOSE(RANDBETWEEN(1;4);3;4;4;5);CHOOSE(RANDBETWEEN(1;5);3;4;5;5;5)))))
                var am = null;
                if (dataList[i].al == 1) {
                    am = Libs.randomNumber('122', 1);
                } else if (dataList[i].al == 2) {
                    am = Libs.randomNumber('12233', 1);
                } else if (dataList[i].al == 3) {
                    am = Libs.randomNumber('233443', 1);
                } else if (dataList[i].al == 4) {
                    am = Libs.randomNumber('3445', 1);
                } else if (dataList[i].al == 5) {
                    am = Libs.randomNumber('34555', 1);
                }
                dataList[i].am = am;


                // ------------------------------------------- an set null -------------------------
                // ------------------------------------------- ao tu nhap -------------------------

                // ------------------------------------------- ap -------------------------
                //= =IF(AO1=2;CHOOSE(RANDBETWEEN(1;3);1;1;2);IF(AO1=4;CHOOSE(RANDBETWEEN(1;3);5;5;4);AO1))
                var ap = null;
                if (dataList[i].ao == 1) {
                    ap = Libs.randomNumber('112', 1);
                } else if (dataList[i].ao == 4) {
                    ap = Libs.randomNumber('554', 1);
                } else {
                    ap = dataList[i].ao
                }
                dataList[i].ap = ap;

                // ------------------------------------------- aq set null -------------------------
                // ------------------------------------------- ar tự nhập -------------------------
                // ------------------------------------------- as tự nhập -------------------------
                // ------------------------------------------- at tự nhập -------------------------
                // ------------------------------------------- au tự nhập -------------------------
                // ------------------------------------------- av tự nhập -------------------------
                // ------------------------------------------- aw tự nhập -------------------------

                // ------------------------------------------- ax -------------------------
                // =ROUND(AVERAGE(AR1:AW1);0)
                var ax = null;
                if (!Libs.isBlank(dataList[i].ar) && !Libs.isBlank(dataList[i].as) && !Libs.isBlank(dataList[i].at) && !Libs.isBlank(dataList[i].au) && !Libs.isBlank(dataList[i].av) && !Libs.isBlank(dataList[i].aw)) {
                    ax = Math.round((
                        parseInt(dataList[i].ar) +
                        parseInt(dataList[i].as) +
                        parseInt(dataList[i].at) +
                        parseInt(dataList[i].au) +
                        parseInt(dataList[i].av) +
                        parseInt(dataList[i].aw)) / 6);
                }

                dataList[i].ax = ax;

                // ------------------------------------------- ay -------------------------
                // =INT(AVERAGE(AR1:AW1))
                var ay = null;
                if (!Libs.isBlank(dataList[i].ar) && !Libs.isBlank(dataList[i].as) && !Libs.isBlank(dataList[i].at) && !Libs.isBlank(dataList[i].au) && !Libs.isBlank(dataList[i].av) && !Libs.isBlank(dataList[i].aw)) {
                    ay = Math.floor(
                        (
                            parseInt(dataList[i].ar) +
                            parseInt(dataList[i].as) +
                            parseInt(dataList[i].at) +
                            parseInt(dataList[i].au) +
                            parseInt(dataList[i].av) +
                            parseInt(dataList[i].aw)
                        ) / 6);
                }

                dataList[i].ay = ay;

                // ------------------------------------------- az -------------------------
                // =CHOOSE(RANDBETWEEN(1;2);AX1;AY1)
                var az = null;
                if (!Libs.isBlank(ax) && !Libs.isBlank(ay)) {
                    az = Libs.randomNumber((ax + "" + ay), 1);
                }

                dataList[i].az = az;

                dataListNew.push(dataList[i]);

                if (!Libs.isBlank(dataList[i].a)) { counta = counta + parseInt(dataList[i].a); }
                if (!Libs.isBlank(dataList[i].b)) { countb = countb + parseInt(dataList[i].b); }
                if (!Libs.isBlank(dataList[i].c)) { countc = countc + parseInt(dataList[i].c); }
                if (!Libs.isBlank(dataList[i].d)) { countd = countd + parseInt(dataList[i].d); }
                if (!Libs.isBlank(dataList[i].e)) { counte = counte + parseInt(dataList[i].e); }
                if (!Libs.isBlank(dataList[i].f)) { countf = countf + parseInt(dataList[i].f); }
                if (!Libs.isBlank(dataList[i].g)) { countg = countg + parseInt(dataList[i].g); }
                if (!Libs.isBlank(dataList[i].h)) { counth = counth + parseInt(dataList[i].h); }
                if (!Libs.isBlank(dataList[i].i)) { counti = counti + parseInt(dataList[i].i); }
                if (!Libs.isBlank(dataList[i].j)) { countj = countj + parseInt(dataList[i].j); }
                if (!Libs.isBlank(dataList[i].k)) { countk = countk + parseInt(dataList[i].k); }
                if (!Libs.isBlank(dataList[i].l)) { countl = countl + parseInt(dataList[i].l); }
                if (!Libs.isBlank(dataList[i].m)) { countm = countm + parseInt(dataList[i].m); }
                if (!Libs.isBlank(dataList[i].n)) { countn = countn + parseInt(dataList[i].n); }
                if (!Libs.isBlank(dataList[i].o)) { counto = counto + parseInt(dataList[i].o); }
                if (!Libs.isBlank(dataList[i].p)) { countp = countp + parseInt(dataList[i].p); }
                if (!Libs.isBlank(dataList[i].q)) { countq = countq + parseInt(dataList[i].q); }
                if (!Libs.isBlank(dataList[i].r)) { countr = countr + parseInt(dataList[i].r); }
                if (!Libs.isBlank(dataList[i].s)) { counts = counts + parseInt(dataList[i].s); }
                if (!Libs.isBlank(dataList[i].t)) { countt = countt + parseInt(dataList[i].t); }
                if (!Libs.isBlank(dataList[i].u)) { countu = countu + parseInt(dataList[i].u); }
                if (!Libs.isBlank(dataList[i].v)) { countv = countv + parseInt(dataList[i].v); }
                if (!Libs.isBlank(dataList[i].w)) { countw = countw + parseInt(dataList[i].w); }
                if (!Libs.isBlank(dataList[i].x)) { countx = countx + parseInt(dataList[i].x); }
                if (!Libs.isBlank(dataList[i].y)) { county = county + parseInt(dataList[i].y); }
                if (!Libs.isBlank(dataList[i].z)) { countz = countz + parseInt(dataList[i].z); }

                if (!Libs.isBlank(dataList[i].aa)) { countaa = countaa + parseInt(dataList[i].aa); }
                if (!Libs.isBlank(dataList[i].ab)) { countab = countab + parseInt(dataList[i].ab); }
                if (!Libs.isBlank(dataList[i].ac)) { countac = countac + parseInt(dataList[i].ac); }
                if (!Libs.isBlank(dataList[i].ad)) { countad = countad + parseInt(dataList[i].ad); }
                if (!Libs.isBlank(dataList[i].ae)) { countae = countae + parseInt(dataList[i].ae); }
                if (!Libs.isBlank(dataList[i].af)) { countaf = countaf + parseInt(dataList[i].af); }
                if (!Libs.isBlank(dataList[i].ag)) { countag = countag + parseInt(dataList[i].ag); }
                if (!Libs.isBlank(dataList[i].ah)) { countah = countah + parseInt(dataList[i].ah); }
                if (!Libs.isBlank(dataList[i].ai)) { countai = countai + parseInt(dataList[i].ai); }
                if (!Libs.isBlank(dataList[i].aj)) { countaj = countaj + parseInt(dataList[i].aj); }
                if (!Libs.isBlank(dataList[i].ak)) { countak = countak + parseInt(dataList[i].ak); }
                if (!Libs.isBlank(dataList[i].al)) { countal = countal + parseInt(dataList[i].al); }
                if (!Libs.isBlank(dataList[i].am)) { countam = countam + parseInt(dataList[i].am); }
                if (!Libs.isBlank(dataList[i].an)) { countan = countan + parseInt(dataList[i].an); }
                if (!Libs.isBlank(dataList[i].ao)) { countao = countao + parseInt(dataList[i].ao); }
                if (!Libs.isBlank(dataList[i].ap)) { countap = countap + parseInt(dataList[i].ap); }
                if (!Libs.isBlank(dataList[i].aq)) { countaq = countaq + parseInt(dataList[i].aq); }
                if (!Libs.isBlank(dataList[i].ar)) { countar = countar + parseInt(dataList[i].ar); }
                if (!Libs.isBlank(dataList[i].as)) { countas = countas + parseInt(dataList[i].as); }
                if (!Libs.isBlank(dataList[i].at)) { countat = countat + parseInt(dataList[i].at); }
                if (!Libs.isBlank(dataList[i].au)) { countau = countau + parseInt(dataList[i].au); }
                if (!Libs.isBlank(dataList[i].av)) { countav = countav + parseInt(dataList[i].av); }
                if (!Libs.isBlank(dataList[i].aw)) { countaw = countaw + parseInt(dataList[i].aw); }
                if (!Libs.isBlank(dataList[i].ax)) { countax = countax + parseInt(dataList[i].ax); }
                if (!Libs.isBlank(dataList[i].ay)) { countay = countay + parseInt(dataList[i].ay); }
                if (!Libs.isBlank(dataList[i].az)) { countaz = countaz + parseInt(dataList[i].az); }
            }

            dataCounts.a = Libs.round((counta / curItem.totalRow), 2).toFixed(2);
            dataCounts.b = Libs.round((countb / curItem.totalRow), 2).toFixed(2);
            dataCounts.c = Libs.round((countc / curItem.totalRow), 2).toFixed(2);
            dataCounts.d = Libs.round((countd / curItem.totalRow), 2).toFixed(2);
            dataCounts.e = Libs.round((counte / curItem.totalRow), 2).toFixed(2);
            dataCounts.f = Libs.round((countf / curItem.totalRow), 2).toFixed(2);
            dataCounts.g = Libs.round((countg / curItem.totalRow), 2).toFixed(2);
            dataCounts.h = Libs.round((counth / curItem.totalRow), 2).toFixed(2);
            dataCounts.i = Libs.round((counti / curItem.totalRow), 2).toFixed(2);
            dataCounts.j = Libs.round((countj / curItem.totalRow), 2).toFixed(2);
            dataCounts.k = Libs.round((countk / curItem.totalRow), 2).toFixed(2);
            dataCounts.l = Libs.round((countl / curItem.totalRow), 2).toFixed(2);
            dataCounts.m = Libs.round((countm / curItem.totalRow), 2).toFixed(2);
            dataCounts.n = Libs.round((countn / curItem.totalRow), 2).toFixed(2);
            dataCounts.o = Libs.round((counto / curItem.totalRow), 2).toFixed(2);
            dataCounts.p = Libs.round((countp / curItem.totalRow), 2).toFixed(2);
            dataCounts.q = Libs.round((countq / curItem.totalRow), 2).toFixed(2);
            dataCounts.r = Libs.round((countr / curItem.totalRow), 2).toFixed(2);
            dataCounts.s = Libs.round((counts / curItem.totalRow), 2).toFixed(2);
            dataCounts.t = Libs.round((countt / curItem.totalRow), 2).toFixed(2);
            dataCounts.u = Libs.round((countu / curItem.totalRow), 2).toFixed(2);
            dataCounts.v = Libs.round((countv / curItem.totalRow), 2).toFixed(2);
            dataCounts.w = Libs.round((countw / curItem.totalRow), 2).toFixed(2);
            dataCounts.x = Libs.round((countx / curItem.totalRow), 2).toFixed(2);
            dataCounts.y = Libs.round((county / curItem.totalRow), 2).toFixed(2);
            dataCounts.z = Libs.round((countz / curItem.totalRow), 2).toFixed(2);
        
            dataCounts.aa = Libs.round((countaa / curItem.totalRow), 2).toFixed(2);
            dataCounts.ab = Libs.round((countab / curItem.totalRow), 2).toFixed(2);
            dataCounts.ac = Libs.round((countac / curItem.totalRow), 2).toFixed(2);
            dataCounts.ad = Libs.round((countad / curItem.totalRow), 2).toFixed(2);
            dataCounts.ae = Libs.round((countae / curItem.totalRow), 2).toFixed(2);
            dataCounts.af = Libs.round((countaf / curItem.totalRow), 2).toFixed(2);
            dataCounts.ag = Libs.round((countag / curItem.totalRow), 2).toFixed(2);
            dataCounts.ah = Libs.round((countah / curItem.totalRow), 2).toFixed(2);
            dataCounts.ai = Libs.round((countai / curItem.totalRow), 2).toFixed(2);
            dataCounts.aj = Libs.round((countaj / curItem.totalRow), 2).toFixed(2);
            dataCounts.ak = Libs.round((countak / curItem.totalRow), 2).toFixed(2);
            dataCounts.al = Libs.round((countal / curItem.totalRow), 2).toFixed(2);
            dataCounts.am = Libs.round((countam / curItem.totalRow), 2).toFixed(2);
            dataCounts.an = Libs.round((countan / curItem.totalRow), 2).toFixed(2);
            dataCounts.ao = Libs.round((countao / curItem.totalRow), 2).toFixed(2);
            dataCounts.ap = Libs.round((countap / curItem.totalRow), 2).toFixed(2);
            dataCounts.aq = Libs.round((countaq / curItem.totalRow), 2).toFixed(2);
            dataCounts.ar = Libs.round((countar / curItem.totalRow), 2).toFixed(2);
            dataCounts.as = Libs.round((countas / curItem.totalRow), 2).toFixed(2);
            dataCounts.at = Libs.round((countat / curItem.totalRow), 2).toFixed(2);
            dataCounts.au = Libs.round((countau / curItem.totalRow), 2).toFixed(2);
            dataCounts.av = Libs.round((countav / curItem.totalRow), 2).toFixed(2);
            dataCounts.aw = Libs.round((countaw / curItem.totalRow), 2).toFixed(2);
            dataCounts.ax = Libs.round((countax / curItem.totalRow), 2).toFixed(2);
            dataCounts.ay = Libs.round((countay / curItem.totalRow), 2).toFixed(2);
            dataCounts.az = Libs.round((countaz / curItem.totalRow), 2).toFixed(2);
        }
        this.setState({
            dataList: dataListNew,
            dataCounts: dataCounts
        });
    }

    copyATToA() {
        var { dataList } = this.state, self = this;
        if (Libs.isArrayData(dataList)) {
            for (var i = 0; i < dataList.length; i++) {
                dataList[i].a = dataList[i].am;
            }
        }
        this.setState({
            dataList: dataList
        }, () => {
            self.reRenderData();
        });
    }

    copyATToAs() {
        var { dataList } = this.state, self = this;
        if (Libs.isArrayData(dataList)) {
            for (var i = 0; i < dataList.length; i++) {
                dataList[i].al = dataList[i].am;
            }
        }
        this.setState({
            dataList: dataList
        }, () => {
            self.reRenderData();
        });
    }


    copyAJToA() {
        var { dataList } = this.state, self = this;
        if (Libs.isArrayData(dataList)) {
            for (var i = 0; i < dataList.length; i++) {
                dataList[i].a = dataList[i].aj;
            }
        }
        this.setState({
            dataList: dataList
        }, () => {
            self.reRenderData();
        });
    }

    changeData() {
        this.reRenderData();
    }
    handleInputonKeyUp = (event, index, key) => {
        if (event.key == "ArrowDown") {
            $(".table-bordered").find("#" + key + "key" + (index + 1)).focus();
        } else if (event.key == "ArrowUp") {
            $(".table-bordered").find("#" + key + "key" + (index - 1)).focus();
        }
    };

    exportToExel() {
        let { dataList } = this.state, dataExport = [];
        if (!Libs.isArrayData(dataList)) return;


        dataList.map((item, index) => {
            dataExport.push({
                a: item.a,
                b: item.b,
                c: item.c,
                d: item.d,
                e: item.e,
                f: item.f,
                g: item.g,
                h: item.h,
                i: item.i,
                j: item.j,
                k: item.k,
                l: item.l,
                m: item.m,
                n: item.n,
                o: item.o,
                p: item.p,
                q: item.q,
                r: item.r,
                s: item.s,
                t: item.t,
                u: item.u,
                v: item.v,
                w: item.w,
                x: item.x,
                y: item.y,
                z: item.z,

                aa: item.aa,
                ab: item.ab,
                ac: item.ac,
                ad: item.ad,
                ae: item.ae,
                af: item.af,
                ag: item.ag,
                ah: item.ah,
                ai: item.ai,
                aj: item.aj,
                ak: item.ak,
                al: item.al,
                am: item.am,
                an: item.an,
                ao: item.ao,
                ap: item.ap,
                aq: item.aq,
                ar: item.ar,
                as: item.as,
                at: item.at
            });
        });

        if (Libs.isArrayData(dataList)) {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(dataExport, { header: null, skipHeader: true });
            var wscols = [
                { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 },
                { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 },
                { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 },
                { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 },
                { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }
            ];

            ws['!cols'] = wscols;

            const wb = { Sheets: { 'Error': ws }, SheetNames: ['Error'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, "Export-excel-" + fileExtension);
        }
    }


    render() {
        return this.jsxTemplate.call(this)
    }
}



const HighOrderComponentTranslated = withTranslation('common')(FileSPSS)
export default HighOrderComponentTranslated;