import React, { Component } from 'react';
import Routers from './router/index';
import { CMSToast } from './components/CMSToast';
import Constants from './utils/Constants';
import Libs from './utils/Libs';

export default class App extends Component {
  constructor(props) {
    super(props)
    this.setPermission();
  }

  setPermission() {
    let accessParam = localStorage.getItem(Constants.COMMON.ACCESS_PARAM);
    if (!Libs.isBlank(accessParam)) {
      try {
        let jsonAccessParam = JSON.parse(Libs.base64Decrypt(accessParam));
        this.permission = jsonAccessParam;
      } catch (e) {
        console.log(e)
      }
    }
  }

  render() {
    return (
      <div className="App">
        <CMSToast position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={true} />
        <Routers auth={this.permission} />
      </div>
    );
  };
}

