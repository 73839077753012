
import Libs from '../../../../utils/Libs';
import BaseComponent from '../../../BaseComponent';
import AddPopupJsx from './AddPopup.jsx';


class AddPopup extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            curItem: this.props.curItem,
        }
        this.jsxTemplate = AddPopupJsx;
    }

    onSave() {
        var { curItem } = this.state;
        curItem.index_active = curItem.index_change;
        if(Libs.isBlank(curItem.value_add)) {return};
        this.props.onClickCloseShowForm(curItem);
    }
}
export default AddPopup;