import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import MainLogin from '../containers/layouts/MainLogin';
import MainModuleSystem from '../containers/layouts/MainModuleSystem';

import Login from '../containers/views/Login/Login';
import GoogleForm from '../containers/views/desktop/GoogleForm/GoogleForm';
import FileSPSS from '../containers/views/desktop/FileSPSS/FileSPSS';
import FileRegression from '../containers/views/desktop/FileRegression/FileRegression';
import Role from '../containers/views/desktop/ModuleSystem/Role/Role';
import Permissions from '../containers/views/desktop/ModuleSystem/Permissions/Permissions';
import Employees from '../containers/views/desktop/ModuleSystem/Employees/Employees';
import DashBoard from '../containers/views/desktop/Dashboard/DashBoard';
import DataSeven from '../containers/views/desktop/DataSeven/DataSeven';


import DataRegressionSeven from '../containers/views/desktop/DataRegressionSeven/DataRegressionSeven';







class MainRoute extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Router>
            <Switch>
                <Route path='/' exact>
                    <MainLogin>
                        <Switch>
                            <Route path='/' component={Login} />
                        </Switch>
                    </MainLogin>
                </Route>

                <Route path='/:path?'>
                    <MainModuleSystem>
                        <Switch>

                            <Route path="/dashboard" exact component={(props) => <DashBoard auth={this.props.auth} baseParam={props} />} />
                            <Route path="/google-form" exact component={(props) => <GoogleForm auth={this.props.auth} baseParam={props} />} />
                            <Route path="/data-5" exact component={(props) => <FileSPSS auth={this.props.auth} baseParam={props} />} />
                            <Route path="/regression-5" exact component={(props) => <FileRegression auth={this.props.auth} baseParam={props} />} />
                            
                            <Route path="/data-7" exact component={(props) => <DataSeven auth={this.props.auth} baseParam={props} />} />
                            <Route path="/regression-7" exact component={(props) => <DataRegressionSeven auth={this.props.auth} baseParam={props} />} />

                            <Route path="/role" exact component={(props) => <Role auth={this.props.auth} baseParam={props} />} />
                            <Route path="/permission" exact component={(props) => <Permissions auth={this.props.auth} baseParam={props} />} />
                            <Route path="/user" exact component={(props) => <Employees auth={this.props.auth} baseParam={props} />} />
                        </Switch>
                    </MainModuleSystem>
                </Route>

            </Switch>
        </Router>
    }
}

export default MainRoute;